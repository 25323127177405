import React, { useEffect, useState } from "react";
import "../../../StyleSheet/Property/allproperties.scss";
import "./PropertyDetails-panel.scss";
import PropertyDetailsPanel from "./LandlordDetails-panel";
import { useParams } from "react-router-dom";
import { useFetchLandlordDetails } from "../../../../Api/PropertiesQuery";
import PropertyDetailsTransactions from "./LandlordDetails-transactions";
import PropertyDetailsProperties from "./LandlordDetails-properties";
import PropertyDetailsIncomeAndExpense from "./LandlordDetails-incomeAndExpense";
import PropertyDetailsDocuments from "./LandlordDetails-documents";
import PropertyDetailsContactDetails from "./LandlordDetails-contactDetails";
import { useDispatch } from "react-redux";
import { setPropertyDetailsCrumbs } from "../../../../redux/reducer/counterslice";

function LandlordDetails() {
  const { propertyId } = useParams();
  const fetchLandlordDetails = useFetchLandlordDetails(propertyId);
  const dispatch = useDispatch();

  const [selectedSection, setSelectedSection] = useState("Contact Details");
  useEffect(() => {
    if (propertyId)
      dispatch(
        setPropertyDetailsCrumbs({
          fromTitle: "Contacts",
          fromUrl: `/contacts`,
        })
      );
  }, []);

  const renderSection = () => {
    switch (selectedSection) {
      case "Contact Details":
        return (
          <PropertyDetailsContactDetails
            dataFromParent={fetchLandlordDetails?.data?.data}
            dataIsLoading={fetchLandlordDetails?.isLoading}
            fromContact={true}
          />
        );
      case "Documents":
        return (
          <PropertyDetailsDocuments
            dataFromParent={fetchLandlordDetails?.data?.data}
            dataIsLoading={fetchLandlordDetails?.isLoading}
          />
        );
      case "Income and Expenditure":
        return <PropertyDetailsIncomeAndExpense />;
      case "Properties":
        return <PropertyDetailsProperties />;
      case "Transactions":
        return (
          <PropertyDetailsTransactions
            dataFromParent={fetchLandlordDetails?.data?.data}
            dataIsLoading={fetchLandlordDetails?.isLoading}
          />
        );
      default:
        return <PropertyDetailsPanel />;
    }
  };

  return (
    <div
      className="allproperties_main_wrapper property-details"
      style={{ overflowY: "auto" }}
    >
      <PropertyDetailsPanel
        dataFromParent={fetchLandlordDetails?.data?.data}
        dataIsLoading={fetchLandlordDetails?.isLoading}
      />
      <div className="tabs">
        {[
          "Contact Details",
          "Documents",
          "Income and Expenditure",
          "Properties",
          "Transactions",
        ].map((tab) => (
          <button
            key={tab}
            className={selectedSection === tab ? "active" : ""}
            onClick={() => setSelectedSection(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="sectionWrapper" style={{ height: "71.6%" }}>
        {renderSection()}
      </div>
    </div>
  );
}

export default LandlordDetails;
