import "../../../StyleSheet/Property/popups/propertydetails.scss";
import closebutton from "../../../../Assets/Common/close_button.svg";
import Money from "../../../../Assets/Common/Money.svg";
import download from "../../../../Assets/Common/download.svg";
import landlordIcon from "../../../../Assets/Common/landlordIcon.svg";
import { useEffect, useMemo, useState } from "react";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import archive from "../../../../Assets/Common/archive.svg";
import expenditure from "../../../../Assets/Common/expenditure.png";
import grossIncome from "../../../../Assets/Common/grossIncome.png";
import income from "../../../../Assets/Common/income.png";
import { createColumnHelper } from "@tanstack/react-table";
import {
  useFetchProperties,
  useFetchPropertiesCompliance,
} from "../../../../Api/PropertiesQuery";
import dayjs from "dayjs";
import {
  formatAddress,
  formatName,
  formatPounds,
} from "../../../../Utils/Utils";
import {
  useFetchLandLordExpenditure,
  useFetchLandLordTransactions,
} from "../../../../Api/ContactsQuery";
import ArchivePopup from "../../Property/popups/ArchivePopup";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import PropertyPropertyContacts from "../../Property/popups/PropertyPropertyContacts";
import Propertydetails from "../../Property/popups/propertydetails";
import {
  setContactDetailsFilter,
  setPropertyDetailsFilter,
} from "../../../../redux/reducer/counterslice";
import { useDispatch } from "react-redux";
import GeneratePeriodicStatement from "./generatePeriodicStatement";
import grossIncomeWhite from "../../../../Assets/Common/grossIncomeWhite.svg";
import incomeWhite from "../../../../Assets/Common/incomeWhite.svg";
import expenditureWhite from "../../../../Assets/Common/expenditureWhite.svg";

export default function LandLordPopup(props) {
  const { rowvalue, landlordPopup, fromContact = false } = props;
  const columnHelper = createColumnHelper();
  const [search, setsearch] = useState("");
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [landLordId, setLandLordId] = useState("");
  const [popup, setpopup] = useState(false);

  const fetchLandlordTransactions = useFetchLandLordTransactions({
    landlord: landLordId,
    page: pageindex,
    page_size: pagesize,
    search: search,
  });

  const [payloadForArchiveApi, setPayloadForArchiveApi] = useState({});
  const [archivePopup, setArchivePopup] = useState(false);
  const [selectedArchivedData, setSelectedArchivedData] = useState({});
  const [selectedExpenditureType, setSelectedExpenditureType] = useState("all");
  const [tableData, setTableData] = useState([]);
  const [tagactive, setTagActive] = useState("1");
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [generatePeriodicStatement, setGeneratePeriodicStatement] =
    useState(false);
  const fetchSpecificProperties =
    useFetchPropertiesCompliance(selectedPropertyId);
  const dispatch = useDispatch();

  const fetchLandLordExpenditure = useFetchLandLordExpenditure({
    landlord: landLordId,
    page: pageindex,
    page_size: pagesize,
    search: search,
    type: selectedExpenditureType,
  });
  useEffect(() => {
    if (fetchLandLordExpenditure.data?.data) {
      setTableData(fetchLandLordExpenditure.data?.data?.data);
      setCount(fetchLandLordExpenditure.data?.data?.count);
    }
  }, [fetchLandLordExpenditure.data, selectedExpenditureType]);
  const handlebuttonclick = (e) => {
    setTagActive(e.target.id);
  };
  useEffect(() => {
    if (rowvalue.id) {
      setLandLordId(rowvalue.id);
    }
  }, [rowvalue]);

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "address",
      cell: (info) => {
        console.log(info.row.original, "kajsdlfkjlaskjdlfij");
        const data = info.row.original;

        return (
          <span
            className="contentHover"
            style={{ wordBreak: "break-all" }}
            onClick={() => {
              dispatch(setPropertyDetailsFilter("1"));
              dispatch(setContactDetailsFilter(1));
              setSelectedPropertyId(data?.id);
              setpopup(true);
            }}
          >
            {formatAddress([
              data.name + " " + data.street,
              data.city,
              data.postcode,
            ])}
          </span>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Address
        </span>
      ),
    }),

    columnHelper.accessor((row) => row, {
      id: "rooms",
      cell: (info) => {
        const data = info.row.original?.rooms?.[0];
        const value =
          parseInt(data?.bed) ||
          0 + parseInt(data?.bath) ||
          0 + parseInt(data?.living) ||
          0;
        return (
          <p
            style={{
              textTransform: "capitalize",
              position: "relative",
              left: "25%",
            }}
          >
            {value || 0}
          </p>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Rooms
        </span>
      ),
    }),
    columnHelper.accessor((row) => row, {
      id: "status",
      cell: (info) => {
        console.log(info, "jkdldl");

        return (
          <p
            className={
              info.row?.original?.status === "Approved"
                ? "Approved"
                : "NotApproved"
            }
            style={{
              height: "auto",
              padding: "max(8px, 0.8vw) max(10px, 1vw)",
              // width: "205px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
              // fontSize: "max(12px, 1.1vw)",
              width: "fit-content",
              background:
                info.row?.original?.status === "Let in Progress"
                  ? "#CEFFE1"
                  : info.row?.original?.status === "Occupied"
                  ? "#CEFFE1"
                  : info.row?.original?.status === "Available"
                  ? "rgb(255,216,212)"
                  : info.row?.original?.status === "Vacate in Progress"
                  ? "rgb(255,240,204)"
                  : "",
            }}
          >
            {info.row?.original?.status === "Renewal in Progress"
              ? "Occupied"
              : info.row?.original?.status === "Let in Progress"
              ? "Occupied"
              : info.row?.original?.status === "Vacate in Progress"
              ? "Upcoming Vacancy"
              : info.row?.original?.status}
          </p>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Occupancy Status
        </span>
      ),
    }),
    columnHelper.accessor((row) => row, {
      id: "rent",
      cell: (info) => {
        const data = info.row.original?.tenant?.rent;
        console.log(info.row.original, "lakjsdlafj");
        return (
          <p style={{ textTransform: "capitalize" }}>
            {data ? `£${formatPounds(data)}` : "--"}
          </p>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Property Rent
        </span>
      ),
    }),
  ];
  const tenancyColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Tenant",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.tenant?.contact_detail?.[0];
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "max(12px, 1.1vw)",
              }}
            >
              {data?.title} {data?.first_name} {data?.last_name}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Start Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.start_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "max(12px, 1.1vw)",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "End Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.end_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "max(12px, 1.1vw)",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Rent",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.rent;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "max(12px, 1.1vw)",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Status",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.status;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "max(12px, 1.1vw)",
              }}
            >
              {data}
            </span>
          );
        },
      }),
    ],
    []
  );
  const transactionColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Amount",
        sortingFn: "basic",

        cell: (info) => {
          const debit = info.row.original?.debit;
          const credit = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",

                color: credit > 0 ? "green" : "red",
              }}
            >
              {`£${formatPounds(credit || debit)}`}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Download",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.amount;
          console.log(info.row.original, "asdfsfdsafd");

          return (
            data?.file?.[0] && (
              <p
                style={{
                  color: "#817EFF",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(data.file?.[0], "_blank");
                }}
              >
                Download&nbsp;&nbsp;
                <img
                  src={download}
                  width={"15px"}
                  height={"15px"}
                  alt="document image"
                />
              </p>
            )
          );
        },
      }),
    ],
    []
  );

  const fetchProperties = useFetchProperties({
    viewType: true,
    params: {
      landlord: landLordId,
      page: pageindex,
      page_size: pagesize,
      status: true,
    },
  });

  const cardData = [
    {
      title: "Net Amount",
      amount: formatPounds(
        fetchLandLordExpenditure.data?.data?.total_credit -
          fetchLandLordExpenditure.data?.data?.total_debit || 0
      ),
      imgSrc:
        selectedExpenditureType === "all" ? grossIncomeWhite : grossIncome,
      type: "all",
    },
    {
      title: "Total Income",
      amount: formatPounds(
        fetchLandLordExpenditure.data?.data?.total_credit || 0
      ),
      imgSrc: selectedExpenditureType === "income" ? incomeWhite : income,
      type: "income",
    },
    {
      title: "Total Expenditure",
      amount: formatPounds(
        fetchLandLordExpenditure.data?.data?.total_debit || 0
      ),
      imgSrc:
        selectedExpenditureType === "expense" ? expenditureWhite : expenditure,
      type: "expense",
    },
  ];
  const Documentstatus = (expiry_date, reminder_days) => {
    const todaysdate = new Date();
    const today = todaysdate.setHours(0, 0, 0, 0);

    if (!expiry_date) {
      return "no_expiry";
    }

    const expiryDate = new Date(expiry_date).setHours(0, 0, 0, 0);
    const reminderDate = new Date(expiry_date);
    reminderDate.setDate(reminderDate.getDate() - reminder_days);
    const formattedReminderDate = reminderDate.setHours(0, 0, 0, 0);

    if (today < formattedReminderDate) {
      return "not_due";
    } else if (today >= formattedReminderDate && today <= expiryDate) {
      return "due";
    } else {
      return "overdue";
    }
  };
  useEffect(() => {
    console.log(fetchLandlordTransactions.data, "lakjsdfljasjldaf");
  }, [fetchLandlordTransactions.data]);
  return (
    <div
      className={`popup_main_wrapper landlordDetailsPopup ${
        landlordPopup === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`content_wrapper ${
          landlordPopup === true ? "popup-open" : "popup-close"
        } `}
        // style={{ position: "relative", left: "18.8%", top: "6%" }}
        style={{ height: "auto" }}
      >
        <div className="contact_popup_header" style={{ height: "auto" }}>
          <div
            className="header_content-wrapper"
            style={{ position: "relative" }}
          >
            {/* <div className="col-1 p-0 m-0"> */}

            {/* </div> */}
            <div
              className="contact_title_wrapper"
              style={{
                width: "100%",
                // border: "1px solid black",
              }}
            >
              <div className="contact_pop_head_text">
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                    textTransform: "capitalize",
                    fontWeight: "400",
                  }}
                >
                  <img
                    src={landlordIcon}
                    style={{
                      width: "max(28px, 3.4vw)",
                      height: "max(24px, 3.1vw)",
                      borderRadius: "0",
                    }}
                  ></img>
                  {/* {`${rowvalue.contact_detail?.[0]?.title}. ${rowvalue.contact_detail?.[0]?.first_name} ${rowvalue.contact_detail?.[0]?.last_name}`} */}
                  {`${
                    rowvalue.company
                      ? rowvalue.company
                      : formatName(
                          rowvalue.contact_detail?.[0]?.title,
                          rowvalue.contact_detail?.[0]?.first_name,
                          rowvalue.contact_detail?.[0]?.last_name
                        )
                  }`}
                </h1>
              </div>

              <div className="contact_buttons_cover">
                <button
                  id={"1"}
                  onClick={handlebuttonclick}
                  className={tagactive == "1" ? "active" : "inActive"}
                >
                  Contacts
                </button>
                <button
                  id={"2"}
                  onClick={handlebuttonclick}
                  className={tagactive == "2" ? "active" : "inActive"}
                >
                  Documents
                </button>
                <button
                  id={"3"}
                  onClick={(e) => {
                    console.log(
                      fetchLandLordExpenditure.data?.data?.count,
                      "kjhawieksadfkhihaefkh"
                    );
                    handlebuttonclick(e);
                    setTableData(fetchLandLordExpenditure.data?.data?.data);
                    setCount(fetchLandLordExpenditure.data?.data?.count);
                  }}
                  className={tagactive == "3" ? "active" : "inActive"}
                >
                  Income and Expenditure
                </button>
                <button
                  id={"4"}
                  onClick={(e) => {
                    handlebuttonclick(e);
                    setTableData(fetchProperties.data?.data?.results);
                    setCount(fetchProperties.data?.data?.count);
                  }}
                  className={tagactive == "4" ? "active" : "inActive"}
                >
                  {" "}
                  Properties
                </button>
                <button
                  id={"5"}
                  onClick={(e) => {
                    console.log(
                      fetchLandlordTransactions.data?.data?.results,
                      "lakjsdfljasjldaflkasjlfdlajsdjf"
                    );
                    handlebuttonclick(e);
                    if (fetchLandlordTransactions.data?.data) {
                      setTableData(
                        fetchLandlordTransactions.data?.data?.results
                      );
                      setCount(fetchLandlordTransactions.data?.data?.count);
                    }
                  }}
                  className={tagactive == "5" ? "active" : "inActive"}
                >
                  Transactions
                </button>
              </div>
            </div>
            <div
              className="col-1 close_button_wrapper"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                paddingBottom: "0px",
              }}
            >
              <img
                style={{ borderRadius: "0" }}
                className="close"
                onClick={(e) => {
                  props.setpopup(false);
                }}
                src={closeSolidIcon}
              ></img>
            </div>
          </div>
        </div>
        <div className="contact_body_content_data">
          {tagactive == "2" ? (
            <>
              <div className="body_content_wrapper body_content_wrapperLandlord ">
                <div className="con_doc_left">
                  <h2>Documents</h2>
                </div>
                <div className="con_doc_right">
                  <div className="doc_card_cover_main">
                    {rowvalue?.document_types.map((ele) => {
                      console.log(ele, "laksjdfljalsjdflaldf");
                      return (
                        <div
                          className="doc_card_cover"
                          style={{
                            height: "23%",
                          }}
                        >
                          <div className="con_download_card">
                            <div
                              className="con_text"
                              onClick={() => {
                                if (ele.documents.length > 0) {
                                  window.open(ele.documents?.[0], "_blank");
                                }
                              }}
                            >
                              <div className="con_icon_cover">
                                <img src={ele.icon}></img>
                              </div>
                              <p>{ele.name}</p>
                            </div>

                            <div
                              className="dividerLine"
                              style={{
                                background:
                                  Documentstatus(
                                    ele.documents?.[0]?.expiry_date,
                                    ele.reminder_days
                                  ) === "due"
                                    ? "#ffb200"
                                    : Documentstatus(
                                        ele.documents?.[0]?.expiry_date,
                                        ele.reminder_days
                                      ) === "overdue"
                                    ? "#ff3a29"
                                    : Documentstatus(
                                        ele.documents?.[0]?.expiry_date,
                                        ele.reminder_days
                                      ) === "not_due"
                                    ? "#4dde87"
                                    : "black",
                              }}
                            ></div>
                            <div
                              className="bottomSection"
                              style={{
                                justifyContent:
                                  ele.documents.length === 0 && "center",
                              }}
                            >
                              {ele.documents.length > 0 ? (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          cursor:
                                            ele.documents.length > 0
                                              ? "pointer"
                                              : "default",
                                        }}
                                      >
                                        Expiry
                                      </span>
                                      <span style={{ color: "black" }}>
                                        {dayjs(
                                          ele.documents?.[0]?.expiry_date
                                        ).format("DD/MM/YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", gap: "8px" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                      onClick={() => {
                                        console.log(
                                          {
                                            d_id: ele.id,
                                            landlord:
                                              ele?.documents?.[0]
                                                ?.property_landlord,
                                          },
                                          "lakjsdljaljdsf"
                                        );
                                        console.log(ele, "lakjsdljaljdsf");
                                        setPayloadForArchiveApi({
                                          d_id: ele.id,
                                          landlord:
                                            ele?.documents?.[0]
                                              ?.property_landlord,
                                        });
                                        setSelectedArchivedData({
                                          custom_name: ele?.name,
                                        });
                                        setArchivePopup(true);
                                      }}
                                    >
                                      <span
                                        style={{
                                          cursor:
                                            ele.documents.length > 0
                                              ? "pointer"
                                              : "default",
                                        }}
                                      >
                                        Archived
                                      </span>
                                      <img
                                        src={archive}
                                        alt="document image"
                                        style={{
                                          width: "12px",
                                          height: "12px",
                                          cursor:
                                            ele.documents.length > 0
                                              ? "pointer"
                                              : "default",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                      onClick={() => {
                                        window.open(
                                          ele?.documents?.[0]?.file?.[0],
                                          "_blank"
                                        );
                                        {
                                          console.log(ele, "hjgvcfvghbnjkl");
                                        }
                                      }}
                                    >
                                      <span
                                        style={{
                                          cursor:
                                            ele.documents.length > 0
                                              ? "pointer"
                                              : "default",
                                        }}
                                      >
                                        Download
                                      </span>
                                      <img
                                        // src={ele.documents?.[0].documents?.[0]}
                                        src={download}
                                        width={"12px"}
                                        height={"12px"}
                                        alt="document image"
                                        style={{
                                          cursor:
                                            ele.documents.length > 0
                                              ? "pointer"
                                              : "default",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "max(9px, 0.8vw)",
                                    color: "red",
                                    justifyContent: "center",
                                  }}
                                >
                                  Document not upload yet.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : tagactive == "1" ? (
            <PropertyPropertyContacts
              dataFromParent={rowvalue}
              fromContact={fromContact}
            />
          ) : (
            <div className="con_popuptable" style={{ height: "100%" }}>
              {tagactive == "3" && (
                <div
                  className="con_incom_cards con_landlord_popup_cards"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                    width: "97%",
                    margin: "0 10px",
                    height: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      // width: "80%",
                      width: "100%",
                      maxWidth: "1500px",
                    }}
                  >
                    {cardData.map((card, index) => (
                      <div
                        className={`landlordPopupCard ${
                          selectedExpenditureType === card.type ? "active" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          setSelectedExpenditureType(card.type);
                          setPageIndex(1);
                        }}
                        // style={{ width: "max-content" }}
                        style={{
                          // width: "100%",
                          width: "max-content",
                          marginRight: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={card.imgSrc}
                            alt={card.title}
                            style={{
                              width: "clamp(16px, 1.9vw, 35px)",
                              height: "clamp(16px, 1.9vw, 35px)",
                            }}
                          />
                          <p>{card.title}</p>
                          <h1>£{card.amount}</h1>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div
                    className=""
                    style={{
                      alignItems: "flex-end",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "rgb(129, 126, 255)",
                        padding: "10px 15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        color: "white",
                        fontWeight: "500",
                        fontSize: "13px",
                        ":hover": {
                          backgroundColor: "rgb(129, 126, 255, 0.7)",
                        },
                      }}
                      onClick={() => {
                        setGeneratePeriodicStatement(true);
                      }}
                    >
                      Generate Statement
                    </button>
                  </div> */}
                </div>
              )}
              {tagactive === "5" && (
                <div
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    className=""
                    style={{
                      alignItems: "flex-end",
                      display: "flex",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "rgb(129, 126, 255)",
                        padding: "10px 15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        color: "white",
                        fontWeight: "500",
                        fontSize: "clamp(10px, 1vw, 28px)",
                        ":hover": {
                          backgroundColor: "rgb(129, 126, 255, 0.7)",
                        },
                      }}
                      onClick={() => {
                        setGeneratePeriodicStatement(true);
                      }}
                    >
                      Generate Statement
                    </button>
                  </div>
                </div>
              )}
              <div
                className={
                  tagactive == ""
                    ? "tenancyColumn"
                    : tagactive == "5"
                    ? "incomeexp"
                    : tagactive == "3"
                    ? "transactionColumn"
                    : "columns"
                }
              >
                <TableComponent
                  data={tableData ? tableData : []}
                  columns={
                    tagactive == ""
                      ? tenancyColumn
                      : tagactive == "5" || tagactive == "3"
                      ? transactionColumn
                      : columns
                  }
                  // onclickrow={clickrow}
                  count={count}
                  pageindex={pageindex}
                  pagesize={pagesize}
                  setPageIndex={setPageIndex}
                  setPageSize={setPageSize}
                  skeLetonLoading={
                    fetchProperties?.isFetching ||
                    fetchLandlordTransactions?.isFetching
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {payloadForArchiveApi !== "" && (
        <ArchivePopup
          isOpen={archivePopup}
          setIsOpen={setArchivePopup}
          dataFromParent={selectedArchivedData}
          payloadForArchiveApi={payloadForArchiveApi}
        />
      )}

      {fetchSpecificProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchSpecificProperties.data?.data}
          setpopup={setpopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={popup}
        />
      )}

      {generatePeriodicStatement && (
        <GeneratePeriodicStatement
          isOpen={generatePeriodicStatement}
          setIsOpen={setGeneratePeriodicStatement}
          landLordId={landLordId}
          type={"landlord"}
        />
      )}
    </div>
  );
}
