import React from "react";
import "../../../StyleSheet/Property/popups/propertydetails.scss";
import UploadPropertyDocument from "../popups/UploadPropertyDocument";
import { Skeleton } from "antd";

function PropertyDetailsUploadDoc({ dataFromParent, dataIsLoading }) {
  return (
    <div className="propertyDetailsTransactionsWrapper">
      {dataIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div
            className="headingWrapper"
            style={{
              flexDirection: "column",
              height: "100%",
              borderBottom: "none",
            }}
          >
            <h2 className="transactionsHeading" style={{ width: "100%" }}>
              Document Upload
            </h2>
            <UploadPropertyDocument
              dataFromParent={dataFromParent}
              propertyApprovalFolderId={dataFromParent?.document_types}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PropertyDetailsUploadDoc;
