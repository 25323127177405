import React, { useEffect, useMemo, useState } from "react";
import "../PropertyDetails-panel.scss";
import { createColumnHelper } from "@tanstack/react-table";
import { formatPounds, handleTruncate } from "../../../../Utils/Utils";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useFetchMaintenancePropertySpecific } from "../../../../Api/PropertiesQuery";
import Search from "../../../../MainComponent/fields/search";
import { Skeleton, Tooltip } from "antd";

function PropertyDetailsMaintenance() {
  const columnHelper = createColumnHelper();
  const { propertyId } = useParams();
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [search, setsearch] = useState("");

  const fetchMaintenanceProperties = useFetchMaintenancePropertySpecific({
    id: propertyId,
    page: pageindex,
    page_size: pagesize,
    search: search,
    type: "all",
  });

  const maintenanceColumn = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Reported Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.created_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "status",
        sortingFn: "basic",

        cell: (info) => {
          console.log(info.getValue());
          const data = info.row.original.status;

          return (
            <div
              style={{
                width: "195px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "8px 0px",
                borderRadius: "10px",
                fontWeight: "500",
                fontSize: "14px",
                gap: "6px",
                marginLeft: "6px",
              }}
            >
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "2px",
                  background:
                    data === "Quote Requested"
                      ? "#FFD47C"
                      : data === "New Requests"
                      ? "#97E9AC"
                      : data === "Awaiting payment"
                      ? "#FFB693"
                      : data === "Awaiting Approval"
                      ? "#FF8485"
                      : data === "Approved"
                      ? "#7DD1F3"
                      : data === "Booked"
                      ? "#8889F4"
                      : data === "Rejected"
                      ? "#EFA6A6"
                      : data === "Resolved"
                      ? "#A6EFC3"
                      : data === "More Info"
                      ? "#AC7AFF"
                      : "",
                }}
              ></span>
              <span
                style={{
                  fontSize: "14px",
                  // textWrap: "nowrap",
                  lineHeight: "1.1vw",
                }}
              >
                {data}
              </span>
            </div>
          );
        },
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor("summary", {
        header: "Summary",
        cell: (info) => {
          const data = info.row.original?.title;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor("description", {
        header: "Description",
        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-word",
                fontSize: "14px",
                maxWidth: "350px",
                display: "flex",
              }}
            >
              {handleTruncate(data, 55)}
              {data?.length > 55 && (
                <>
                  <Tooltip title={data}>
                    <div
                      data-tooltip-id="description-tooltip"
                      data-tooltip-content={data}
                      style={{
                        display: "inline-block",
                        width: "16px",
                        height: "16px",
                        cursor: "pointer",
                        position: "relative",
                        verticalAlign: "middle",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="rgb(0,0,0)"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                        }}
                      >
                        <path d="M12,24A12,12,0,1,0,0,12,12.013,12.013,0,0,0,12,24ZM12,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12,5Zm-1,5h1a2,2,0,0,1,2,2v6a1,1,0,0,1-2,0V12H11a1,1,0,0,1,0-2Z" />
                      </svg>
                    </div>
                  </Tooltip>
                </>
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("quote", {
        header: "Quote",
        cell: (info) => {
          const data = info.row.original?.invoice_amount;
          return (
            <span
              style={{
                // wordBreak: "break-all",
                display: "block",
                width: "max-content",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {data > 0 ? `£${formatPounds(data)}` : "--"}
            </span>
          );
        },
      }),
    ],
    []
  );
  useEffect(() => {
    if (fetchMaintenanceProperties.data?.data?.count)
      setCount(fetchMaintenanceProperties.data?.data?.count);
  }, [fetchMaintenanceProperties.data]);
  return (
    <div className="propertyDetailsTransactionsWrapper">
      {fetchMaintenanceProperties.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="headingWrapper">
            <h2 className="transactionsHeading">Maintenance</h2>
            <Search
              setsearch={setsearch}
              style={{ width: "200px" }}
              setPageIndex={setPageIndex}
              placeholder={"Search"}
            />
          </div>
          <TableComponent
            data={fetchMaintenanceProperties.data?.data?.results || []}
            columns={maintenanceColumn}
            count={count}
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </>
      )}
    </div>
  );
}

export default PropertyDetailsMaintenance;
