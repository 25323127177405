import React from "react";
import "../../../StyleSheet/Property/popups/propertydetails.scss";
import PropertyPropertyContacts from "../../Property/popups/PropertyPropertyContacts";
import { Skeleton } from "antd";

function PropertyDetailsContactDetails({
  dataFromParent,
  fromContact = false,
  dataIsLoading,
}) {
  return (
    <>
      {dataIsLoading ? (
        <Skeleton active />
      ) : (
        <PropertyPropertyContacts
          dataFromParent={dataFromParent}
          fromContact={fromContact}
        />
      )}
    </>
  );
}

export default PropertyDetailsContactDetails;
