import "../../../StyleSheet/Maintenance/popups/maintenanceoverviewpopup.scss";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import { createColumnHelper } from "@tanstack/react-table";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { useEffect, useMemo, useState } from "react";
import { useArrearsSummary } from "../../../../Api/clientmonetQuery";
import {
  useTodaysRentAgency,
  useTodaysRentAgencyTwo,
} from "../../../../Api/clientmonetQuery";
import { useDispatch, useSelector } from "react-redux";

import download from "../../../../Assets/Common/download.svg";
import {
  setClientRentPopup,
  setContactDetailsFilter,
  setPropertyDetailsCrumbs,
  setPropertyDetailsFilter,
} from "../../../../redux/reducer/counterslice";
import {
  formatAddress,
  formatName,
  formatPounds,
} from "../../../../Utils/Utils";
import { useFetchPropertiesCompliance } from "../../../../Api/PropertiesQuery";
import Propertydetails from "../../Property/popups/propertydetails";
import Search from "../../../../MainComponent/fields/search";
import { useNavigate } from "react-router-dom";

export default function ClientMoneyOverviewPopup(props) {
  const { todayRent } = props;
  const columnHelper = createColumnHelper();
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const [tabledata, settabledata] = useState([]);
  const clientid = useSelector((state) => state.counter.clientid);
  const [popup, setpopup] = useState(false);
  const [fetchType, setFetchType] = useState(null);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const popupStatus = useSelector((state) => state.counter.clientRentPopup);
  const navigate = useNavigate();

  useEffect(() => {
    if (props?.keydata?.text === "Today’s Rents") {
      setFetchType("Today’s Rents");
    } else if (props?.keydata?.text === "Today's Agency Income") {
      setFetchType("Today's Agency Income");
    } else if (props?.keydata?.subtext !== "") {
      setFetchType("Arrears");
    }
  }, [props?.keydata]);

  // const maintenanceData = useTodaysRentAgencyTwo(
  //   {
  //     params: {
  //       client: clientid,
  //       // type: "Rent",
  //       page: pageindex,
  //       page_size: pagesize,
  //       search: search,
  //       from_date: props?.fromDate,
  //       to_date: props?.toDate,
  //     },
  //   },
  //   fetchType === "Today’s Rents"
  // );
  const landlordId = useSelector((state) => state.counter.landlordId);

  const getAgencyRent = useTodaysRentAgency(
    {
      params: {
        client: clientid,
        landlord: landlordId?.length > 0 ? landlordId : null,
        // type: "Rent",
        page: pageindex,
        page_size: pagesize,
        search: search,
        from_date: props?.fromDate,
        to_date: props?.toDate,
      },
    },
    fetchType === "Today’s Rents"
  );

  useEffect(() => {
    console.log(search, "lkjihuygtfvgbhjk");
  }, [search]);
  const getAgencyManagementFee = useTodaysRentAgencyTwo(
    {
      params: {
        client: clientid,
        landlord: landlordId?.length > 0 ? landlordId : null,
        // type: "Management Fee",
        page: pageindex,
        page_size: pagesize,
        search: search,
        from_date: props?.fromDate,
        to_date: props?.toDate,
      },
    },
    fetchType === "Today's Agency Income"
  );

  const getArrears = useArrearsSummary(
    { params: { id: clientid, date_range: props?.keydata?.subtext } },
    fetchType === "Arrears"
  );

  useEffect(() => {
    if (fetchType === "Today’s Rents" && getAgencyRent.data) {
      settabledata(getAgencyRent.data?.data?.data);
      setCount(getAgencyRent.data?.data.count);
    } else if (
      fetchType === "Today's Agency Income" &&
      getAgencyManagementFee.data
    ) {
      settabledata(getAgencyManagementFee.data.data.data);
      setCount(getAgencyRent.data?.data?.count);
    } else if (fetchType === "Arrears" && getArrears.data) {
      settabledata(getArrears.data.data);
      setCount(getAgencyRent.data?.data?.count);
    }
  }, [
    fetchType,
    getAgencyRent.data,
    getAgencyManagementFee.data,
    getArrears.data,
    props?.keydata?.text,
  ]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "Tenant_name",
        header: () => (
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
            }}
          >
            Tenant Name
          </span>
        ),
        cell: (info) => {
          const data =
            info?.row?.original?.tenancy?.tenancies?.[0]?.contact_detail?.[0];
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(2));
                // setSelectedPropertyId(info.row.original.tenancy.property?.id);
                // setpopup(true);
                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Client Money",
                    fromUrl: "/clientmoney",
                  })
                );
                navigate(
                  `/property/propertyDetails/${info.row.original.tenancy.property?.id}`
                );
              }}
            >
              {/* {formatName(data?.title, data?.first_name, data?.last_name)} */}
              {data && data.first_name
                ? formatName(
                    data.title,
                    data.first_name,
                    data.last_name ? data.last_name : ""
                  )
                : "--"}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "address",
        header: () => (
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
            }}
          >
            Address
          </span>
        ),
        enableSorting: false,
        cell: (info) => {
          const data = info?.row?.original?.tenancy?.property;
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(1));
                // setSelectedPropertyId(data?.id);
                // setpopup(true);
                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Client Money",
                    fromUrl: "/clientmoney",
                  })
                );
                navigate(
                  `/property/propertyDetails/${info.row.original.tenancy.property?.id}`
                );
              }}
            >
              {formatAddress([
                data?.name + " " + data?.street,
                data?.city,
                data?.postcode,
              ])}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "description",
        header: () => (
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
            }}
          >
            Description
          </span>
        ),
        enableSorting: false,
        cell: (info) => {
          return (
            <span
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
            >
              {info.row.original.description}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "amount",
        header: () => (
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
            }}
          >
            Amount
          </span>
        ),
        cell: (info) => (
          <span
            style={{
              wordBreak: "break-all",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            £{formatPounds(info.row.original.amount)}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "download",
        enableSorting: false,
        header: () => (
          // {row.original?.file}
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
            }}
          >
            Download
          </span>
        ),
        cell: (info) => {
          return (
            info.row.original?.file.length > 0 && (
              <div style={{ display: "flex", gap: "10px", cursor: "pointer" }}>
                <p
                  style={{
                    color: "#817EFF",
                    fontSize: "clamp(12px, 0.7vw, 27px)",
                    // fontSize: "14px",
                  }}
                  onClick={() => {
                    window.open(info.row.original?.file, "_blank");
                  }}
                >
                  Receipts
                </p>
                <img src={download}></img>
              </div>
            )
          );
        },
      }),
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "Tenant_name",
        header: () => (
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
              // fontSize: "14px",
            }}
          >
            Tenant Name
          </span>
        ),
        cell: (info) => {
          const data = info?.row?.original?.tenancies?.tenant;
          return (
            <span style={{ wordBreak: "break-all", width: "100%" }}>
              {data?.first_name || data?.last_name ? (
                formatName(data?.title, data?.first_name, data?.last_name)
              ) : (
                <span
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginLeft: "15px",
                  }}
                >
                  --
                </span>
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "address",
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Address</span>
        ),
        enableSorting: false,
        cell: (info) => {
          const data = info?.row?.original?.property;
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                setSelectedPropertyId(data?.id);
                setpopup(true);
              }}
            >
              {formatAddress([
                data?.name + " " + data?.street,
                data?.city,
                data?.postcode,
              ])}
            </span>
          );
        },
      }),

      columnHelper.accessor("description", {
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>
            Commission
          </span>
        ),
        cell: (info) => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "amount",
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Amount</span>
        ),
        cell: (info) => (
          <span
            style={{
              wordBreak: "break-all",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            £{formatPounds(info?.row?.original?.amount)}
          </span>
        ),
      }),
    ],
    []
  );

  const columns3 = useMemo(
    () => [
      columnHelper.accessor("type", {
        header: () => (
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
              // fontSize: "14px",
            }}
          >
            Property Address
          </span>
        ),
        cell: (info) => {
          const data = info.row.original?.id;
          return (
            <span>
              {formatAddress([
                data?.name,
                data?.street,
                data?.city,
                data?.postcode,
              ])}
            </span>
          );
        },
      }),
      columnHelper.accessor("type", {
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Name</span>
        ),
        cell: (info) => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            {info.renderValue()}
          </span>
        ),
      }),
      columnHelper.accessor("total_arrears", {
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Amount</span>
        ),
        cell: (info) => (
          <span
            style={{
              wordBreak: "break-all",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            £ {info.getValue()}
          </span>
        ),
      }),
    ],
    []
  );

  return (
    <div
      className={`popup_main_wrapper ${
        popupStatus === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`main_popup ${
          popupStatus === true ? "popup-open" : "popup-close"
        } `}
        style={{
          // width: "1542px",
          height: "800",
          marginRight: "20px",
          position: "relative",
        }}
      >
        <div className="contact_popup_header">
          {/* <div className="content" > */}
          <div className="pop_head" style={{ marginBottom: "10px" }}>
            <h1>
              {props?.keydata?.text === "Today’s Rents"
                ? "Today’s Rent"
                : "Today's Agency Income"}{" "}
              {props?.keydata?.text === "Today’s Rents"
                ? props?.todayRent && (
                    <span style={{ color: "#817EFF" }}>
                      £{formatPounds(props?.todayRent)}{" "}
                    </span>
                  )
                : props?.todaysAgencyIncome && (
                    <span style={{ color: "#817EFF" }}>
                      £{formatPounds(props?.todaysAgencyIncome)}{" "}
                    </span>
                  )}
              {props?.keydata?.subtext && (
                <span>
                  {props?.keydata?.subtext}
                  <span style={{ color: "#817EFF" }}>{props?.todayRent}</span>
                </span>
              )}
            </h1>
            <div
              style={{
                display: "flex",
                gap: "max(10px, 0.8vw)",
              }}
            >
              <Search
                setsearch={setSearch}
                style={{ width: "250px" }}
                setPageIndex={setPageIndex}
              />
              <div className="col-1 close_button_wrapper">
                <img
                  className="close"
                  alt="close"
                  onClick={() => dispatch(setClientRentPopup(false))}
                  src={closeSolidIcon}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main_content">
          <TableComponent
            columns={
              props?.keydata?.text == "Today’s Rents"
                ? columns
                : props?.keydata?.text == "Today's Agency Income"
                ? columns2
                : columns3
            }
            data={tabledata}
            count={count}
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            skeLetonLoading={fetchProperties?.isFetching}
          />
        </div>
      </div>
      {/* {fetchProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setpopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={popup}
        />
      )} */}
    </div>
  );
}
