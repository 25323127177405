import React, { useState } from "react";
import "./PropertyDetails-panel.scss";
import ArchivePopup from "../../Property/popups/ArchivePopup";
import dayjs from "dayjs";
import archive from "../../../../Assets/Common/archive.svg";
import download from "../../../../Assets/Common/download.svg";
import { Skeleton } from "antd";

function PropertyDetailsDocuments({ dataFromParent, dataIsLoading }) {
  const [payloadForArchiveApi, setPayloadForArchiveApi] = useState({});
  const [selectedArchivedData, setSelectedArchivedData] = useState({});
  const [archivePopup, setArchivePopup] = useState(false);

  const Documentstatus = (expiry_date, reminder_days) => {
    const todaysdate = new Date();
    const today = todaysdate.setHours(0, 0, 0, 0);

    if (!expiry_date) {
      return "no_expiry";
    }

    const expiryDate = new Date(expiry_date).setHours(0, 0, 0, 0);
    const reminderDate = new Date(expiry_date);
    reminderDate.setDate(reminderDate.getDate() - reminder_days);
    const formattedReminderDate = reminderDate.setHours(0, 0, 0, 0);

    if (today < formattedReminderDate) {
      return "not_due";
    } else if (today >= formattedReminderDate && today <= expiryDate) {
      return "due";
    } else {
      return "overdue";
    }
  };
  return (
    <>
      <div className="propertyDetailsTransactionsWrapper">
        {dataIsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div
              className="headingWrapper"
              style={{
                flexDirection: "column",
                height: "100%",
                borderBottom: "none",
              }}
            >
              <h2 className="transactionsHeading" style={{ width: "100%" }}>
                Document Upload
              </h2>
              <div
                className="contact_body_content_data"
                style={{ height: "100%", width: "100%" }}
              >
                <div className="doc_con_cover" style={{ height: "100%" }}>
                  <>
                    <div
                      className="body_content_wrapper body_content_wrapperLandlord "
                      style={{ padding: "15px" }}
                    >
                      {/* <div className="con_doc_left">
                    <h2>Documents</h2>
                  </div> */}
                      <div className="con_doc_right">
                        <div className="doc_card_cover_main">
                          {dataFromParent?.document_types.map((ele) => {
                            return (
                              <div className="doc_card_cover">
                                <div className="con_download_card">
                                  <div
                                    className="con_text"
                                    onClick={() => {
                                      if (ele.documents.length > 0) {
                                        window.open(
                                          ele.documents?.[0],
                                          "_blank"
                                        );
                                      }
                                    }}
                                  >
                                    <div className="con_icon_cover">
                                      <img src={ele.icon}></img>
                                    </div>
                                    <p>{ele.name}</p>
                                  </div>

                                  <div
                                    className="dividerLine"
                                    style={{
                                      background:
                                        Documentstatus(
                                          ele.documents?.[0]?.expiry_date,
                                          ele.reminder_days
                                        ) === "due"
                                          ? "#ffb200"
                                          : Documentstatus(
                                              ele.documents?.[0]?.expiry_date,
                                              ele.reminder_days
                                            ) === "overdue"
                                          ? "#ff3a29"
                                          : Documentstatus(
                                              ele.documents?.[0]?.expiry_date,
                                              ele.reminder_days
                                            ) === "not_due"
                                          ? "#4dde87"
                                          : "black",
                                    }}
                                  ></div>
                                  <div
                                    className="bottomSection"
                                    style={{
                                      justifyContent:
                                        ele.documents.length === 0 && "center",
                                    }}
                                  >
                                    {ele.documents.length > 0 ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                cursor:
                                                  ele.documents.length > 0
                                                    ? "pointer"
                                                    : "default",
                                              }}
                                            >
                                              Expiry
                                            </span>
                                            <span style={{ color: "black" }}>
                                              {dayjs(
                                                ele.documents?.[0]?.expiry_date
                                              ).format("DD/MM/YYYY")}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "8px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                            onClick={() => {
                                              console.log(
                                                {
                                                  d_id: ele.id,
                                                  landlord:
                                                    ele?.documents?.[0]
                                                      ?.property_landlord,
                                                },
                                                "lakjsdljaljdsf"
                                              );
                                              console.log(
                                                ele,
                                                "lakjsdljaljdsf"
                                              );
                                              setPayloadForArchiveApi({
                                                d_id: ele.id,
                                                landlord:
                                                  ele?.documents?.[0]
                                                    ?.property_landlord,
                                              });
                                              setSelectedArchivedData({
                                                custom_name: ele?.name,
                                              });
                                              setArchivePopup(true);
                                            }}
                                          >
                                            <span
                                              style={{
                                                cursor:
                                                  ele.documents.length > 0
                                                    ? "pointer"
                                                    : "default",
                                              }}
                                            >
                                              Archived
                                            </span>
                                            <img
                                              src={archive}
                                              alt="document image"
                                              style={{
                                                width: "12px",
                                                height: "12px",
                                                cursor:
                                                  ele.documents.length > 0
                                                    ? "pointer"
                                                    : "default",
                                              }}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                            onClick={() => {
                                              window.open(
                                                ele?.documents?.[0]?.file?.[0],
                                                "_blank"
                                              );
                                              {
                                                console.log(
                                                  ele,
                                                  "hjgvcfvghbnjkl"
                                                );
                                              }
                                            }}
                                          >
                                            <span
                                              style={{
                                                cursor:
                                                  ele.documents.length > 0
                                                    ? "pointer"
                                                    : "default",
                                              }}
                                            >
                                              Download
                                            </span>
                                            <img
                                              // src={ele.documents?.[0].documents?.[0]}
                                              src={download}
                                              width={"12px"}
                                              height={"12px"}
                                              alt="document image"
                                              style={{
                                                cursor:
                                                  ele.documents.length > 0
                                                    ? "pointer"
                                                    : "default",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <p
                                        style={{
                                          fontSize: "max(9px, 0.8vw)",
                                          color: "red",
                                          justifyContent: "center",
                                        }}
                                      >
                                        Document not upload yet.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {payloadForArchiveApi !== "" && (
        <ArchivePopup
          isOpen={archivePopup}
          setIsOpen={setArchivePopup}
          dataFromParent={selectedArchivedData}
          payloadForArchiveApi={payloadForArchiveApi}
        />
      )}
    </>
  );
}

export default PropertyDetailsDocuments;
