import { useEffect, useState } from "react";
import Search from "../../../MainComponent/fields/search";
import "../../StyleSheet/Property/allproperties.scss";
import UploadButton from "../../../MainComponent/fields/upload";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import Propertydetails from "./popups/propertydetails";
import { createColumnHelper } from "@tanstack/react-table";
import PropertyCreate from "./popups/PropertyCreate";
import property_default from "../../../Assets/Common/property_default.jpg";
import {
  useFetchLandlordDetails,
  useFetchProperties,
  useFetchPropertiesCompliance,
} from "../../../Api/PropertiesQuery";
import { useDispatch, useSelector } from "react-redux";
import { addLeadingZero, formatName, formatPounds } from "../../../Utils/Utils";
import { useFetchPropertiesPagination } from "../../../Api/PaginationQuery";
import { useInView } from "react-intersection-observer";
import { Skeleton } from "antd";
import {
  setContactDetailsFilter,
  setPropertyDetailsCrumbs,
  setPropertyDetailsFilter,
} from "../../../redux/reducer/counterslice";
import LandLordPopup from "../Contacts/Popup/LandlordPopup";
import defaultPropertyalt from "../../../Assets/Common/defaultPropertyalt.svg";
import { useNavigate } from "react-router-dom";

export default function AllProperties() {
  const columnHelper = createColumnHelper();

  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [tableCount, setTableCount] = useState(0);

  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);

  const clientId = useSelector((state) => state.counter.loginuserdata.id);

  const [search, setsearch] = useState("");
  const [switcher, setswitcher] = useState(false);
  const [popup, setpopup] = useState(false);
  const [rowvalue, setrowvalue] = useState([]);
  const [dataLoader, seDataLoader] = useState([]);
  const [createPropertyPopup, setCreatePropertyPopup] = useState(false);
  const [editData, setEditData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [filter, setFiler] = useState("All");
  const [modifiedFilter, setModifiedFilter] = useState("All");
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedLandLordId, setSelectedLandLordId] = useState("");
  const fetchUniqueProperties =
    useFetchPropertiesCompliance(selectedPropertyId);
  const fetchUniqueLandlordDetails =
    useFetchLandlordDetails(selectedLandLordId);
  const [fetchUniquePropertiesPopup, setFetchUniquePropertiesPopup] =
    useState(false);
  const [fetchUniqueLandlordDetailsPopup, setFetchUniqueLandlordDetailsPopup] =
    useState(false);
  const dispatch = useDispatch();
  const filterGen = () => {};
  useEffect(() => {
    if (filter === "All") {
      setModifiedFilter("All");
    } else if (filter === "Approved") {
      setModifiedFilter("true");
    } else {
      console.log("filter", filter);

      setModifiedFilter("false");
    }
  }, [filter]);
  useEffect(() => {
    console.log(dataLoader, "modifiedFilter");
  }, [dataLoader]);
  const landlordId = useSelector((state) => state.counter.landlordId);
  const fetchProperties = useFetchProperties({
    viewType: switcher,
    params: {
      client: clientId,
      page: pageindex,
      page_size: pagesize,
      status: modifiedFilter,
      search: search,
      landlord: landlordId?.length > 0 ? landlordId : null,
    },
  });
  const { data, fetchNextPage, hasNextPage, isFetching } =
    useFetchPropertiesPagination({
      params: {
        client: clientId,
        landlord: landlordId?.length > 0 ? landlordId : null,
        status: modifiedFilter,
        page: pageindex,
        page_size: 20,
        search: search,
      },
    });
  useEffect(() => {
    if (data != undefined) {
      // dataLoader;
      const allResults = data?.pages?.flatMap((page) => page?.results);
      console.log(data, "lajkslfjasjdlaslfdj");
      seDataLoader(allResults);
      setCount(
        data?.pages?.[0]?.count ? data?.pages?.[0]?.count : data?.[0]?.count
      );
    }
  }, [data]);
  useEffect(() => {
    console.log(data, "lakjsljdfajksdflj");
  }, [data]);

  console.log(popup, "popup");
  function formatAddress(addressParts) {
    return addressParts
      .filter((part) => part) // Filters out undefined, null, and empty strings
      .join(", ");
  }
  const columns = [
    columnHelper.accessor((row) => row, {
      id: "address",
      enableSorting: false,

      cell: (info) => {
        const data = info.row.original;
        return (
          <span
            className=""
            style={{ wordBreak: "break-all", textTransform: "capitalize" }}
          >
            {formatAddress([data.name, data.street, data.city, data.postcode])}
          </span>
        );
      },
      header: () => <span>Address</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "landlordName",
      enableSorting: false,

      cell: (info) => {
        const data = info.row.original?.landlord?.contact_detail?.[0];
        return (
          <span
            className="contentHover"
            style={{ wordBreak: "break-all" }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setPropertyDetailsFilter("1"));
              dispatch(setContactDetailsFilter(1));
              setSelectedLandLordId(info.row.original.landlord?.id);
              setFetchUniqueLandlordDetailsPopup(true);
            }}
          >
            {info.row.original?.landlord?.company
              ? info.row.original?.landlord?.company
              : formatName(data?.title, data?.first_name, data?.last_name)}
          </span>
        );
      },
      header: () => <span>Landlord Name</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "rooms",
      enableSorting: false,

      cell: (info) => {
        const totalRooms = info.row.original?.rooms?.reduce((total, room) => {
          const bed = parseInt(room.bed) || 0;
          const bath = parseInt(room.bath) || 0;
          const garage = parseInt(room.garage) || 0;
          const living = parseInt(room.living) || 0;
          return total + bed + bath + garage + living;
        }, 0);
        return (
          <p style={{ textTransform: "capitalize" }}>
            {addLeadingZero(totalRooms)}
          </p>
        );
      },
      header: () => <span>Bedrooms</span>,
    }),
    columnHelper.accessor("status", {
      header: "Occupancy Status",
      cell: (info) => {
        console.log(info.getValue());

        return (
          <p
            className={
              info.getValue() === "Approved" ? "Approved" : "NotApproved"
            }
            style={{
              // fontSize: "max(10px, 0.8vw)",
              height: "auto",
              padding: "6px 12px",
              width: "100%",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100px",
              background:
                info.getValue() === "Let in Progress"
                  ? "#CEFFE1"
                  : info.getValue() === "Occupied"
                  ? "#CEFFE1"
                  : info.getValue() === "Available"
                  ? "rgb(255,216,212)"
                  : info.getValue() === "Vacate in Progress"
                  ? "rgb(255,240,204)"
                  : "",
            }}
          >
            {info.getValue() === "Renewal in Progress"
              ? "Occupied"
              : info.getValue() === "Let in Progress"
              ? "Occupied"
              : info.getValue() === "Vacate in Progress"
              ? "Upcoming Vacancies"
              : info.getValue() === "Available"
              ? "Vacant"
              : info.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "rent",
      cell: (info) => {
        const data = info.row.original?.tenant?.rent;
        console.log(info.row.original, "lakjsdlafj");
        return (
          <p style={{ textTransform: "capitalize" }}>
            {data ? `£${formatPounds(data)}` : "--"}
          </p>
        );
      },
      header: () => <span>Property Rent</span>,
    }),
  ];
  const clickrow = (row) => {
    console.log(row, "laksjdfljaskdfjasjofj");
    setSelectedData(row);
    setpopup(true);
    setrowvalue(row);
  };
  const handleCreateProperty = () => {
    setCreatePropertyPopup(true);
  };
  useEffect(() => {
    if (fetchProperties.data?.data?.results) {
      // seDataLoader(fetchProperties.data?.data?.results);
      console.log(fetchProperties.data?.data, "lasjdflasjdlfl");
      setTableData(fetchProperties.data?.data?.results);
      setTableCount(fetchProperties.data?.data?.count);
    }
  }, [fetchProperties.data, pageindex, pagesize, filter]);
  const loading = useSelector((state) => state.counter.loader);

  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);
  const buttonData = [
    { label: "All", filterValue: "All", className: "all" },
    { label: "Approved", filterValue: "Approved", className: "approved" },
    {
      label: "Under Approval",
      filterValue: "Under Approval",
      className: "notapproved",
    },
  ];

  return (
    <>
      <PropertyCreate
        isOpen={createPropertyPopup}
        setIsOpen={setCreatePropertyPopup}
        editData={editData}
        setEditData={setEditData}
      />
      <div className="allproperties_main_wrapper">
        <div className="header">
          <div
            className=" button_wrapper"
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              // width: "450px",
            }}
          >
            <div className="button_inside">
              <div
                className="allpropButtonsContainer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                {buttonData.map((button, index) => (
                  <div className="allpropButtons" key={index}>
                    <button
                      className={`${button.className} ${
                        filter === button.filterValue && "activated"
                      }`}
                      onClick={() => setFiler(button.filterValue)}
                      style={{ backgroundColor: "#fff", fontSize: "16px" }}
                    >
                      <span style={{ fontSize: "18px" }}>{button.label}</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="search_wrapper">
              <div
                className="search_sub_wrap"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="searchAndUpload">
                  <div
                    className=""
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: "15px",
                      justifyContent: "right",
                      alignItems: "center",
                      minWidth: "180px",
                    }}
                  >
                    {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                ></div> */}
                    <Search
                      setsearch={setsearch}
                      style={{ width: "210px" }}
                      setPageIndex={setPageIndex}
                      backgroundColor="#fff"
                      placeholder={"property"}
                      // width={{ width: "200px" }}
                    />
                  </div>
                  <div className="">
                    <UploadButton handleClick={handleCreateProperty} />
                  </div>
                  <div className="views_button_wrapper">
                    <button
                      className={!switcher ? "active" : ""}
                      onClick={(e) => {
                        setswitcher(false);
                      }}
                    >
                      <i className="bi bi-grid"></i>
                    </button>
                    <button
                      className={switcher ? "active" : ""}
                      onClick={(e) => {
                        setswitcher(true);
                      }}
                    >
                      <i className="bi bi-list"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="totalArrears"
            style={{
              margin: "0px",
              textAlign: "right",
            }}
          >
            <span
              style={{
                fontWeight: "500",
                fontSize: "clamp(12px, 1.25vw, 33px)",
                width: "auto",
              }}
            >
              Total Properties
            </span>
            <span style={{ width: "40px" }}>{addLeadingZero(count || 0)}</span>
          </div>
        </div>
        <div
          className="allproperties_wrapper"
          style={{
            background: switcher ? "white" : "",
            marginRight: "20px",
            marginLeft: "20px",
            // paddingTop: switcher ? "20px" : "",
            borderRadius: switcher ? "15px" : "",
          }}
        >
          {!switcher ? (
            <div className="row">
              {isFetching && dataLoader.length === 0 && (
                // {true && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    // gap: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  {Array.from({ length: 8 }).map((_, index) => (
                    <div key={index} className=" skeltonCardDiv">
                      <div className="backgroundClass">
                        <Skeleton.Image active />
                      </div>
                      <div className="backgroundClass">
                        <Skeleton active />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {dataLoader?.[0] !== undefined &&
                dataLoader.map((item, index) => {
                  return (
                    <div
                      className="col-md-6 col-sm-6 col-lg-4 col-xs-4 mb-4"
                      onClick={(e) => {
                        // clickrow(item);
                        dispatch(
                          setPropertyDetailsCrumbs({
                            fromTitle: "All Properties",
                            fromUrl: "/property/allproperties",
                          })
                        );
                        navigate(`/property/propertyDetails/${item.id}`);
                        // if (item.status !== "Available") {
                        // } else {
                        //   toast.warning("The selected property is vacant.");
                        // }
                      }}
                      id="property-cards"
                      style={{ width: "25%", borderRadius: "13px" }}
                    >
                      <div
                        className="prop_card"
                        style={{
                          background: "#ffffff",
                          paddingTop: "0.2%",
                        }}
                      >
                        <div
                          style={{
                            backgroundImage: item?.media?.[0]?.url
                              ? `url('${item?.media?.[0]?.url}')`
                              : item?.image?.[0]?.length > 0
                              ? `url('${item?.image?.[0]}')`
                              : `url(${defaultPropertyalt})`,
                          }}
                          className="imageDiv"
                        >
                          {/* <img
                            className="prop_card-img-top"
                            alt="..."
                            style={{ width: "100%" }}
                            src={
                              item?.media?.[0]?.url
                                ? item?.media?.[0]?.url
                                : item?.image?.[0]?.length > 0
                                ? item?.image?.[0]
                                : defaultPropertyalt
                            }
                          /> */}
                        </div>

                        <span
                          style={{
                            background:
                              item?.status === "Let in Progress"
                                ? "#CEFFE1"
                                : item?.status === "Occupied"
                                ? "#CEFFE1"
                                : item?.status === "Available"
                                ? "rgb(255,216,212)"
                                : item?.status === "Vacate in Progress"
                                ? "rgb(255,240,204)"
                                : "",
                          }}
                        >
                          {item?.status === "Let in Progress"
                            ? "Occupied"
                            : item?.status === "Vacate in Progress"
                            ? "Upcoming Vacancy"
                            : item?.status === "Available"
                            ? "Vacant"
                            : item?.status}
                        </span>
                        <div className="prop_card-body">
                          <h1
                            className="prop_card-title"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item?.name} {item?.street}
                          </h1>
                          <p className="prop_card-text">
                            {formatAddress([
                              item?.name + " " + item?.street,
                              item?.city,
                              item?.postcode,
                            ])}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div ref={ref} style={{ height: "1px" }} />
              {isFetching && dataLoader.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "#817EFF",
                  }}
                >
                  <p className="loading-text">Loading</p>
                </div>
              )}
            </div>
          ) : (
            <TableComponent
              data={tableData ? tableData : []}
              columns={columns}
              onclickrow={clickrow}
              count={tableCount}
              setCount={setTableCount}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          )}
        </div>
      </div>
      {popup ? (
        <Propertydetails
          rowvalue={rowvalue}
          setpopup={setpopup}
          selectedData={"selectedData"}
          propertyPopup={popup}
        />
      ) : (
        ""
      )}
      {fetchUniqueProperties.data?.data && fetchUniquePropertiesPopup && (
        <Propertydetails
          rowvalue={fetchUniqueProperties.data?.data}
          setpopup={setFetchUniquePropertiesPopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={fetchUniquePropertiesPopup}
        />
      )}{" "}
      {fetchUniqueLandlordDetails.data?.data &&
        setFetchUniqueLandlordDetailsPopup && (
          <LandLordPopup
            rowvalue={fetchUniqueLandlordDetails.data?.data}
            setpopup={setFetchUniqueLandlordDetailsPopup}
            selectedData={"selectedData"}
            landlordPopup={fetchUniqueLandlordDetailsPopup}
            fromContact={true}
          />
        )}
    </>
  );
}
