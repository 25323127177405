// import "../../StyleSheet/clientmoney/reports.scss";
// import GenerateReport from "../../../MainComponent/fields/generatereport";
// import Search from "../../../MainComponent/fields/search";
// import { useEffect, useState } from "react";

// export default function Reports() {
//   const [search, setsearch] = useState("");
//   const [toggle, settogle] = useState(true);
//   const [tabledata, settabledata] = useState({});
//   const [Generate, setGenerate] = useState("");

//   useEffect(() => {
//     if (toggle) {
//       settabledata({
//         "12.4.2020": [
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "Mr Ashokkumar Bhimani",
//             StatementNumber: "Agency Payment",
//             Amount: "340",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "faef",
//             StatementNumber: "0293",
//             Amount: "dfja",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "dfa",
//             StatementNumber: "10398019",
//             Amount: "dfja",
//           },
//         ],
//         "15.5.2021": [
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "xyzabc",
//             StatementNumber: "567890",
//             Amount: "1000",
//           },
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "abcdxyz",
//             StatementNumber: "123456",
//             Amount: "1500",
//           },
//         ],
//         "10.6.2022": [
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "lmnopqr",
//             StatementNumber: "987654",
//             Amount: "2000",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "qrstuv",
//             StatementNumber: "654321",
//             Amount: "2500",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "uvwxy",
//             StatementNumber: "321098",
//             Amount: "3000",
//           },
//         ],
//       });
//     } else {
//       settabledata({
//         "14.4.2020": [
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "Mr Sanjay Dharshan",
//             StatementNumber: "Agency Payment",
//             Amount: "340",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "faef",
//             StatementNumber: "0293",
//             Amount: "dfja",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "dfa",
//             StatementNumber: "10398019",
//             Amount: "dfja",
//           },
//         ],
//         "15.5.2021": [
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "xyzabc",
//             StatementNumber: "567890",
//             Amount: "1000",
//           },
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "abcdxyz",
//             StatementNumber: "123456",
//             Amount: "1500",
//           },
//         ],
//         "10.6.2022": [
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "lmnopqr",
//             StatementNumber: "987654",
//             Amount: "2000",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "qrstuv",
//             StatementNumber: "654321",
//             Amount: "2500",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "uvwxy",
//             StatementNumber: "321098",
//             Amount: "3000",
//           },
//         ],
//       });
//     }
//   }, [toggle]);

//   return (
//     <div className="reports">
//       <div className="header">
//         <div className="table_filter">
//           <div className="button_container">
//             <button
//               className={toggle ? "active" : ""}
//               onClick={(e) => {
//                 settogle(true);
//               }}
//             >
//               Landlord Statements
//             </button>
//             <button
//               className={!toggle ? "active" : ""}
//               onClick={(e) => {
//                 settogle(false);
//               }}
//             >
//               Agency Fees
//             </button>{" "}
//             <button
//               className={!toggle ? "active" : ""}
//               onClick={(e) => {
//                 settogle(false);
//               }}
//             >
//               Suppliers
//             </button>{" "}
//           </div>
//           <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//             <Search setsearch={setsearch} style={{ width: "410px" }} />
//             <GenerateReport
//               style={{ padding: "5px, 30px" }}
//               setGenerate={setGenerate}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="report_table">
//         {Object.keys(tabledata).map((key, index) => {
//           console.log(tabledata[key][0], "ajkahkj");
//           return (
//             <>
//               {index == 0 && (
//                 <table className="custom-table">
//                   <thead>
//                     <tr>
//                       {Object.keys(tabledata[key][0]).map((value, index1) => {
//                         return (
//                           <>
//                             <th>{value}</th>
//                             {index1 + 1 ==
//                             Object.keys(tabledata[key][0]).length ? (
//                               <th>
//                                 <p></p>Download
//                               </th>
//                             ) : (
//                               ""
//                             )}
//                           </>
//                         );
//                       })}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr style={{ border: "none" }}>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                     </tr>
//                   </tbody>
//                 </table>
//               )}
//               <div className="contenttable">
//                 <table className={`subtable ${index != 0 ? "mt-5" : ""}`}>
//                   <thead>
//                     <tr>
//                       <th>{key}</th>
//                       <th></th>
//                       <th></th>
//                       <th></th>
//                       <th></th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {tabledata[key].map((val, index) => {
//                       console.log(val, "val");
//                       return (
//                         <tr>
//                           <td>{val.DateProcessed}</td>
//                           <td>{val.LandlordName}</td>
//                           <td>{val.StatementNumber}</td>
//                           <td>£ {val.Amount}</td>
//                           <td>
//                             <p style={{ color: "#817EFF" }}>Download</p>
//                           </td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                 </table>
//               </div>
//             </>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
import "../../StyleSheet/clientmoney/reports.scss";
import "../../StyleSheet/clientmoney/paymentdue.scss";
import Search from "../../../MainComponent/fields/search";
import { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "rsuite";
import dayjs from "dayjs";
import { createColumnHelper } from "@tanstack/react-table";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { formatName, formatPounds } from "../../../Utils/Utils";
import download from "../../../Assets/Common/download.svg";
import { useSelector } from "react-redux";
import {
  useAgencyFeeDetailsTimeline,
  useGetLandlordStatements,
} from "../../../Api/clientmonetQuery";
export default function Reports() {
  const [search, setsearch] = useState("");
  const [toggle, settogle] = useState({
    label: "Landlord Statements",
    value: "get_reports_landlord",
  });
  const [tabledata, settabledata] = useState([]);
  const [Generate, setGenerate] = useState("");
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const columnHelper = createColumnHelper();
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const clientid = useSelector((state) => state.counter.clientid);
  const landlordId = useSelector((state) => state.counter.landlordId);

  const agencyFeeDetailsTimeline = useAgencyFeeDetailsTimeline({
    type: toggle?.value || "",
    data: {
      params: {
        client: clientid || "",
        landlord: landlordId?.length > 0 ? landlordId : null,
        from_date: startDate || "",
        to_date: endDate || "",
      },
    },
  });
  const { data: reportData } = useGetLandlordStatements({
    reportType: toggle.value,
    data: {
      params: {
        from_date: startDate,
        to_date: endDate,
        client: clientid,
        landlord: landlordId?.length > 0 ? landlordId : null,
        page: pageindex,
        page_size: pagesize,
      },
    },
  });
  const commonTextStyle = {
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "400",
    color: "black",
  };
  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor((row) => row, {
        header: "Date",
        id: "date",
        disableSorting: true,
        cell: ({ row: { original } }) => (
          <span style={commonTextStyle}>
            {dayjs(original?.charge_date).format("DD/MM/YYYY")}
          </span>
        ),
      }),

      columnHelper.accessor((row) => row, {
        header: "Statement Number",
        id: "description",
        disableSorting: true,
        cell: ({ row: { original } }) => (
          <span style={commonTextStyle}>{original?.statement_number}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        header: "Amount",
        id: "amount",
        cell: ({ row: { original } }) => {
          const data = original?.credit || original?.amount;
          return (
            <span style={commonTextStyle}>
              {data > 0 ? `£${formatPounds(data)}` : "--"}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "document",
        enableSorting: false,
        header: () => <span>Document</span>,
        cell: ({ row: { original } }) => (
          <p
            style={{ color: "#817EFF", cursor: "pointer" }}
            onClick={() => window.open(original?.report, "_blank")}
          >
            Download&nbsp;&nbsp;
            <img
              src={download}
              width="15px"
              height="15px"
              alt="document image"
            />
          </p>
        ),
      }),
    ];

    // Conditionally add the "Landlord Name" column
    if (toggle?.value !== "get_reports_agency_fees") {
      baseColumns.splice(
        1,
        0, // Insert at the second position
        columnHelper.accessor((row) => row, {
          header:
            toggle?.value === "get_reports_landlord"
              ? "Landlord Name"
              : "Supplier Name",
          id: "name",
          disableSorting: true,
          cell: ({ row: { original } }) => {
            const { contact_detail } =
              original?.landlord || original?.supplier || {};
            const entryPoint = contact_detail?.[0];
            const data = formatName(
              entryPoint?.title,
              entryPoint?.first_name,
              entryPoint?.last_name
            );
            return <span style={commonTextStyle}>{data}</span>;
          },
        })
      );
    }

    return baseColumns;
  }, [toggle, reportData, agencyFeeDetailsTimeline.data]);

  const today = new Date();

  const disableFutureDates = (date) => {
    return date > today;
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(dayjs(start).format("YYYY-MM-DD"));
      setEndDate(dayjs(end).format("YYYY-MM-DD"));
    } else {
      setStartDate(dayjs().format("YYYY-MM-DD"));
      setEndDate(dayjs().format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    if (reportData) {
      setCount(reportData?.data?.count);
      settabledata(reportData.data.results);
    }
  }, [reportData, toggle, startDate, endDate]);
  useEffect(() => {
    if (agencyFeeDetailsTimeline?.data?.data) {
      settabledata(agencyFeeDetailsTimeline?.data?.data);
      setCount(agencyFeeDetailsTimeline?.data?.data_count || 0);
    }
  }, [agencyFeeDetailsTimeline?.data, toggle, startDate, endDate]);
  const assignTableData =
    toggle?.value === "get_reports_agency_fees"
      ? agencyFeeDetailsTimeline?.data?.data || []
      : reportData?.data?.results || [];

  return (
    <div className="reports">
      <div className="header">
        <div className="table_filters">
          <div className="button_container">
            <button
              className={toggle.label === "Landlord Statements" ? "active" : ""}
              onClick={(e) => {
                setPageIndex(1);
                setPageSize(20);
                settogle({
                  label: "Landlord Statements",
                  value: "get_reports_landlord",
                });
              }}
            >
              Landlord Statements
            </button>
            <button
              className={toggle.label === "Agency Fees" ? "active" : ""}
              onClick={(e) => {
                setPageIndex(1);
                setPageSize(20);
                settogle({
                  label: "Agency Fees",
                  value: "get_reports_agency_fees",
                });
              }}
            >
              Agency Fees
            </button>{" "}
            <button
              className={toggle.label === "Suppliers" ? "active" : ""}
              onClick={(e) => {
                setPageIndex(1);
                setPageSize(20);
                settogle({
                  label: "Suppliers",
                  value: "get_reports_supplier",
                });
              }}
            >
              Suppliers
            </button>{" "}
          </div>
        </div>
      </div>
      <div
        style={{
          background: "white",
          borderRadius: "13px",
          height: "calc(100% - 55px)",
          overflow: "auto",
        }}
      >
        <div className="header">
          <div
            className="table_filters"
            style={{
              padding: "20px 27px",
              margin: "0",
            }}
          >
            <div>
              <DateRangePicker
                placeholder="Select date range"
                format="dd MMM yyyy"
                character="    –    "
                size="lg"
                showHeader={false}
                className="custom-date-range-picker"
                disabledDate={disableFutureDates} // Disable future dates
                value={[new Date(startDate), new Date(endDate)]} // Controlled value
                onChange={handleDateChange} // Handle date changes
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                // padding: "20px",
              }}
            >
              <div className="report-search">
                <Search
                  setsearch={setsearch}
                  style={{ width: "200px" }}
                  setPageIndex={setPageIndex}
                  placeholder={"report"}
                />
              </div>
              {/* <GenerateReport
                style={{ padding: "5px, 30px" }}
                setGenerate={setGenerate}
              /> */}
            </div>
          </div>
        </div>
        <div className="tableComp">
          <TableComponent
            data={assignTableData}
            columns={columns}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
}
