import React, { useEffect, useState } from "react";
// import "./ClientCreate.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import blTopLeft from "../../Assets/Common/bl_TopLeft.svg";
import invalidLink from "../../Assets/Common/invalidLink.svg";
import bl_BottomRight from "../../Assets/Common/bl_BottomRight.svg";
import solace_Logo from "../../Assets/Common/solace_Logo.svg";
import success_check from "../../Assets/Common/success_check.svg";
import { PasswordInput } from "../GlobalComponents/othercomponents/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { useCreateInvite, useLinkValidation } from "../../Api/homeQuery";
import DefaultLoader from "../GlobalComponents/DefaultLoader";
import { token } from "../../redux/reducer/counterslice";

import {
  loginuserdata,
  setAllowFullPortalAccess,
} from "../../redux/reducer/counterslice";
// Component for creating a new SuperAdmin account
function ClientCreate() {
  // Get the id from the URL parameters
  const { id } = useParams();
  // const token = useSelector((state) => state.counter.token);
  // Set up state for the password and confirm password inputs
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateLink = useLinkValidation({
    params: { type: "client", id: id },
  });
  const dispatch = useDispatch();

  // Set up state for tracking whether the account creation was successful
  const [accountSuccess, setAccountSuccess] = useState(false);
  const [validateUrl, setValidateUrl] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  // Set up navigation for redirecting to the login page
  const navigate = useNavigate();

  const handleCreateInvite = useCreateInvite({
    data: {
      password: password,
      is_active: true,
    },
    setAccountSuccess: setAccountSuccess,
    id: id,
  });

  const validatePassword = (password) => {
    const minLength = 8;
    const maxLength = 16;
    const numberRegex = /\d/;
    const capitalLetterRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    // Check length
    if (password.length < minLength || password.length > maxLength) {
      return "Password must be between 8 and 16 characters long.";
    }

    // Check for at least one number
    if (!numberRegex.test(password)) {
      return "Password must contain at least one number.";
    }

    // Check for at least one capital letter
    if (!capitalLetterRegex.test(password)) {
      return "Password must contain at least one capital letter.";
    }

    // Check for at least one special character
    if (!specialCharRegex.test(password)) {
      return "Password must contain at least one special character.";
    }

    // Password meets all conditions
    return "Password is valid.";
  };

  // Validate the password inputs and create the new SuperAdmin account
  const validate = () => {
    if (password === "" || confirmPassword === "") {
      toast.error("Password fields cannot be empty.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    const response = validatePassword(confirmPassword);
    if (response === "Password is valid.") {
      console.log("Response:", response);
      handleCreateInvite.mutate();
    } else {
      toast.error(response);
    }
  };
  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    if (validateLink.isLoading) {
      setIsValidating(true);
    } else {
      setIsValidating(false);
      if (
        validateLink?.error &&
        validateLink.error.includes("400") &&
        firstTime
      ) {
        setValidateUrl(true);
        setFirstTime(false);
      }
    }
  }, [validateLink]);
  // const { loginuserdata } = useSelector((state) => state.counter);
  // const { allowFullPortalAccess } = useSelector((state) => state.counter);
  useEffect(() => {
    dispatch(token(""));
    dispatch(setAllowFullPortalAccess(null));
    dispatch(loginuserdata(null));
  }, []);

  if (isValidating) {
    return (
      <div className="passwordCreate">
        <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
        <img className="bottomLeft" src={bl_BottomRight} alt="bl_BottomRight" />
        <div className="skeleton-container">
          <div className="skeleton-logo skeleton-animate"></div>
          <div className="skeleton-text skeleton-animate"></div>
          <div className="skeleton-text skeleton-animate"></div>
          <div className="skeleton-input skeleton-animate"></div>
          <div className="skeleton-input skeleton-animate"></div>
          <div className="skeleton-button skeleton-animate"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Toaster richColors />
      {handleCreateInvite?.isPending && <DefaultLoader />}
      {!validateUrl ? (
        <div className="passwordCreate">
          <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
          <img
            className="bottomLeft"
            src={bl_BottomRight}
            alt="bl_BottomRight"
          />
          <div className="innerPasswordCreate">
            <div className="topSection">
              <img src={solace_Logo} alt="solace_Logo" />
            </div>
            {!accountSuccess && (
              <div className="textSection">
                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                  Account Creation Portal
                </p>
                <p style={{ fontSize: "16px", fontWeight: "400" }}>
                  Please enter your new password to update your account
                  credentials.
                </p>
              </div>
            )}
            {accountSuccess && (
              <div className="successSection">
                <img src={success_check} alt="success_check" />
                <p style={{ textAlign: "center" }}>
                  <strong>Your account has been created</strong>
                </p>
              </div>
            )}

            <div className="mainPasswordSection">
              {accountSuccess ? (
                // <p
                //   style={{ color: "black", fontSize: "16px", lineHeight: "35px" }}
                // >
                //   Lorem Ipsum has been the industry's standard dummy text ever
                //   since the 1500s, when an unknown printer took a galley of type
                //   and scrambled
                // </p>
                ""
              ) : (
                <>
                  <div
                    style={{
                      backgroundColor: "white",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <label style={{ fontSize: "20px", fontWeight: "400" }}>
                      Password
                    </label>
                    <PasswordInput
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      placeholder="New password"
                      name="password"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                      marginTop: "25px",
                    }}
                  >
                    <label style={{ fontSize: "20px", fontWeight: "400" }}>
                      Confirm Password
                    </label>

                    <PasswordInput
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      placeholder="Confirm password"
                      name="confPassword"
                      width="100%"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="footerSection">
              {accountSuccess ? (
                <button
                  className="upload_button"
                  onClick={() => {
                    localStorage.clear();
                    navigate("/login");
                  }}
                >
                  <span>Login</span>&nbsp;
                </button>
              ) : (
                <button
                  className="upload_button"
                  onClick={() => {
                    validate();
                  }}
                >
                  <span>Submit</span>&nbsp;
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="passwordCreate">
          <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
          <img
            className="bottomLeft"
            src={bl_BottomRight}
            alt="bl_BottomRight"
          />
          <div className="innerPasswordCreate" style={{ minHeight: "200px" }}>
            <div className="topSection">
              <img src={solace_Logo} alt="solace_Logo" />
            </div>

            <div className="successSection">
              <img src={invalidLink} alt="success_check" />
              <p style={{ textAlign: "center" }}>
                <strong style={{ textAlign: "center" }}>Link Expired</strong>
              </p>
            </div>

            <div className="mainPasswordSection">
              <p
                style={{
                  color: "black",
                  fontSize: "16px",
                  lineHeight: "35px",
                }}
              >
                Your link has expired. If you encounter any issues, please
                contact the respective property manager for further assistance.{" "}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { ClientCreate };

// Import necessary libraries, modules, and components
// Get the id from the URL parameters using react-router's useParams hook
// Set up state for the password and confirm password inputs
// Set up state for tracking whether the account creation was successful
// Set up navigation for redirecting to the login page
// Handle changes to the password input
// Handle changes to the confirm password input
// Set up the mutation for creating a new SuperAdmin account
// Validate the password inputs and create the new SuperAdmin account
// Display the password creation form and success message
// Display error messages using the Sonner toast library
