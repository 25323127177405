import React, { useEffect, useState } from "react";
import "../../../StyleSheet/Property/popups/propertydetails.scss";
import name from "../../../../Assets/Common/name.svg";
import email from "../../../../Assets/Common/email.svg";
import telephone from "../../../../Assets/Common/telephone.svg";
import companyIcon from "../../../../Assets/Common/companyIcon.svg";
import address from "../../../../Assets/Common/address.svg";
import { formatAddress, formatName } from "../../../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setContactDetailsFilter,
  setPropertyDetailsFilter,
} from "../../../../redux/reducer/counterslice";
import { useFetchLandlordDetails } from "../../../../Api/PropertiesQuery";
import LandLordPopup from "../../Contacts/Popup/LandlordPopup";

const PropertyPropertyContacts = (props) => {
  const { dataFromParent, fromContact } = props;
  const tagActive = useSelector((state) => state.counter.contactDetailsFilter);
  const [selectedLandLordId, setSelectedLandLordId] = useState("");
  const fetchLandlordDetails = useFetchLandlordDetails(selectedLandLordId);
  const [landLordPopup, setLandLordPopup] = useState(false);

  const dispatch = useDispatch();
  const formatUKPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string") {
      return ""; // Return an empty string or handle the case as needed
    }

    return phoneNumber.replace(/^0/, "+44 ").replace(/(\d{4})(\d{6})/, "$1 $2");
  };
  const data = [
    {
      image: companyIcon,
      title: "Company",
      data: dataFromParent?.company
        ? dataFromParent?.company
        : dataFromParent?.landlord?.company,
    },
    {
      image: name,
      title: "Contact Name",
      id: dataFromParent?.landlord?.id,
      data: dataFromParent?.first_name
        ? formatName(
            dataFromParent?.title,
            dataFromParent?.first_name,
            dataFromParent?.last_name
          )
        : formatName(
            dataFromParent?.landlord?.contact_detail?.[0]?.title,
            dataFromParent?.landlord?.contact_detail?.[0]?.first_name,
            dataFromParent?.landlord?.contact_detail?.[0]?.last_name
          ),
    },
    {
      image: telephone,
      title: "Telephone",
      data: formatUKPhoneNumber(
        dataFromParent?.phone
          ? formatUKPhoneNumber(dataFromParent?.phone)
          : dataFromParent?.landlord?.contact_detail?.[0]?.telephone?.[0]
              ?.number
      ),
    },
    {
      image: email,
      title: "Email",
      data: dataFromParent?.email
        ? dataFromParent?.email
        : dataFromParent?.landlord?.contact_detail?.[0]?.email?.[0],
    },
    {
      image: address,
      title: "Address",
      data: dataFromParent?.property_landlord_address?.length
        ? formatAddress([
            dataFromParent?.property_landlord_address?.[0]?.Name +
              " " +
              dataFromParent?.property_landlord_address?.[0]?.street,
            dataFromParent?.property_landlord_address?.[0]?.town,
            dataFromParent?.property_landlord_address?.[0]?.postcode,
          ])
        : dataFromParent?.landlord?.property_landlord_address?.length
        ? formatAddress([
            dataFromParent?.landlord?.property_landlord_address?.[0]?.Name +
              " " +
              dataFromParent?.landlord?.property_landlord_address?.[0]?.street,
            dataFromParent?.landlord?.property_landlord_address?.[0]?.town,
            dataFromParent?.landlord?.property_landlord_address?.[0]?.postcode,
          ])
        : "--",
    },
  ];
  const tenantData = [
    {
      image: name,
      title: "Name",
      data: formatName(
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.title,
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.first_name,
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.last_name
      ),
    },
    {
      image: telephone,
      title: "Telephone",
      data: formatUKPhoneNumber(
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.telephone?.[0]
          ?.number
      ),
    },
    {
      image: email,
      title: "Email",

      data: dataFromParent?.tenant?.tenant__contact_detail?.[0]?.email?.[0],
    },
  ];
  const [occupants, setOccupants] = useState([]);

  useEffect(() => {
    const occupantsData =
      dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenants;

    if (occupantsData) {
      const formattedOccupants = occupantsData.map((item) => [
        {
          image: name,
          title: "Name",
          data:
            formatName(
              item?.contact_detail?.[0]?.title,
              item?.contact_detail?.[0]?.first_name,
              item?.contact_detail?.[0]?.last_name
            ) || "-",
        },
        {
          image: telephone,
          title: "Telephone",
          data:
            item?.contact_detail?.[0]?.telephone?.[0]?.number.length > 0
              ? formatUKPhoneNumber(
                  item?.contact_detail?.[0]?.telephone?.[0]?.number
                )
              : "-",
        },
        {
          image: email,
          title: "Email",
          data: item?.contact_detail?.[0]?.email?.[0] || "-",
        },
      ]);

      setOccupants(formattedOccupants);
    }
  }, [dataFromParent]);

  const leadTenantData = dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant;
  const contactTenantData = [
    {
      image: name,
      title: "Name",
      data:
        formatName(
          leadTenantData?.contact_detail?.[0]?.title,
          leadTenantData?.contact_detail?.[0]?.first_name,
          leadTenantData?.contact_detail?.[0]?.last_name
        ) || "-",
    },
    {
      image: telephone,
      title: "Telephone",
      data:
        leadTenantData?.contact_detail?.[0]?.telephone?.[0]?.number.length > 0
          ? formatUKPhoneNumber(
              leadTenantData?.contact_detail?.[0]?.telephone?.[0]?.number
            )
          : "-",
    },
    {
      image: email,
      title: "Email",

      data: leadTenantData?.contact_detail?.[0]?.email?.[0] || "-",
    },
  ];
  const title = dataFromParent?.tenant?.tenant__contact_detail?.[0]?.title;
  useEffect(() => {
    if (dataFromParent?.first_name || dataFromParent?.last_name) {
      dispatch(setContactDetailsFilter(1));
    }
  }, [dataFromParent]);

  return (
    <div className="propertyContactsWrapper">
      <div className="button_wrapper">
        <button
          className={tagActive === 1 ? "active" : ""}
          onClick={() => {
            dispatch(setContactDetailsFilter(1));
          }}
        >
          Landlord
        </button>
        {(!dataFromParent?.first_name || !dataFromParent?.last_name) && (
          <button
            className={tagActive === 2 ? "active" : ""}
            onClick={() => {
              dispatch(setContactDetailsFilter(2));
            }}
          >
            Tenant
          </button>
        )}
      </div>

      <div className="contentDiv" style={{ height: "auto", minHeight: "100%" }}>
        {tagActive === 1 && (
          <div className="landlordDetails">
            <p
              className="title"
              style={{ height: "auto", padding: "clamp(12px, 1vw, 20px) 0" }}
            >
              Landlord Contact Details
            </p>
            <div className="detailsSectionWrapper">
              {data.map((items, index) => {
                return (
                  <div
                    key={index}
                    className="singleRecord"
                    style={{ marginBottom: "clamp(10px, 1vw, 16px)" }}
                  >
                    <div className="leftSection">
                      <img
                        src={items.image}
                        alt={index}
                        style={{ height: "30px", width: "30px" }}
                      />
                      <span>{items.title}</span>
                    </div>
                    <p
                      className={index === 1 && "contentHover"}
                      style={{
                        wordBreak: "break-all",
                        textTransform:
                          items.title === "Email" ? "lowercase" : "capitalize",
                      }}
                      onClick={() => {
                        if (index === 1) {
                          dispatch(setPropertyDetailsFilter("1"));
                          dispatch(setContactDetailsFilter(1));
                          setSelectedLandLordId(items.id);
                          setLandLordPopup(true);
                        }
                      }}
                    >
                      {items.data}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {tagActive === 2 && (
          <div className="landlordDetails">
            <p
              className="title"
              style={{ height: "auto", padding: "clamp(11px, 0.95vw, 16px) 0" }}
            >
              Tenant Details
            </p>
            <div className="detailsSectionWrapper" style={{ height: "auto" }}>
              {!fromContact ? (
                title ? (
                  <>
                    <p
                      style={{
                        fontSize: "clamp(14px, 1.2vw, 45px)",
                        marginBottom: "10px",
                      }}
                    >
                      Lead Tenant
                    </p>
                    {tenantData.map((items, index) => (
                      <div key={index} className="singleRecord">
                        <div
                          className="leftSection"
                          style={{
                            height: "auto",
                            margin: "clamp(4px, 0.4vw, 10px) 0",
                          }}
                        >
                          <img src={items.image} alt={index} />
                          <span>{items.title}</span>
                        </div>
                        <p
                          style={{
                            textTransform:
                              items.title === "Email"
                                ? "lowercase"
                                : "capitalize",
                          }}
                        >
                          {items.data}
                        </p>
                      </div>
                    ))}
                    {/* <p style={{ fontSize: "22px", margin: "20px 0" }}>
                      Occupants
                    </p>
                    <div
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        gap: "20px",
                      }}
                    >
                      {[0, 1, 2, 3, 4].map((item) => (
                        <div
                          style={{
                            display: "flex",
                            height: "39%",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              padding: "10px",
                              backgroundColor: "rgb(129,126,255, 0.1)",
                              borderRadius: "15px",
                              width: "max-content",
                              boxShadow:
                                "3px 3px 20px 4px rgb(129,126,255, 0.1)",
                            }}
                          >
                            {tenantData.map((items, index) => (
                              <div key={index} className="singleRecord">
                                <div
                                  className="leftSection"
                                  style={{
                                    height: "auto",
                                    margin: "10px 10px 10px 0",
                                  }}
                                >
                                  <img
                                    src={items.image}
                                    alt={index}
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                  <span style={{ marginRight: "10px" }}>
                                    {items.title}
                                    &nbsp;
                                  </span>
                                </div>
                                <p
                                  style={{
                                    textTransform: "capitalize",
                                    width: "max-content",
                                  }}
                                >
                                  {items.data}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div> */}
                  </>
                ) : (
                  <div>
                    <p>No tenant assigned to this property</p>
                  </div>
                )
              ) : (
                <>
                  <p
                    style={{
                      fontSize: "clamp(14px, 1.2vw, 45px)",
                      marginBottom: "10px",
                    }}
                  >
                    Lead Tenant
                  </p>
                  {contactTenantData.map((items, index) => (
                    <div key={index} className="singleRecord">
                      <div
                        className="leftSection"
                        style={{ height: "auto", margin: "10px 0" }}
                      >
                        <img
                          src={items.image}
                          alt={index}
                          style={{ height: "25px", width: "25px" }}
                        />
                        <span>{items.title}</span>
                      </div>
                      <p
                        style={{
                          textTransform:
                            items.title === "Email"
                              ? "lowercase"
                              : "capitalize",
                        }}
                      >
                        {items.data}
                      </p>
                    </div>
                  ))}
                  {/* <p style={{ fontSize: "22px", margin: "20px 0" }}>
                    Occupants
                  </p>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      gap: "20px",
                    }}
                  >
                    {[0, 1, 2, 3, 4].map((item) => (
                      <div
                        style={{
                          display: "flex",
                          height: "39%",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: "10px",
                            backgroundColor: "rgb(129,126,255, 0.1)",
                            borderRadius: "15px",
                            width: "max-content",
                            boxShadow: "3px 3px 20px 4px rgb(129,126,255, 0.1)",
                          }}
                        >
                          {contactTenantData.map((items, index) => (
                            <div key={index} className="singleRecord">
                              <div
                                className="leftSection"
                                style={{
                                  height: "auto",
                                  margin: "10px 10px 10px 0",
                                }}
                              >
                                <img
                                  src={items.image}
                                  alt={index}
                                  style={{ height: "20px", width: "20px" }}
                                />
                                <span style={{ marginRight: "10px" }}>
                                  {items.title}
                                  &nbsp;
                                </span>
                              </div>
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  width: "max-content",
                                }}
                              >
                                {items.data}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div> */}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {fetchLandlordDetails.data?.data && landLordPopup && (
        <LandLordPopup
          rowvalue={fetchLandlordDetails.data?.data}
          setpopup={setLandLordPopup}
          selectedData={"selectedData"}
          landlordPopup={landLordPopup}
          fromContact={true}
        />
      )}
    </div>
  );
};

export default PropertyPropertyContacts;
