import "../../../StyleSheet/clientmoney/accounts/depositaccount.scss";
import agentInsured_selected from "../../../../Assets/ClientMoney/agentInsured_selected.svg";
import agentInsured_unselected from "../../../../Assets/ClientMoney/agentInsured_unselected.svg";
import custodial_selected from "../../../../Assets/ClientMoney/custodial_selected.svg";
import custodial_unselected from "../../../../Assets/ClientMoney/custodial_unselected.svg";
import landlord_selected from "../../../../Assets/ClientMoney/landlord_selected.svg";
import landlord_unselected from "../../../../Assets/ClientMoney/landlord_unselected.svg";
import Search from "../../../../MainComponent/fields/search";
import DateRange from "../../../../MainComponent/fields/daterangepicker";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import {
  useDepositeAccountDetails,
  useFetchAccounts,
} from "../../../../Api/clientmonetQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatPounds } from "../../../../Utils/Utils";
import { Skeleton } from "antd";

export default function DepositAccount() {
  const [toggle, setToggle] = useState("Agent - Insured");
  const columnHelper = createColumnHelper();
  const [search, setsearch] = useState("");
  const [Generate, setGenerate] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [accountid, setAccountid] = useState("");

  const clientid = useSelector((state) => state.counter.clientid);
  const landlordId = useSelector((state) => state.counter.landlordId);
  const [count, setCount] = useState(0);

  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [table, setTable] = useState([]);

  console.log(accounts, "accounts");
  const getaccounts = useFetchAccounts({
    params: { client: clientid, landlord: landlordId || null, type: "client" },
  });
  const [range, setRange] = useState([]);

  const getdepositeaccountdata = useDepositeAccountDetails({
    params: {
      client: clientid,
      landlord: landlordId || null,
      account: accountid,
      page: pageindex,
      page_size: pagesize,
      search: search,
      is_show: true,
      from_date: range[0],
      to_date: range[1],
    },
  });

  const extractDepositAccountNumbers = (accounts) => {
    return accounts.filter((account) =>
      account.bank_details.includes("Deposit")
    );
  };

  useEffect(() => {
    if (getaccounts?.data?.data != undefined) {
      const idList = extractDepositAccountNumbers(
        getaccounts.data.data.results
      );
      setTimeout(() => {
        // setAccountid(idList?.[0]?.account_number);
        setAccounts(idList);
      }, 200);
    }
  }, [getaccounts.data]);
  useEffect(() => {
    if (accountid !== "" && accountid !== undefined) {
      setTimeout(() => {
        if (getdepositeaccountdata.data?.data != undefined) {
          setTable(getdepositeaccountdata.data?.data?.results);
          setCount(getdepositeaccountdata.data?.data?.count);
        }
      }, 200);
    }
  }, [getdepositeaccountdata.data, accountid, pageindex, pagesize]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "receipt_date",
        header: "Date",
        sortingFn: "basic",
        cell: (info) => (
          <span>
            {dayjs(info.row.original.recpit_date).format("DD/MM/YYYY")}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "payer_name",
        header: "Name",
        sortingFn: "basic",
        cell: (info) => <span>{info.row.original?.payer_name?.[0]}</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "recipt_type",
        header: "Property",
        sortingFn: "basic",
        cell: (info) => <span>{info.row.original?.property_name}</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "recipt_type",
        header: "Type",
        sortingFn: "basic",
        cell: (info) => (
          // <span>{info.row.original.debit != 0 ? "Charge" : "Receipt"}</span>
          <span>{info.row.original.type}</span>
          // <span>{"Hello"}</span>
        ),
      }),

      columnHelper.accessor((row) => row, {
        id: "debit",
        header: "In",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.credit)}`}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "credit",
        header: "Out",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.debit)}`}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "balance",
        header: "Balance",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(
            toggle === "Custodial" && landlordId?.length > 0
              ? info.row.original.l_balance
              : info.row.original.c_balance
          )}`}</span>
        ),
      }),
    ],
    []
  );
  const accountTypes = [
    {
      label: "Agent - Insured",
      selected: agentInsured_selected,
      unselected: agentInsured_unselected,
    },
    {
      label: "Landlord – Insured",
      selected: landlord_selected,
      unselected: landlord_unselected,
    },
    {
      label: "Custodial",
      selected: custodial_selected,
      unselected: custodial_unselected,
    },
  ];
  return (
    <div className="Deposit">
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>{toggle}</h1>
        <Search
          setsearch={setsearch}
          style={{ width: "353px" }}
          setPageIndex={setPageIndex}
        />
      </div> */}

      <div className="depoHead">
        <h1>{toggle}</h1>
        <div
        //  className="searchDeposit"
        >
          <Search
            setsearch={setsearch}
            // style={{ width: "100%" }}
            setPageIndex={setPageIndex}
            placeholder={"property"}
          />
        </div>
      </div>
      <div className="table_headers">
        <div className="card_parent">
          {accounts.map((account, index) => {
            const accountType = accountTypes[index];

            if (!accountType) {
              return null;
            }

            const { label, selected, unselected } = accountType;
            const isActive = toggle === label;

            return (
              <div
                key={index}
                className={`card ${isActive ? "active" : "inactive"}`}
                onClick={() => {
                  setToggle(label);
                  setAccountid(account.account_number);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img src={isActive ? selected : unselected} alt={label} />
                  <p>{label}</p>
                </div>
                <h1>{account.balance}</h1>
              </div>
            );
          })}
        </div>

        <div>
          <DateRange
            onChange={(e) => {
              setRange(e);
            }}
            minDate={new Date(2020, 0, 1)}
            maxDate={new Date()}
            style={{ width: "250px" }}
          />
        </div>
      </div>
      <div className="main_content">
        {getdepositeaccountdata.isFetching ? (
          <div className="skelitonDiv">
            <Skeleton active style={{ height: "98px" }} rows={1} />
          </div>
        ) : (
          <TableComponent
            columns={columns}
            data={table}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            // onclickrow={row => console.log(row)}
          />
        )}
      </div>
    </div>
  );
}
