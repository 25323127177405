import { useEffect, useMemo, useState } from "react";
import "./Styles/Contacts.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setContactDetailsFilter,
  setContactsFilter,
  setPropertyDetailsCrumbs,
  setPropertyDetailsFilter,
} from "../../../redux/reducer/counterslice";
import FilterPanel from "../../../MainComponent/FilterPanel";
import { useFetchTenantNoId } from "../../../Api/ContactsQuery";
import {
  addLeadingZero,
  formatAddress,
  formatName,
  formatUKTelephone,
} from "../../../Utils/Utils";
import {
  useFetchLandlordDetails,
  useFetchLandlordNoId,
  useFetchPropertiesCompliance,
} from "../../../Api/PropertiesQuery";
import Propertydetails from "../Property/popups/propertydetails";
import LandLordPopup from "./Popup/LandlordPopup";
import Search from "../../../MainComponent/fields/search";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import {
  useFetchLandlordContactsPagination,
  useFetchTenantsContacts,
} from "../../../Api/PaginationQuery";
import { useInView } from "react-intersection-observer";
import { Skeleton } from "antd";
import CircularIndeterminate from "../../../MainComponent/Loader";
import { useNavigate } from "react-router-dom";

const Contacts = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const loading = useSelector((state) => state.counter.loader);
  const toggle = useSelector((state) => state.counter.contactsFilter);
  const clientid = useSelector((state) => state.counter.clientid);
  const landlordId = useSelector((state) => state.counter.landlordId);
  const [popup, setpopup] = useState(false);
  const [landLordPopup, setLandLordPopup] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedLandLordId, setSelectedLandLordId] = useState("");
  const [switcher, setswitcher] = useState(false);
  const fetchLandlordDetails = useFetchLandlordDetails(selectedLandLordId);
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const [search, setsearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(null);
  const [pageindex, setPageIndex] = useState(1);
  const [rowValue, setRowValue] = useState([]);
  const [dataLoader, seDataLoader] = useState([]);
  const navigate = useNavigate();
  const [tableCount, setTableCount] = useState(20);
  const [tablePageIndex, setTablePageIndex] = useState(1);
  const [tablePageSize, setTablePageSize] = useState(20);
  const { ref, inView } = useInView();

  // First, initialize the pagination queries
  const {
    data: landlordData,
    fetchNextPage: landlordFetchNextPage,
    hasNextPage: landlordHasNextPage,
    isFetching: landlordIsFetching,
  } = useFetchLandlordContactsPagination({
    params: {
      client: clientid,
      landlord: landlordId?.length > 0 ? landlordId : null,
      page: pageindex,
      page_size: 15,
      status: "dummy",
      search: search,
    },
    enabled: !switcher && toggle === "Landlords",
  });

  const { data, fetchNextPage, hasNextPage, isFetching } =
    useFetchTenantsContacts({
      params: {
        client: clientid,
        landlord: landlordId?.length > 0 ? landlordId : null,
        page: pageindex,
        page_size: 15,
        status: "dummy",
        search: search,
        portal: "client",
      },
      enabled: !switcher && toggle === "Tenants",
    });

  // Then initialize the regular queries
  const fetchTenant = useFetchTenantNoId({
    params: {
      client: clientid,
      landlord: landlordId?.length > 0 ? landlordId : null,
      page: tablePageIndex,
      page_size: tablePageSize,
      status: "dummy",
      search: search,
    },
    enabled: switcher && toggle === "Tenants" && !isFetching,
  });

  const fetchLandlord = useFetchLandlordNoId({
    params: {
      client: clientid,
      landlord: landlordId?.length > 0 ? landlordId : null,
      page: tablePageIndex,
      page_size: tablePageSize,
      status: "dummy",
      search: search,
    },
    enabled: switcher && toggle === "Landlords" && !landlordIsFetching,
  });

  useEffect(() => {
    console.log(dataLoader, "dataLoader");
  }, [dataLoader]);

  useEffect(() => {
    if (data?.pages && toggle === "Tenants" && !isFetching) {
      const allResults = data.pages.flatMap((page) => page?.results || []);
      seDataLoader(allResults);
      setCount(data.pages[0]?.count || 0);
    }
  }, [data?.pages, toggle, isFetching]);

  useEffect(() => {
    if (landlordData?.pages && toggle === "Landlords" && !landlordIsFetching) {
      const allResults = landlordData.pages.flatMap(
        (page) => page?.results || []
      );
      seDataLoader(allResults);
      setCount(landlordData.pages[0]?.count || 0);
    }
  }, [landlordData?.pages, toggle, landlordIsFetching]);

  useEffect(() => {
    if (
      fetchTenant.data?.data?.results &&
      toggle === "Tenants" &&
      switcher &&
      !isFetching
    ) {
      setTableData(fetchTenant.data.data.results);
      setTableCount(fetchTenant.data.data.count);
    }
  }, [fetchTenant.data?.data, toggle, switcher, isFetching]);

  useEffect(() => {
    if (
      fetchLandlord.data?.data?.results &&
      toggle === "Landlords" &&
      switcher &&
      !landlordIsFetching
    ) {
      setTableData(fetchLandlord.data.data.results);
      setTableCount(fetchLandlord.data.data.count);
    }
  }, [fetchLandlord.data?.data, toggle, switcher, landlordIsFetching]);

  useEffect(() => {
    setTablePageIndex(1);
    setTablePageSize(20);
    setPageIndex(1);
  }, [toggle]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, isFetching]);

  useEffect(() => {
    if (inView && landlordHasNextPage && !landlordIsFetching) {
      landlordFetchNextPage();
    }
  }, [inView, landlordHasNextPage, landlordFetchNextPage, landlordIsFetching]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "contact_name",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.contact_detail?.[0];
          return (
            <p style={{ textTransform: "capitalize" }}>
              {info.row.original?.company
                ? info.row.original?.company
                : formatName(data.title, data.first_name, data.last_name)}
            </p>
          );
        },
        header: () => <span>Contact Name</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "email",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original.email;
          return <p style={{ textTransform: "capitalize" }}>{data}</p>;
        },
        header: () => <span>Email</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "rooms",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.phone;
          return (
            <p style={{ textTransform: "capitalize" }}>
              {data ? formatUKTelephone(data) : "--"}
            </p>
          );
        },
        header: () => <span>Telephone</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "rooms",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.property_count;
          return (
            <p style={{ textTransform: "capitalize" }}>
              {addLeadingZero(data)}
            </p>
          );
        },
        header: () => (
          <span style={{ textTransform: "none" }}>No of Properties</span>
        ),
      }),
    ],
    []
  );

  const updateFilter = (item) => {
    setSelectedPropertyId(undefined);
    setSelectedLandLordId(undefined);
    dispatch(setContactsFilter(item));
    setCardData([]);
  };
  const clickrow = (card) => {
    console.log(card.id, "laksjdfljaskdfjasjofj");
    if (toggle === "Landlords") {
      dispatch(
        setPropertyDetailsCrumbs({
          fromTitle: "Contacts",
          fromUrl: "/contacts",
        })
      );
      navigate(`/property/landlordDetails/${card?.id}`);
    } else {
      dispatch(
        setPropertyDetailsCrumbs({
          fromTitle: "Contacts",
          fromUrl: "/contacts",
        })
      );
      navigate(
        `/property/propertyDetails/${
          landlordId?.length > 0
            ? card?.tenancies?.[0]?.tenancies?.[0]?.property.id
            : card?.tenancies?.[0]?.tenancies?.property.id
        }`
      );
    }
  };

  useEffect(() => {
    console.log(data, "laskjdflkjajsdfojas");
  }, [data]);
  useEffect(() => {
    if (toggle === "Landlords") {
      if (fetchLandlordDetails.data?.data !== undefined) {
        setRowValue(fetchLandlordDetails.data?.data);
      }
    }
  }, [selectedLandLordId, fetchLandlordDetails.data]);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <>
      <div className="contactsWrapper">
        <div className="contacts_main">
          <div>
            <div className="toggle_header" style={{ height: "auto" }}>
              <div style={{ margin: "0 0 0 0 ", width: "100%" }}>
                <FilterPanel
                  tabs={["Tenants", "Landlords"]}
                  setActiveTab={updateFilter}
                  activeTab={toggle}
                />
              </div>
            </div>
            <div
              className="search_wrapper"
              style={{ justifyContent: "space-between" }}
            >
              <div className="totalArrears" style={{ marginBottom: "0" }}>
                <span style={{ fontWeight: "500" }}>Total {toggle}</span>
                <span>
                  {isFetching || addLeadingZero(count) === undefined ? (
                    <>
                      <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </>
                  ) : (
                    addLeadingZero(count || 0)
                  )}
                </span>
              </div>

              <div
                className="searchGridList"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div
                  className="searchBarContact"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Search
                    setsearch={setsearch}
                    setPageIndex={setPageIndex}
                    backgroundColor="#fff"
                    placeholder={toggle === "Landlords" ? "landlord" : "tenant"}
                  />
                </div>
                <div className="views_button_wrapper">
                  <button
                    className={!switcher ? "active" : ""}
                    onClick={(e) => {
                      setswitcher(false);
                    }}
                  >
                    <i className="bi bi-grid"></i>
                  </button>
                  <button
                    className={switcher ? "active" : ""}
                    onClick={(e) => {
                      setswitcher(true);
                    }}
                  >
                    <i className="bi bi-list"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="overview_main" style={{ minWidth: "100%" }}>
            <div
              style={{
                minWidth: "100%",
                overflow: "auto",
                height: "calc(100% - 0px)",
              }}
            >
              {!switcher ? (
                <>
                  <div className="cardcontain">
                    {(landlordIsFetching || isFetching) &&
                      dataLoader.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "15px",
                          }}
                        >
                          {Array.from({ length: 15 }).map((_, index) => (
                            <div
                              className="skelitonDiv"
                              style={{ width: "31.5%" }}
                            >
                              <Skeleton.Avatar
                                active={true}
                                size={"large"}
                                shape={"circle"}
                                style={{ height: "100px", width: "100px" }}
                              />

                              <Skeleton
                                active
                                style={{ height: "20px" }}
                                paragraph={{ rows: 1 }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    {dataLoader.map((card, index) => {
                      const contact = card?.contact_detail?.[0];
                      const address =
                        toggle === "Tenants"
                          ? landlordId?.length > 0
                            ? card?.tenancies?.[0]?.tenancies?.[0]?.property
                            : card?.tenancies?.[0]?.tenancies?.property
                          : card.property_landlord_address?.[0];
                      return (
                        <div
                          className="cardWrapper"
                          key={index}
                          onClick={() => {
                            console.log(card, "card");
                            if (toggle === "Landlords") {
                              dispatch(
                                setPropertyDetailsCrumbs({
                                  fromTitle: "Contacts",
                                  fromUrl: "/contacts",
                                })
                              );
                              navigate(`/property/landlordDetails/${card?.id}`);
                            } else {
                              dispatch(
                                setPropertyDetailsCrumbs({
                                  fromTitle: "Contacts",
                                  fromUrl: "/contacts",
                                })
                              );
                              // navigate(
                              //   `/property/propertyDetails/${card?.tenancies?.[0]?.tenancies?.[0]?.property?.id}`
                              // );
                              navigate(
                                `/property/propertyDetails/${
                                  landlordId?.length > 0
                                    ? card?.tenancies?.[0]?.tenancies?.[0]
                                        ?.property.id
                                    : card?.tenancies?.[0]?.tenancies?.property
                                        .id
                                }`
                              );
                            }
                          }}
                        >
                          <div className="cardHeader">
                            <div className="imgDiv">
                              {contact?.first_name.slice(0, 2)}
                            </div>
                          </div>
                          <div className="cardBody">
                            <div className="section">
                              <span>
                                {card?.company
                                  ? card?.company
                                  : formatName(
                                      contact?.title,
                                      contact?.first_name,
                                      contact?.last_name
                                    )}
                              </span>
                            </div>
                            <div className="section">
                              <span
                                style={{ fontWeight: "300" }}
                                className="add_contact_card"
                              >
                                {address?.name ||
                                address?.Name ||
                                address?.street ||
                                address?.city ||
                                address?.postcode
                                  ? formatAddress([
                                      address?.name ||
                                        address?.Name + " " + address?.street,
                                      address?.city || address?.town,
                                      address?.postcode,
                                    ])
                                  : "--"}
                                {}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div ref={ref} style={{ height: "1px" }} />
                  </div>
                  {(landlordIsFetching || isFetching) &&
                    dataLoader.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          fontSize: "22px",
                          fontWeight: "500",
                          color: "#817EFF",
                        }}
                      >
                        <p className="loading-text">Loading</p>
                      </div>
                    )}
                </>
              ) : (
                <div
                  style={{
                    padding: "10px",
                    background: "white",
                    width: "100%",
                    borderRadius: "13px",
                    height: "99%",
                  }}
                >
                  <TableComponent
                    data={tableData ? tableData : []}
                    columns={columns}
                    onclickrow={clickrow}
                    count={tableCount}
                    pageindex={tablePageIndex}
                    pagesize={tablePageSize}
                    setCount={setTableCount}
                    setPageIndex={setTablePageIndex}
                    setPageSize={setTablePageSize}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        {fetchProperties.data?.data ? (
          popup ? (
            <Propertydetails
              rowvalue={fetchProperties.data?.data}
              setpopup={setpopup}
              selectedData={"selectedData"}
              fromContact={true}
              setSelectedLandLordId={setSelectedLandLordId}
              propertyPopup={popup}
            />
          ) : (
            ""
          )
        ) : (
          <CircularIndeterminate />
        )}
        {fetchLandlordDetails.data?.data ? (
          landLordPopup ? (
            <LandLordPopup
              rowvalue={fetchLandlordDetails.data?.data}
              setpopup={setLandLordPopup}
              selectedData={"selectedData"}
              landlordPopup={landLordPopup}
              fromContact={true}
            />
          ) : (
            ""
          )
        ) : (
          <CircularIndeterminate />
        )}
      </>
    </>
  );
};

export default Contacts;
