import React, { useEffect } from "react";
import "../../StyleSheet/Property/allproperties.scss";
import "./PropertyDetails-panel.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import address from "../../../Assets/Common/address.svg";
import tenantIcon from "../../../Assets/Common/tenantIcon.svg";
import { formatAddress, formatName, formatPounds } from "../../../Utils/Utils";
import propertyDefault from "../../../Assets/Common/defaultPropertyalt.svg";
import rent_Amount from "../../../Assets/Common/rent_Amount.svg";
import { Skeleton } from "antd";

function PropertyDetailsPanel({ dataFromParent, dataIsLoading }) {
  const navigate = useNavigate();
  const breadCrumbDetails = useSelector(
    (state) => state.counter.propertyDetailsCrumbs
  );
  const tenantDetails =
    dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant?.contact_detail?.[0];

  return (
    <div>
      <div className="header">
        <div>
          <span
            className="crumbSubSection"
            onClick={() => navigate(breadCrumbDetails?.fromUrl)}
          >
            {breadCrumbDetails?.fromTitle}
          </span>
          <span style={{ fontWeight: "500" }}>/Property Details</span>
        </div>
      </div>
      <div className="details-card">
        {dataIsLoading ? (
          <>
            <Skeleton active />
          </>
        ) : (
          <>
            <img
              src={dataFromParent?.media?.[0]?.url || propertyDefault}
              alt="Property"
              className="property-image"
              width={160}
              height={130}
            />
            <div className="info">
              <h2 className="infoMainHeading">{dataFromParent?.name}</h2>

              <div className="panelContentSection">
                <div className="address-wrapper">
                  <p className="address">
                    <img src={address} alt="address" width={20} height={20} />
                    <strong>Address</strong>

                    {formatAddress([
                      dataFromParent?.name + " " + dataFromParent?.street,
                      dataFromParent?.city,
                      dataFromParent?.postcode,
                    ])}
                    <button className="copy-btn">Copy</button>
                  </p>
                  {/* <p className="landlord">
                <img src={landlordIcon} alt="address" width={20} height={20} />
                {"  "}
                <strong>Landlord</strong>
                {formatName(
                  landlordDetails?.title,
                  landlordDetails?.first_name,
                  landlordDetails?.last_name
                )}
              </p> */}
                  <p className="tenant">
                    <img
                      src={tenantIcon}
                      alt="address"
                      width={20}
                      height={20}
                    />
                    <strong>Tenant</strong>
                    {dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant
                      ?.contact_detail?.[0] ? (
                      formatName(
                        tenantDetails?.title,
                        tenantDetails?.first_name,
                        tenantDetails?.last_name
                      )
                    ) : (
                      <span className="no-tenant">Not Assigned</span>
                    )}
                  </p>
                </div>
                <div className="address-wrapper">
                  <p className="occupancy">
                    <div className="occupancy-wrapper">
                      <img
                        src={rent_Amount}
                        alt="rent"
                        width={20}
                        height={20}
                      />
                      <strong>Property Status</strong>
                    </div>
                    <span
                      style={{
                        background: (() => {
                          const status = dataFromParent?.status;
                          if (
                            status === "Let in Progress" ||
                            status === "Occupied" ||
                            status === "Periodic"
                          ) {
                            return "#CEFFE1";
                          }
                          if (status === "Vacate in Progress") {
                            return "#FFF0CC";
                          }
                          if (status === "Available") {
                            return "rgb(255,216,212)";
                          }
                          return "";
                        })(),
                      }}
                    >
                      {["Let in Progress", "Occupied", "Periodic"].includes(
                        dataFromParent?.status
                      )
                        ? "Occupied"
                        : dataFromParent?.status === "Vacate in Progress"
                        ? "Upcoming Vacancy"
                        : dataFromParent?.status === "Available"
                        ? "Vacant"
                        : ""}
                    </span>
                  </p>
                  <p className="rent">
                    <div className="rent-amount-wrapper">
                      <img
                        src={rent_Amount}
                        alt="rent"
                        width={20}
                        height={20}
                      />
                      <strong>Property Rent</strong>
                    </div>
                    {dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.rent ? (
                      <span className="no-rent">
                        {`£${formatPounds(
                          dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.rent
                        )}`}
                      </span>
                    ) : (
                      <span className="no-rent">N/A</span>
                    )}
                  </p>
                </div>
              </div>

              {/* <p className="bedrooms">
            <span className="icon bed"></span> 2
          </p> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PropertyDetailsPanel;
