import React, { useEffect } from "react";
import dayjs from "dayjs";
import archive from "../../../../Assets/Common/archive.svg";
import download from "../../../../Assets/Common/download.svg";

const Documents = ({
  title,
  documents,
  setSelectedArchivedData,
  setArchivePopup,
  setPayloadForArchiveApi,
  type,
  rowvalue,
}) => {
  const openFile = (file) => {
    if (file?.length > 0) {
      window.open(file[0], "_blank");
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "not_due":
        return "#4DDE87";
      case "due":
        return "#FFB200";
      case "overdue":
        return "#FF3A29";
      default:
        return "#000";
    }
  };

  return (
    <>
      <div className="body_content_wrapper">
        <div className="con_doc_left">
          <h2>{title}</h2>
        </div>
        <div className="con_doc_right">
          <div className="doc_card_cover_main">
            {documents?.map((ele, index) => (
              <div className="doc_card_cover" key={index}>
                <div className="con_download_card">
                  <div className="con_text" onClick={() => openFile(ele.file)}>
                    <div className="con_icon_cover">
                      <img
                        src={ele.document_type__icon}
                        // style={{ width: "26px", height: "26px" }}
                        alt="icon"
                      />
                    </div>
                    <p title={ele.document_type__name}>
                      {ele.document_type__name}
                    </p>
                  </div>
                  <div
                    className="dividerLine"
                    style={{ background: getStatusColor(ele?.status) }}
                  ></div>
                  {ele?.file?.length > 0 ? (
                    <div
                      className="bottomSection"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="con_bottom_date_exp"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span style={{ cursor: "pointer" }}>Expiry</span>
                        <span>
                          {dayjs(ele.new_expiry_date).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <DocumentAction
                          icon={archive}
                          label="Archived"
                          data={ele}
                          setSelectedArchivedData={setSelectedArchivedData}
                          setArchivePopup={setArchivePopup}
                          ele={ele}
                          setPayloadForArchiveApi={setPayloadForArchiveApi}
                          type={type}
                          rowvalue={rowvalue}
                          title={title}
                        />
                        <DocumentAction
                          icon={download}
                          label="Download"
                          setArchivePopup={setArchivePopup}
                          ele={ele}
                        />
                      </div>
                    </div>
                  ) : (
                    "No document uploaded."
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </>
  );
};

const DocumentAction = ({
  icon,
  label,
  setSelectedArchivedData,
  setArchivePopup,
  ele,
  setPayloadForArchiveApi,
  type,
  rowvalue,
  title,
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
    }}
    onClick={() => {
      if (label === "Archived") {
        setArchivePopup(true);
        setSelectedArchivedData(ele);
        setPayloadForArchiveApi({
          d_id: ele.document_type,
          [type]:
            title === "Property Documents"
              ? rowvalue.id
              : title === "Tenancy Documents"
              ? rowvalue?.tenancies?.[0]?.tenancies?.[0]?.tenant?.id
              : "",
        });
      } else {
        window.open(ele?.file?.[0], "_black");
      }
    }}
  >
    <span style={{ cursor: "pointer" }}>{label}</span>
    <img
      src={icon}
      width="max(8px, 0.7vw)"
      height="max(8px, 0.7vw)"
      alt={label}
      style={{ cursor: "pointer" }}
    />
  </div>
);

export default Documents;
