import React, { useEffect, useState } from "react";
import "../popups/PropertyCreate.scss";
// import { Button } from "../Button";
// import {
//   useAdminPost,
//   useAdminResendInvite,
//   useAdminUpdateAdmin,
//   useFetchClientsNoId,
// } from "../../Context/queries";
import { useDropzone } from "react-dropzone";
// import { CustomSelect } from "../CustomSelect";
// import { useFetchLandlordNoId } from "../../Api/ContactsQuery";
import loading from "../../../../Assets/Common/output-onlinegiftools (4).gif";
import closeIcon from "../../../../Assets/Common/closeIcon.svg";
import { toast } from "sonner";
import { googleFinderKey } from "../../../../Api/useResponseHandler";
import {
  useFetchLandlordNoId,
  usePresignedUrlPost,
  usePresignedUrlPut,
  usePresignedUrlPutType,
  usePropertyCreatePost,
} from "../../../../Api/PropertiesQuery";
import DefaultLoader from "../../../GlobalComponents/DefaultLoader";
import { CustomButton } from "../../../GlobalComponents/CustomButton/CustomButton";
import { Padding } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CustomSelect } from "../../../GlobalComponents/CustomSelect";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";

const PropertyCreate = (props) => {
  const { isOpen, setIsOpen } = props;
  const [formDirty, setFormDirty] = useState(true);
  const [errors, setErrors] = useState({});
  //   const clientFetch = useFetchClientsNoId();
  const clientId = useSelector((state) => state.counter.loginuserdata.id);
  const fetchLandlord = useFetchLandlordNoId({
    params: {
      client: clientId,
      page: 1,
      page_size: 10000,
      status: "dummy",
      search: "",
    },
  });
  const [uploadStatus, setUploadStatus] = useState("idel");
  const [data, setData] = useState({
    name: "",
    street: "",
    city: "",
    postcode: "",
    // image: [],
    media: [],
    client: "",
    landlord: "",
    is_active: false,
    rooms: [{ bed: "", bath: "", living: "", garage: "" }],
  });
  const [PresignedUrlPayload, setPresignedUrlPayload] = useState({
    multiple_files: [],
  });
  const createPresignedURL = usePresignedUrlPost(PresignedUrlPayload);
  const [files, setFiles] = useState([]);
  const updatePresignedUrlPUT = usePresignedUrlPutType();

  useEffect(() => {
    setData((prev) => ({ ...prev, client: clientId }));
  }, [clientId]);

  const handleChange = (e) => {
    setFormDirty(false);
    const { name, value } = e.target;
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!data.name.trim()) {
      newErrors.name = "Property name is required";
      valid = false;
    }
    if (!data.street.trim()) {
      newErrors.street = "Street is required";
      valid = false;
    }
    if (!data.city.trim()) {
      newErrors.city = "City is required";
      valid = false;
    }
    if (!data.postcode.trim()) {
      newErrors.postcode = "Postcode is required";
      valid = false;
    }
    if (!selectedLandlordOption) {
      newErrors.landlord = "Landlord is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleClose = () => {
    setData({
      name: "",
      street: "",
      city: "",
      postcode: "",
      client: "",
      landlord: "",
      // image: [],
      media: [],
      is_active: false,
    });
    setSelectedLandlordOption(null);
    setErrors({});
    setCoImage([]);
    setIsOpen(false);
    setFiles([]);
  };

  const formFields = [
    { name: "name", label: "Name / No", type: "text" },
    { name: "street", label: "Street", type: "text" },
    { name: "city", label: "Town / City", type: "text" },
    { name: "postcode", label: "Postcode", type: "text" },
  ];
  const roomFormFields = [
    { name: "bed", label: "Bedroom", type: "number" },
    { name: "bath", label: "Bathroom", type: "number" },
    { name: "living", label: "Living Room", type: "number" },
    { name: "garage", label: "Garage", type: "number" },
  ];

  const [presignedURL, setPresignedURL] = useState("");
  const [binaryImageData, setBinaryImageData] = useState("");
  const [coImage, setCoImage] = useState([]);
  const [enrollPresignedImage, setEnrollPresignedImage] = useState([]);
  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     if (acceptedFiles.length + files.length <= 9) {
  //       const newFiles = [...files, ...acceptedFiles];
  //       setFiles(newFiles);
  //       setEnrollPresignedImage([...enrollPresignedImage, ...acceptedFiles]);

  //       if (newFiles.length > 0) {
  //         const payload = newFiles.map((ele) => ({
  //           filename: `${data.type === "Property" ? "property" : "contact"}${
  //             ele.path
  //           }`,
  //           file_type: ele.type,
  //         }));

  //         setPresignedUrlPayload({
  //           multiple_files: payload,
  //         });

  //         setTimeout(() => {
  //           createPresignedURL.mutate();
  //         }, 100);
  //       }
  //     } else {
  //     }
  //   },
  //   maxFiles: 1,
  //   accept: {
  //     "image/jpeg": [],
  //     "image/png": [],
  //   },
  // });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      // Check if user tries to add multiple files at once
      if (acceptedFiles.length > 1 || rejectedFiles.length > 1) {
        toast.error("You can only upload one file at a time.");
        return;
      }

      // Check if there are any rejected files
      if (rejectedFiles.length > 0) {
        toast.error("Only JPEG and PNG images are supported.");
        return;
      }

      // Check if a file already exists
      if (files.length > 0) {
        toast.error("You can only upload one file.");
        return;
      }

      // Process the single valid file
      const newFile = acceptedFiles[0];
      if (newFile) {
        const newFiles = [newFile];
        setFiles(newFiles);
        setEnrollPresignedImage([newFile]);

        const payload = [
          {
            filename: `${data.type === "Property" ? "property" : "contact"}${
              newFile.path
            }`,
            file_type: newFile.type,
          },
        ];

        setPresignedUrlPayload({
          multiple_files: payload,
        });

        // Trigger the presigned URL API call
        setTimeout(() => {
          createPresignedURL.mutate();
        }, 100);
      }
    },
    multiple: false, // Restrict selection or drag-and-drop to one file at a time
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const removeFunction = (index) => {
    const newFiles = files?.filter((_, i) => i !== index);
    const newEnrollPresignedImage = enrollPresignedImage?.filter(
      (_, i) => i !== index
    );
    const urls = presignedURL?.filter((_, i) => i !== index);
    setFiles(newFiles);
    setEnrollPresignedImage(newEnrollPresignedImage);
    setPresignedURL(urls);

    setData((prevValue) => ({
      ...prevValue,
      image: prevValue.media?.filter((_, i) => i !== index),
    }));
  };
  const [selectedLandlordOption, setSelectedLandlordOption] = useState(null);
  const [landlordOptions, setlandlordOptions] = useState([]);

  const handleLandlordDropChange = (e) => {
    setSelectedLandlordOption(e);
    setData({
      ...data,
      landlord: e.value,
    });
  };

  // useEffect(() => {
  //   console.log(clientFetch.data.data.results, 'kansfjisajdfasd');
  // }, [clientFetch.data]);
  //   useEffect(() => {
  //     // console.log(clientResponse?.data?.data?.results, 'lajsldjfiaojisojdf');
  //     const clientDrop = clientFetch?.data?.data?.results?.map((item) => ({
  //       value: item.id,
  //       label:
  //         item.contact_detail?.[0]?.first_name +
  //         " " +
  //         item.contact_detail?.[0]?.last_name,
  //     }));
  //     setOptions(clientDrop);
  //   }, [clientFetch.data]);

  // useEffect(() => {
  //   if (enrollPresignedImage.length > 0) {
  //     setPresignedUrlPayload({
  //       multiple_files: [
  //         {
  //           filename: `property/${enrollPresignedImage[0].path}`,
  //           file_type: enrollPresignedImage[0].type,
  //         },
  //       ],
  //     });
  //     setTimeout(() => {
  //       createPresignedURL.mutate();
  //     }, 100);
  //   }
  // }, [enrollPresignedImage]);
  useEffect(() => {
    if (fetchLandlord?.data?.data) {
      const clientDrop = fetchLandlord?.data?.data?.results?.map((item) => ({
        value: item.id,
        label:
          item.contact_detail?.[0]?.first_name +
          " " +
          item.contact_detail?.[0]?.last_name,
      }));
      setlandlordOptions(clientDrop);
    }
  }, [fetchLandlord.data]);

  // // const createPresignedURL = usePresignedUrlPost();
  // const [binaryImageData, setBinaryImageData] = useState("");
  // const [imageType, setImageType] = useState("");

  useEffect(() => {
    if (createPresignedURL.data?.data?.length) {
      const output = createPresignedURL.data.data.map(
        (url) => url.split("?")[0]
      );
      setPresignedURL(output);
      setPresignedUrlPayload({ multiple_files: [] });

      const binaryData = enrollPresignedImage.map((file, index) => ({
        filename: file.name,
        type: file.type,
        url: output[index],
        data: file,
      }));

      setBinaryImageData(binaryData);

      if (binaryData.length > 0) {
        binaryData.forEach((binary, index) => {
          updatePresignedUrlPUT.mutate({
            url: createPresignedURL.data.data[index],
            imagePayload: binary.data,
            type: binary.type,
          });
        });
      }
      console.log(data, "dataLoggs");

      setData((prevValue) => {
        const existingUrls = new Set(prevValue.media?.map((file) => file.url));
        const newFiles = binaryData.filter(({ url }) => !existingUrls.has(url));
        // const existingUrls = new Set(prevValue.image || []);

        // const newUrls = binaryData
        //   .map(({ url }) => url)
        //   .filter((url) => !existingUrls.has(url));
        return {
          ...prevValue,
          // image: [...existingUrls, ...newUrls],
          media: [
            ...prevValue.media,
            ...newFiles.map(({ filename, type, url }) => ({
              filename,
              type,
              url,
            })),
          ],
        };
      });
    }
  }, [createPresignedURL.data]);
  const createProperty = usePropertyCreatePost(data);

  const handleSubmit = (e) => {
    e.preventDefault();
    // handleClose();
    const isValid = validateForm();
    // const isValid = validateForm(data, setErrors);
    if (!isValid) {
      toast.error("Please fill all required fields correctly");
      return; // Prevent form submission if invalid
    }

    setTimeout(() => {
      createProperty.mutate();
      handleClose();
    }, 300);

    console.log(data, "gvhbnjkmjnhbvgbhnj");
  };

  // const updatePresignedUrlPUT = usePresignedUrlPutType({
  //   url: presignedURL,
  //   imagePayload: binaryImageData,
  //   type: imageType,
  // });

  // useEffect(() => {
  //   if (createPresignedURL.data?.data?.length) {
  //     const output = createPresignedURL.data.data.map(
  //       (url) => url.split("?")[0]
  //     );
  //     setPresignedURL(output);
  //     setPresignedUrlPayload({ multiple_files: [] });

  //     const binaryData = enrollPresignedImage.map((file, index) => ({
  //       filename: file.name,
  //       type: file.type,
  //       url: output[index],
  //       data: file,
  //     }));

  //     setBinaryImageData(binaryData);

  //     if (binaryData.length > 0) {
  //       binaryData.forEach((binary, index) => {
  //         updatePresignedUrlPUT.mutate({
  //           url: createPresignedURL.data.data[index],
  //           imagePayload: binary.data,
  //           type: binary.type,
  //         });
  //       });
  //     }

  //     setData((prevValue) => {
  //       const existingUrls = new Set(prevValue.image.map((file) => file.url));
  //       const newFiles = binaryData.filter(({ url }) => !existingUrls.has(url));
  //       return {
  //         ...prevValue,
  //         image: [
  //           ...prevValue.image,
  //           ...newFiles.map(({ filename, type, url }) => ({
  //             filename,
  //             type,
  //             url,
  //           })),
  //         ],
  //       };
  //     });
  //   }
  // }, [createPresignedURL.data]);

  // useEffect(() => {
  //   var count = 0;
  //   if (updatePresignedUrlPUT.data != undefined && count == 0) {
  //     count = 1;
  //     setUploadStatus(
  //       `success${updatePresignedUrlPUT.data?.config?.data?.name}`
  //     );
  //     setTimeout(() => {
  //       setUploadStatus("idel");
  //     }, 1000);
  //   }
  // }, [updatePresignedUrlPUT.data]);

  const getAddress = async (e) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${e.target.value}&components=country:GB&key=${googleFinderKey}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data1 = await response.json();

      if (data1?.status === "OK" && data1?.results?.length > 0) {
        const addressComponents = data1.results[0].address_components;

        // Initialize address data object
        const addressData = {
          street: "",
          city: "",
          postcode: "",
          country: "",
        };

        // Map components to address fields based on their types
        addressComponents.forEach((component) => {
          const types = component.types;

          // Get street name (route or street_number + route)
          if (types.includes("street_number")) {
            addressData.street = component.long_name;
          } else if (types.includes("route")) {
            addressData.street = addressData.street
              ? `${addressData.street} ${component.long_name}`
              : component.long_name;
          }

          // Get town (prefer postal_town, fallback to locality)
          if (types.includes("postal_town")) {
            addressData.city = component.long_name;
          } else if (!addressData.town && types.includes("locality")) {
            addressData.city = component.long_name;
          }

          // Get postcode
          if (types.includes("postal_code")) {
            addressData.postcode = component.long_name;
          }

          // Get country
          if (types.includes("country")) {
            addressData.country = component.long_name;
          }
        });

        // Verify we got the essential address components
        if (!addressData.postcode || !addressData.city) {
          toast.error("Invalid postcode format");
          return;
        }

        setData((prev) => ({
          ...prev,
          ...addressData,
        }));

        if (data1?.status === "ZERO_RESULTS") {
          toast.error("Postcode not found");
        } else {
          // toast.error('Invalid postcode format');
        }
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      toast.error("An error occurred while fetching address details.");
    }
  };

  const isLoading =
    createPresignedURL.isPending ||
    createProperty.isPending ||
    updatePresignedUrlPUT.isPending;

  return (
    <div>
      {isLoading && <DefaultLoader />}
      {isOpen && (
        <>
          <div className="overlay" />
          <div className="popup" style={{ height: "80%", maxHeight: "1250px" }}>
            <div className="popup-content" style={{ height: "94%" }}>
              <div className="popup_top">
                <div />
                <h2 style={{ fontSize: "clamp(18px, 1.6vw, 45px)" }}>
                  Add Property
                </h2>
                {/* <button
                  type="button"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <img src={closeIcon} alt="closeIcon" />
                </button> */}
                <div className="close_button_wrapper">
                  <img
                    className="close"
                    style={{ borderRadius: "0px" }}
                    onClick={(e) => {
                      handleClose();
                      // props.setSelectedLandLordId("");
                    }}
                    src={closeSolidIcon}
                  ></img>
                </div>
              </div>
              <div
                className="popup_center"
                style={{ height: "95%", overflow: "auto" }}
              >
                <form className="popup_form">
                  <div className="formContainer">
                    <p
                      style={{
                        width: "100%",
                        fontSize: "17px",
                        fontWeight: "500",
                        textAlign: "left",
                        // marginTop: "210px",
                      }}
                    >
                      Details
                    </p>
                    <div className="innerWrapper">
                      {formFields.map((field, index) => (
                        <>
                          <div className="innerFormContainer" key={index}>
                            <label
                              htmlFor={field.name}
                              style={{ fontWeight: "500" }}
                            >
                              {field.label}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="customInput"
                              type={field.type}
                              id={field.name}
                              name={field.name}
                              value={data[field.name]}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              autoComplete="off"
                              style={{ height: "35px", width: "90%" }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  field.name === "postcode"
                                ) {
                                  getAddress(e);
                                }
                              }}
                              onBlur={(e) => {
                                if (
                                  e.target.value.length > 0 &&
                                  field.name === "postcode"
                                ) {
                                  getAddress(e);
                                }
                              }}
                            />
                            {errors[field.name] && (
                              <div className="error">
                                <p style={{ textWrap: "nowrap" }}>
                                  {errors[field.name]}
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                      <div
                        style={{
                          borderBottom: "1px solid black",
                          width: "100%",
                          height: "2px",
                        }}
                      ></div>
                      <p
                        style={{
                          width: "100%",
                          fontSize: "17px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Rooms
                      </p>
                      {roomFormFields.map((field, index) => (
                        <>
                          <div className="innerFormContainer" key={index}>
                            <label
                              htmlFor={field.name}
                              style={{ fontWeight: "500" }}
                            >
                              {field.label}
                            </label>
                            <input
                              className="customInput"
                              type={field.type}
                              id={field.name}
                              name={field.name}
                              value={data.rooms?.[0]?.[field.name]}
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              onChange={(e) => {
                                if (e.target.value.length < 2) {
                                  setData((prev) => ({
                                    ...prev,
                                    rooms: [
                                      {
                                        ...prev?.rooms?.[0],
                                        [field.name]: e.target.value,
                                      },
                                    ],
                                  }));
                                }
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "-" ||
                                  e.key === "e" ||
                                  e.key === "E"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              autoComplete="off"
                              style={{ height: "35px", width: "90%" }}
                              placeholder="0"
                            />
                          </div>
                          {errors[field.name] && (
                            <div className="error">
                              <p>{errors[field.name]}</p>
                            </div>
                          )}
                        </>
                      ))}
                      <div
                        style={{
                          borderBottom: "1px solid black",
                          width: "100%",
                          height: "2px",
                        }}
                      ></div>
                      <div
                        className="innerWrapper"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            gap: "10px",
                            width: "42%",
                            marginTop: "13px",
                          }}
                        >
                          <label htmlFor="client" style={{ fontWeight: "500" }}>
                            Landlord
                          </label>
                          <div style={{ width: "100%" }}>
                            <CustomSelect
                              name={"typeTemplate"}
                              selectedOption={selectedLandlordOption}
                              handleSelectChange={handleLandlordDropChange}
                              options={landlordOptions}
                            />
                          </div>
                          {errors.landlord && (
                            <div className="error">
                              <p style={{ textWrap: "nowrap" }}>
                                {errors.landlord}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <label htmlFor="client">Client</label>
                      <div style={{ width: '50%' }}>
                        <CustomSelect
                          name={'typeTemplate'}
                          selectedOption={selectedOption}
                          handleSelectChange={handleClientDropChange}
                          options={options}
                        />
                      </div>
                      <label htmlFor="client">Client</label>
                      <div style={{ width: '50%' }}>
                        <CustomSelect
                          name={'typeTemplate'}
                          selectedOption={selectedOption}
                          handleSelectChange={handleClientDropChange}
                          options={options}
                        />
                      </div> */}

                      <div className="Upload_pic">
                        <div
                          className={`imagedropper ${
                            coImage.length > 0 && "actives"
                          }`}
                        >
                          <div
                            {...getRootProps()}
                            className={`Imagedropper_inner`}
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              className="customInput"
                              {...getInputProps()}
                            />
                            {
                              <>
                                {coImage.length < 8 ? (
                                  <>
                                    <p>Drag photos here to upload</p>
                                  </>
                                ) : (
                                  <span> Max upload reached</span>
                                )}
                              </>
                            }
                          </div>
                          <div className="Image_Viewer">
                            {files?.length >= 0 &&
                              files?.map((upFile, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      // src={upFile[0].preview}
                                      src={URL.createObjectURL(upFile)}
                                      className="ImageView"
                                      alt={"preview"}
                                    />
                                    <span
                                      className="cancle_img"
                                      onClick={(e) => removeFunction(index)}
                                    >
                                      x
                                    </span>
                                    <div className="status">
                                      {uploadStatus ===
                                        `pending${upFile.name}` && (
                                        <img src={loading}></img>
                                      )}
                                      {/* {uploadStatus ===
                                      `success${upFile.name}` && (
                                      <img src={success}></img>
                                    )} */}
                                      {uploadStatus === "error" && (
                                        <p>Upload failed. Please try again.</p>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="popup_bottom">
                    <CustomButton
                      title={"Create"}
                      active
                      type="submit"
                      handleClick={handleSubmit}
                      styles={{
                        padding: "13px 60px",
                        fontSize: "18px",
                        borderRadius: "13px",
                      }}
                      //   disabled={clientFetch.isPending || formDirty}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyCreate;
