import "../../StyleSheet/Maintenance/maintenanceoverview.scss";
import logo from "../../../Assets/Client/maintenence/maintanence_t_ogo.svg";
import ReactEcharts from "echarts-for-react";
import one from "../../../Assets/Client/maintenence/maintenance.svg";
import two from "../../../Assets/Client/maintenence/amount.svg";
import three from "../../../Assets/Client/maintenence/funds.svg";
import { useEffect, useMemo, useState, useRef } from "react";
import info from "../../../Assets/Common/info.svg";
import MaintenanceOverviewPopup from "./popups/maintenanceoverviewpopup";
import { createColumnHelper } from "@tanstack/react-table";
import DateRange from "../../../MainComponent/fields/daterangepicker";
import {
  useFetchMaintenanceFilterStatus,
  useFetchMaintenanceSpendAmount,
  useFetchUpcomingMaintenance,
} from "../../../Api/MaintenanceQuery";
import { useDispatch, useSelector } from "react-redux";
import { formatAddress, formatPounds } from "../../../Utils/Utils";
import { setMaintenanceFilter } from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";

export default function MaintenanceOverview() {
  const columnHelper = createColumnHelper();
  const [Range, setRange] = useState([]);

  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const landlordId = useSelector((state) => state.counter.landlordId);
  const [selectedFilter, setSelectedFilter] = useState("0-7days");
  const MaintenanceSpendAmount = useFetchMaintenanceSpendAmount({
    id: loginuserdata.id,
    date_filter: selectedFilter,
    type: false,
    date_filter: selectedFilter,
    from_date: Range?.[0] || "",
    to_date: Range?.[1] || "",
    landlord: landlordId?.length > 0 ? landlordId : null,
  });

  const [popup, setPopup] = useState(false);
  const [keydata, setkeydata] = useState();
  const navigate = useNavigate();
  const [Data, setData] = useState([
    { name: "New Requests", count: "12" },
    { name: "Quote Requested", count: "4" },
    { name: "Awaiting Approval", count: "5" },
    { name: "Approved", count: "8" },
    { name: "Booked", count: "2" },
    { name: "Awaiting Payment", count: "2" },
  ]);
  const FilterOptions = [
    { label: "7 Days", value: "0-7days" },
    { label: "Month", value: "month" },
    { label: "Year", value: "year" },
  ];
  const fetchStatus = useFetchMaintenanceFilterStatus({
    id: loginuserdata.id,
    landlord: landlordId?.length > 0 ? landlordId : null,
    type: false,
  });

  const containerRef = useRef(null);
  const belowRef = useRef(null);

  const [isColumn, setIsColumn] = useState(false);
  const [isWrapped, setIsWrapped] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 1100 && (!isColumn || !isWrapped)) {
      toggleLayout();
      toggleWrap();
    } else if (window.innerWidth > 1100 && (isColumn || isWrapped)) {
      toggleLayout();
      toggleWrap();
    }
  };

  useEffect(() => {
    handleResize();
    toggleWrap();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isColumn]);

  const toggleLayout = () => {
    if (!isColumn) {
      const children = Array.from(containerRef.current.children);

      gsap.to(children, {
        duration: 1,
        marginTop: (i) => `${i * 15}px`,
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(containerRef.current, {
        duration: 1,
        flexDirection: "column",
      });

      setIsColumn(true);
    } else {
      const children = Array.from(containerRef.current.children);

      gsap.to(children, {
        duration: 1,
        marginTop: "0px",
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(containerRef.current, {
        duration: 1,
        flexDirection: "row",
      });

      setIsColumn(false);
    }
  };

  const toggleWrap = () => {
    const children = Array.from(belowRef.current.children);

    if (!isWrapped) {
      gsap.to(children, {
        duration: 1,
        marginTop: "0px",
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(belowRef.current, {
        duration: 1,
        flexWrap: "wrap",
      });

      setIsWrapped(true);
    } else {
      gsap.to(children, {
        duration: 1,
        marginTop: "15px",
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(belowRef.current, {
        duration: 1,
        flexWrap: "wrap",
      });

      setIsWrapped(false);
    }
  };
  const option = {
    // legend: {
    //     top: '0%',
    //     left: 'center'
    // },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["48%", "90%"],
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 5,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 10,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value:
              fetchStatus?.data?.data?.[0]?.count +
              fetchStatus?.data?.data?.[1]?.count,
            // name: "New Requests",
            itemStyle: { color: "#75E291" },
          },
          {
            value: fetchStatus?.data?.data?.[2]?.count,
            // name: "Quote Requested",
            itemStyle: { color: "#FFC650" },
          },
          {
            value: fetchStatus?.data?.data?.[3]?.count,
            // name: "Awaiting Approval",
            itemStyle: { color: "#FF5B5C" },
          },
          {
            value: fetchStatus?.data?.data?.[4]?.count,
            // name: "Approved",
            itemStyle: { color: "#52C2EF" },
          },
          {
            value: fetchStatus?.data?.data?.[5]?.count,
            // name: "Booked",
            itemStyle: { color: "#6062F0" },
          },
          {
            value: fetchStatus?.data?.data?.[6]?.count,
            // name: "Awaiting Payment",
            itemStyle: { color: "#FF9E6F" },
          },
        ],
      },
    ],
  };

  const carddata = [
    {
      img: one,
      amount: MaintenanceSpendAmount.data?.data?.total_property_charges,
      text: "Total maintenance spent",
    },
    {
      img: two,
      amount: MaintenanceSpendAmount.data?.data?.total_reserve_debits,
      text: "Total amount paid by Solace",
    },
    {
      img: three,
      amount: MaintenanceSpendAmount.data?.data?.latest_balance,
      text: "Reserve Funds",
    },
  ];

  const upcomingMaintenance = useFetchUpcomingMaintenance();
  const [upcomingMaintenanceData, setUpcomingMaintenanceData] = useState([]);
  useEffect(() => {
    if (upcomingMaintenance.data?.data) {
      console.log(upcomingMaintenance.data.data, "lkasjdlfjlasjdljafl");
      setUpcomingMaintenanceData(upcomingMaintenance.data.data);
    }
  }, [upcomingMaintenance.data]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (info) => {
          console.log(info.getValue());

          return (
            <p
              className={
                info.getValue() == "Approved" ? "Approved" : "NotApproved"
              }
            >
              {info.getValue()}
            </p>
          );
        },
        header: () => <span>Status</span>,
      }),

      columnHelper.accessor("summary", {
        header: "Summary",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("description", {
        header: "Decription",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("quote", {
        header: "Quote",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>£ {info.getValue()}</span>
        ),
      }),
    ],
    []
  );
  useEffect(() => {
    setData([
      { name: "New Requests", count: fetchStatus?.data?.data?.[0]?.count },
      { name: "Quote Requested", count: fetchStatus?.data?.data?.[2].count },
      {
        name: "Awaiting Approval",
        count: fetchStatus?.data?.data?.[3].count,
      },
      { name: "Approved", count: fetchStatus?.data?.data?.[4].count },
      { name: "Booked", count: fetchStatus?.data?.data?.[5].count },
      { name: "Awaiting Payment", count: fetchStatus?.data?.data?.[6].count },
    ]);
  }, [fetchStatus?.data]);
  const clickrow = (row) => {};
  const dispatch = useDispatch();
  const filterOptions = [
    "New Requests",
    "Quote Requested",
    "Awaiting Approval",
    "Approved",
    "Booked",
    "Awaiting payment",
  ];
  useEffect(() => {
    if (Range[0] && Range[1] && selectedFilter) {
      setSelectedFilter(undefined);
    }
  }, [Range]);
  useEffect(() => {
    if (selectedFilter) {
      setRange([]);
    }
  }, [selectedFilter]);

  return (
    <>
      <div className="maintenace_overview">
        <div className="maintenance_content_wrapper">
          <div className="header">
            <img src={logo}></img>
            <h1>Maintenance</h1>
          </div>
          <div className="main_content_wrapper">
            <div className="maintanance_overview_graph" ref={containerRef}>
              <div className="main_graph_Overview">
                <h2 className="main_g_head">Overview</h2>
                <div className="main_graph_data">
                  <div
                    className="main_graph_dia"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {fetchStatus?.data?.data?.[0]?.count === 0 &&
                    fetchStatus?.data?.data?.[2]?.count === 0 &&
                    fetchStatus?.data?.data?.[3]?.count === 0 &&
                    fetchStatus?.data?.data?.[4]?.count === 0 &&
                    fetchStatus?.data?.data?.[5]?.count === 0 &&
                    fetchStatus?.data?.data?.[6]?.count === 0 ? (
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          color: "rgb(129,126,255)",
                        }}
                      >
                        No maintenance to show
                      </span>
                    ) : (
                      <ReactEcharts
                        style={{ width: "100%", height: "100%", top: "10vh" }}
                        option={option}
                      />
                    )}
                  </div>
                  <div className=" maintanence_list">
                    <ul>
                      {Data.map((item, index) => {
                        return (
                          <li
                            className={
                              index === 0
                                ? fetchStatus?.data?.data?.[0]?.count +
                                    fetchStatus?.data?.data?.[1]?.count >
                                  0
                                  ? "animated"
                                  : "notAnimated"
                                : fetchStatus?.data?.data?.[index + 1]?.count >
                                  0
                                ? "animated"
                                : "notAnimated"
                            }
                            onClick={() => {
                              if (index === 0) {
                                if (
                                  fetchStatus?.data?.data?.[0]?.count +
                                    fetchStatus?.data?.data?.[1]?.count >
                                  0
                                ) {
                                  dispatch(
                                    setMaintenanceFilter(filterOptions[index])
                                  );
                                  navigate("/maintenance/log");
                                }
                              } else if (
                                fetchStatus?.data?.data?.[index + 1]?.count > 0
                              ) {
                                dispatch(
                                  setMaintenanceFilter(filterOptions[index])
                                );
                                navigate("/maintenance/log");
                              }
                            }}
                          >
                            <span>
                              <div className={`badge${index + 1}`}></div>
                              {item.name}
                            </span>
                            <p style={{}}>
                              {/* {index === 0
                                ? fetchStatus?.data?.data?.[0]?.count &&
                                  fetchStatus?.data?.data?.[1]?.count
                                  ? addLeadingZero(
                                      fetchStatus?.data?.data?.[0]?.count +
                                        fetchStatus?.data?.data?.[1]?.count
                                    )
                                  : "00"
                                : fetchStatus?.data?.data?.[0]?.count
                                ? addLeadingZero(
                                    fetchStatus?.data?.data?.[index + 1]?.count
                                  )
                                : "00"} */}
                              {item.count < 10 ? `0${item.count}` : item.count}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="maintanance_overview_content" ref={belowRef}>
              <div className="Key_analytics">
                <div className="key_analytics_content">
                  <div className="innerKey_wrapper">
                    <span className="heading ">Key analytics</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        gap: "max(13px, 1.8vw)",
                      }}
                    >
                      <DateRange
                        onChange={(e) => {
                          console.log(e, "asjaaksjd");
                          setRange(e);
                        }}
                        value={Range}
                      />
                      <div className="date">
                        {FilterOptions.map((ele) => {
                          return (
                            <span
                              className={
                                selectedFilter === ele.value ? "active" : ""
                              }
                              onClick={() => setSelectedFilter(ele.value)}
                            >
                              {ele.label}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="main_card_wrapper">
                    {carddata.map((ele) => {
                      return (
                        <div
                          className="main_main_card_cover"
                          onClick={(e) => {
                            setkeydata(ele);
                            setPopup(true);
                          }}
                        >
                          <div className="main_main_card">
                            <div className="img_wrapper">
                              <img src={ele.img}></img>
                            </div>
                            <div className="text_erapper">
                              <h1>£{formatPounds(ele.amount)}</h1>
                              <p>{ele.text}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="upcoming_maintenance_jobs">
                <h2 className="upcoming_main_head">
                  Upcoming Maintenance Jobs
                </h2>
                <div className="upcoming_card_wrapper">
                  {upcomingMaintenanceData.length > 0 ? (
                    upcomingMaintenanceData.map((ele, index) => {
                      return (
                        <div className="upcoming_card_cover">
                          <div className="upcoming_card">
                            <img src={info}></img>
                            <div className="text_erapper">
                              <p className="upcoming_card_text">{ele?.title}</p>
                              <h6>
                                <p>
                                  <p>
                                    {ele?.property?.name +
                                      " " +
                                      ele?.property?.street}
                                    ,
                                  </p>
                                  <p>{ele?.property?.city},</p>{" "}
                                  <p>{ele?.property?.postcode}</p>
                                </p>
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="upcoming_card_alternate"
                      style={{
                        width: "100%",
                        height: "100%",
                        // border: "1px solid black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // marginLeft: "33%",
                      }}
                    >
                      <div
                        className="up_alter_cover"
                        style={{
                          width: "50%",
                          height: "60%",
                          // border: "1px solid black",
                          fontSize: "max(14px, 1.2vw)",
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "250px",
                          // marginLeft: "33%",
                        }}
                      >
                        No Upcoming Maintenance
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <MaintenanceOverviewPopup
          keydata={keydata}
          setPopup={setPopup}
          columns={columns}
          onclickrow={clickrow}
          Range={Range}
          popupstate={popup}
          selectedFilter={selectedFilter}
        />
      )}
    </>
  );
}
