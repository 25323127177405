// import React, { useState } from "react";
// import {
//   GoogleMap,
//   useLoadScript,
//   Marker,
//   useJsApiLoader,
// } from "@react-google-maps/api";
// import Markerpin from "../../../Assets/Common/Markerpin.svg";
// import usePlacesAutocomplete, {
//   getGeocode,
//   getLatLng,
// } from "use-places-autocomplete";

// const Map = (props) => {
//   const { setMapCenter, mapCenter } = props;
//   /////----------------Google---Map
//   //const libraries = ["places"];
//   const [libraries] = useState(["places"]);

//   const mapContainerStyle = {
//     width: "100%",
//     position: "relative",
//     overflow: "hidden",
//     /* margin-bottom: 21px; */
//     height: "275px",
//     border: "3px solid #ddd",
//     borderRadius: "20px",
//     borderBottomLeftRadius: "0px",
//     borderBottomRightRadius: "0px",
//   };
//   const center = {
//     lat: 7.2905715, // default latitude
//     lng: 80.6337262, // default longitude
//   };
//   ///----googleMapsAPI_key
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: "AIzaSyCqrujudljl8XJMUorzeCZX4m9ALeKaw0A",
//     libraries,
//   });

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }
//   if (!isLoaded) {
//     return <div>Loading maps</div>;
//   }
//   const handleMapClick = (event) => {
//     // Update the map center when the map is clicked

//     // setMapCenter({
//     //   lat: event.latLng.lat(),
//     //   lng: event.latLng.lng(),
//     // });
//     props.ChangeLocation(event);
//   };
//   const handleMarkerClick = () => {
//     // Handle marker click event if needed
//     console.log("Marker clicked!");
//   };

//   const onSearchBoxLoad = (ref) => {
//     if (ref) {
//       const searchBox = new window.google.maps.places.SearchBox(ref);
//       searchBox.addListener("places_changed", () => {
//         const places = searchBox.getPlaces();
//         console.log(ref.current, "Search", places);
//         if (places.length === 0) {
//           return;
//         }

//         const newCenter = {
//           lat: places[0].geometry.location.lat(),
//           lng: places[0].geometry.location.lng(),
//         };
//         //console.log(searchBox, "Placess" , newCenter);
//         // Set the new map center
//         //mapCenter.panTo(newCenter);
//         setMapCenter({
//           ...mapCenter,
//           lat: newCenter.lat,
//           lng: newCenter.lng,
//         });
//         console.log(newCenter, "map");
//         props.SearchedLocaitons(newCenter);
//       });
//     }
//   };

//   if (loadError) return "Error loading maps";
//   if (!isLoaded) return "Loading maps";

//   // // Function to get user's current location
//   // const getUserLocation = () => {
//   //   if (navigator.geolocation) {
//   //     navigator.geolocation.getCurrentPosition(
//   //       (position) => {
//   //         const userLocation = {
//   //           lat: position.coords.latitude,
//   //           lng: position.coords.longitude,
//   //         };
//   //         setMapCenter(userLocation);

//   //       },
//   //       (error) => {
//   //         console.error("Error getting user location:", error);
//   //         // Handle error, such as displaying a message to the user
//   //       }
//   //     );
//   //   } else {
//   //     console.error("Geolocation is not supported by this browser.");
//   //     // Handle unsupported browser scenario
//   //   }
//   // };

//   // const handleLocationClick = () => {
//   //   // Ask for permission to get user's location
//   //   if (navigator.permissions) {
//   //     navigator.permissions
//   //       .query({ name: "geolocation" })
//   //       .then((result) => {
//   //         if (result.state === "granted") {
//   //           getUserLocation();
//   //         } else if (result.state === "prompt") {
//   //           // Permission prompt will be displayed to the user
//   //           // Handle the prompt if needed
//   //         } else if (result.state === "denied") {
//   //           // User denied the location permission
//   //           // Handle denied scenario, e.g., display a message or provide an alternative
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error querying permission:", error);
//   //       });
//   //   } else {
//   //     console.error("Permissions API is not supported by this browser.");
//   //     // Handle unsupported browser scenario
//   //   }
//   // };
//   const getUserLocation = () => {
//     if (navigator.geolocation) {
//       console.log(navigator.geolocation, "navigator.geolocation");
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const userLocation = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           setMapCenter(userLocation); // Assuming you have a function to set map center
//         },
//         (error) => {
//           console.error("Error getting geolocation:", error);
//           if (error.code === error.PERMISSION_DENIED) {
//             alert(
//               "Access to geolocation was denied. Please enable it in your browser settings."
//             );
//           } else {
//             alert("Error getting geolocation. Please try again later.");
//           }
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//       // Handle unsupported browser scenario
//     }
//   };

//   // Function to handle location button click
//   const handleLocationClick = () => {
//     if (navigator.permissions) {
//       console.log("gifusufhufhufuisu1");

//       navigator.permissions
//         .query({ name: "geolocation" })
//         .then((result) => {
//           console.log(result, "statuetuaygdyasud");
//           if (result.state === "granted") {
//             getUserLocation();
//           } else if (result.state === "prompt") {
//             alert("This app wants to access your location. Please allow.");

//             // Call getCurrentPosition again after user interaction
//             navigator.geolocation.getCurrentPosition(
//               (position) => {
//                 const { latitude, longitude } = position.coords;
//                 console.log("Latitude:", latitude);
//                 console.log("Longitude:", longitude);

//                 // Handle latitude and longitude data as needed
//                 const userLocation = {
//                   lat: latitude,
//                   lng: longitude,
//                 };
//                 setMapCenter(userLocation); // Assuming you have a function to set map center
//               },
//               (error) => {
//                 console.error("Error getting geolocation:", error);
//                 if (error.code === error.PERMISSION_DENIED) {
//                   alert(
//                     "Access to geolocation was denied. Please enable it in your browser settings."
//                   );
//                 } else {
//                   alert("Error getting geolocation. Please try again later.");
//                 }
//               }
//             );
//           } else if (result.state === "denied") {
//             const enableLocationMessage =
//               "Location access is required for this feature. Please enable location services in your browser settings.";

//             alert(enableLocationMessage);
//           }
//         })
//         .catch((error) => {
//           console.error("Error querying permission:", error);

//           alert("Please enable location services in your browser settings.");
//         });
//     } else {
//       console.error("Permissions API is not supported by this browser.");
//       alert(
//         "Your browser does not support location permissions. Please use a different browser."
//       );
//     }
//   };

//   const silverMapStyles = [
//     {
//       elementType: "geometry",
//       stylers: [{ color: "#f5f5f5" }],
//     },
//     {
//       elementType: "labels.icon",
//       stylers: [{ visibility: "off" }],
//     },
//     {
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#616161" }],
//     },
//     {
//       elementType: "labels.text.stroke",
//       stylers: [{ color: "#f5f5f5" }],
//     },
//     {
//       featureType: "administrative.land_parcel",
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#bdbdbd" }],
//     },
//     {
//       featureType: "poi",
//       elementType: "geometry",
//       stylers: [{ color: "#eeeeee" }],
//     },
//     {
//       featureType: "poi",
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#757575" }],
//     },
//     {
//       featureType: "poi.park",
//       elementType: "geometry",
//       stylers: [{ color: "#e5e5e5" }],
//     },
//     {
//       featureType: "poi.park",
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#9e9e9e" }],
//     },
//     {
//       featureType: "road",
//       elementType: "geometry",
//       stylers: [{ color: "#ffffff" }],
//     },
//     {
//       featureType: "road.arterial",
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#757575" }],
//     },
//     {
//       featureType: "road.highway",
//       elementType: "geometry",
//       stylers: [{ color: "#dadada" }],
//     },
//     {
//       featureType: "road.highway",
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#616161" }],
//     },
//     {
//       featureType: "road.local",
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#9e9e9e" }],
//     },
//     {
//       featureType: "transit.line",
//       elementType: "geometry",
//       stylers: [{ color: "#e5e5e5" }],
//     },
//     {
//       featureType: "transit.station",
//       elementType: "geometry",
//       stylers: [{ color: "#eeeeee" }],
//     },
//     {
//       featureType: "water",
//       elementType: "geometry",
//       stylers: [{ color: "#c9c9c9" }],
//     },
//     {
//       featureType: "water",
//       elementType: "labels.text.fill",
//       stylers: [{ color: "#9e9e9e" }],
//     },
//   ];

//   const customIcon = {
//     url: Markerpin,
//     scaledSize: window.google.maps.Size(50, 50), // Scale size of the icon
//     origin: window.google.maps.Point(0, 0), // Origin point of the icon
//     anchor: window.google.maps.Point(25, 50), // Anchor point of the icon
//   };
//   const centerpos = {
//     lat: 7.2905715, // default latitude
//     lng: 80.6337262, // default longitude
//   };
//   return (
//     <>
//       <GoogleMap
//         mapContainerStyle={mapContainerStyle}
//         zoom={13}
//         center={mapCenter[0]}
//         // onClick={handleMapClick}
//         options={{
//           styles: silverMapStyles,
//           disableDefaultUI: true,
//         }}
//       >
//         {mapCenter.map((location, index) => {
//           return (
//             <Marker
//               position={location}
//               icon={customIcon}
//               key={index}
//               // onClick={handleMarkerClick}
//             />
//           );
//         })}
//       </GoogleMap>
//     </>
//   );
// };

// export default Map;

import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Markerpin from "../../../Assets/Common/Markerpin.svg";

const Map = (props) => {
  const { setMapCenter, mapCenter } = props;
  const [libraries] = useState(["places"]);

  const mapContainerStyle = {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    height: "275px",
    // height: "100%",
    border: "3px solid #ddd",
    borderRadius: "20px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  };
  useEffect(() => {
    console.log(mapCenter, "kjuhygtvbhnjkm");
  }, [mapCenter]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCIpJxtSOEE6fCWr8Y_WkH72u2MTq0_1wo",
    libraries,
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const handleMapClick = (event) => {
    // props.ChangeLocation(event);
    const googleMapsUrl = `https://www.google.com/maps?q=${event.lat},${event.lng}`;
    window.open(googleMapsUrl, "_blank");
    console.log(event, "ijuytrgdhjyuij");
  };

  const silverMapStyles = [
    {
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
  ];

  const customIcon = {
    url: Markerpin,
    scaledSize: new window.google.maps.Size(50, 50),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(25, 50),
  };

  return (
    <div style={{ cursor: "default" }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={mapCenter[0]}
        options={{
          styles: silverMapStyles,
          disableDefaultUI: true,
          draggableCursor: "default", // Set cursor style when not dragging
          draggingCursor: "default", // Set cursor style when dragging
        }}
      >
        {mapCenter.map((location, index) => (
          <Marker
            position={location}
            icon={customIcon}
            key={index}
            onClick={() => handleMapClick(location)}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default Map;
