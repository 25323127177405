import React, { useCallback, useEffect, useState } from "react";
import logo from "./Assests/logo.svg";
import Search from "../../../MainComponent/fields/search";
import deleteIcon from "./Assests/delete.svg";
import pdfIcon from "./Assests/pdf.svg";
import imageIcon from "../../../Assets/Common/imageIcon.svg";
import greater from "./Assests/greater.svg";
import Dropzone, { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import dragFile from "./Assests/dragfile.svg";
import ConformPopup from "./ConfirmPopup";
import "./Styles/InitialProperty.scss";
import {
  usePresignedUrlPost,
  usePresignedUrlPut,
  usePropertyCreatePostAPI,
} from "../../../Api/PropertiesQuery";
import { UseDeletePropertyDoc } from "../../../Api/InitialSetupQuery";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyFileFilter } from "../../../redux/reducer/counterslice";
import { addLeadingZero } from "../../../Utils/Utils";
import styles from "./FileUploadView.module.scss";
import { useQueryClient } from "@tanstack/react-query";

var filesloc = [];
var presignedURLloc = [];

const getFileIcon = (fileName) => {
  const extension = fileName.toLowerCase().split(".").pop();
  const imageExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
  return imageExtensions.includes(extension) ? imageIcon : pdfIcon;
};

const FileUploadView = ({ dataFromParent, setSearch, search }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [presignedURL, setPresignedURL] = useState("");
  const [confirmGas, setConfirmGas] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);

  const [PresignedUrlPayload, setPresignedUrlPayload] = useState({
    multiple_files: [],
  });
  const propertyFileFilter = useSelector(
    (state) => state.counter.propertyFileFilter
  );
  console.log(propertyFileFilter, "propertyFileFilter");
  const initialSelectedProperty = useSelector(
    (state) => state.counter.initialSelectedProperty
  );
  const { propertyId, id } = useParams();

  const [finalData, setFinalData] = useState("");
  const [uploadProgress, setUploadProgress] = useState({});
  const createPresignedURL = usePresignedUrlPost(PresignedUrlPayload);
  const propertyFilePost = usePropertyCreatePostAPI(finalData);
  const propertyFileDelete = UseDeletePropertyDoc(() => {
    setConfirmGas(false);
  });

  useEffect(() => {
    dataFromParent.map((files) => {
      setUploadProgress((prev) => ({
        ...prev,
        [files.custom_name]: 0,
      }));
    });
  }, [dataFromParent]);
  const queryClient = useQueryClient();

  const handleFileDrop = useCallback((acceptedFiles) => {
    setIsDragging(false);
    queryClient.invalidateQueries({
      queryKey: ["getReleaseFolder"],
    });
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
    filesloc = acceptedFiles;
    const Files = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    if (Files.length > 0) {
      const formattedFiles = Files.map((e) => ({
        filename: `property/${e.path.replace(/^\.\//, "")}`,
        file_type: e.type,
      }));
      setPresignedUrlPayload({ multiple_files: formattedFiles });

      setTimeout(() => {
        createPresignedURL.mutate();
      }, 100);
    }
  }, []);
  var count = 0;
  const successFunction = (response) => {
    count++;

    if (response.status === 200) {
      if (presignedURLloc.length == filesloc.length) {
        filesloc.map((file, index) => {
          propertyFilePost.mutate({
            custom_name: file.name,
            document_type: propertyId,
            file: presignedURLloc[index],
            expiry_date: "",
            property: id,
          });
        });
      }
      console.log(count, response, response?.config, "count");
      const data = response?.config.data;
      const updatedFiles = filesloc.filter((item) => item.name !== data.name);

      console.log(updatedFiles, "123456765432", data.name);
      filesloc = updatedFiles;

      // if (index == files.length - 1) {
      //   // filesloc = [];
      // }

      // console.log(data, updatedFiles, files, response, "data");
      // setFiles([]);
    }
  };

  const updatePresignedUrlPUT = usePresignedUrlPut(successFunction);
  useEffect(() => {
    console.log(presignedURL?.data?.data, "2121212");
    if (createPresignedURL.data?.data?.length) {
      const output = createPresignedURL?.data?.data.map(
        (url) => url.split("?")[0]
      );
      // setPresignedURL(output);
      presignedURLloc = output;
      console.log("ywuioj", output);
      setPresignedUrlPayload({ multiple_files: [] });

      const binaryData = filesloc.map((file, index) => ({
        filename: file.name,
        type: file.type,
        url: output[index],
        data: file,
      }));

      if (binaryData.length > 0) {
        console.log(binaryData, "12121212121212121");

        binaryData.forEach((binary, index) => {
          updatePresignedUrlPUT.mutate({
            url: createPresignedURL.data.data[index],
            imagePayload: binary.data,
            type: binary.type,
            onUploadProgress: (progressEvent) => {
              console.log(
                progressEvent.loaded,
                progressEvent.total,
                "progressEvent"
              );
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(
                `File ${binary.filename} Upload Progress: ${percentCompleted}%`
              );
              setUploadProgress((prev) => ({
                ...prev,
                [binary.filename]: percentCompleted,
              }));
            },
            successFunction: successFunction,
          });

          // setFiles([]);
        });
      }
    }
  }, [createPresignedURL.data]);

  const onDragEnter = useCallback(
    (e) => {
      if (propertyFileFilter === "Shared") return;
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    },
    [propertyFileFilter]
  );

  const onDragLeave = useCallback(
    (e) => {
      if (propertyFileFilter === "Shared") return;
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    },
    [propertyFileFilter]
  );

  return (
    <Dropzone
      onDrop={handleFileDrop}
      noClick
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      disabled={propertyFileFilter === "Shared"}
      accept={{
        "application/pdf": [".pdf"],
        "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      }}
    >
      {({ getRootProps, getInputProps, open }) => (
        <div
          {...getRootProps({
            onClick: (e) => e.stopPropagation(),
          })}
          className={`${styles.fullPageDropzone} ${
            isDragging ? styles.dragging : ""
          } ${propertyFileFilter === "Shared" ? styles.disabled : ""}`}
        >
          <input
            {...getInputProps({
              accept: ".pdf,.png,.jpg,.jpeg,.webp,.doc,.docx,.xls,.xlsx",
            })}
            disabled={propertyFileFilter === "Shared"}
          />

          {isDragging && (
            <div className={styles.dropOverlay}>
              <div className={styles.dropMessage}>
                <img src={dragFile} alt="Drop files here" />
                <p>Drop files here to upload</p>
                <p
                  style={{ fontSize: "14px", color: "#666", marginTop: "8px" }}
                >
                  Supported formats: PDF, Images, Word & Excel documents
                </p>
              </div>
            </div>
          )}

          <div className={styles.contentContainer}>
            <div className="card-contents">
              <div className={styles.cardLogo}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={logo} alt="logo" />
                  <div>
                    <p className="init-headings">
                      {"Total files uploaded"}
                      <span
                        style={{
                          color: "#817EFF",
                          fontSize: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        {addLeadingZero(dataFromParent.length || 0)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="init-card-top">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "24px",
                      opacity: "50%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/initialSetup");
                      dispatch(setPropertyFileFilter(propertyFileFilter));
                    }}
                  >
                    Property Folders
                  </p>
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={greater}
                    alt="greater"
                  />
                  <p style={{ fontSize: "24px" }}>
                    {initialSelectedProperty?.name}
                  </p>
                </div>
                <div className="card-search">
                  <Search
                    setsearch={setSearch}
                    style={{ width: "200px" }}
                    setPageIndex={setPageIndex}
                  />
                </div>
              </div>

              <div className="card-body-content">
                <div className="property-content">
                  {propertyFileFilter === "Property" && (
                    <img
                      className={styles.dragFile}
                      src={dragFile}
                      alt="dropzone"
                    />
                  )}
                  <div className="file-body">
                    {dataFromParent.length === 0 && filesloc.length == 0 ? (
                      <div
                        className="no-files"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          zIndex: "1",
                        }}
                      >
                        {/* <img
                          src={dragFile}
                          style={{ marginTop: "70px" }}
                          alt="dropzone"
                        /> */}
                      </div>
                    ) : (
                      <>
                        {filesloc.map((file) => (
                          <div key={file.id}>
                            <div
                              className="file-list"
                              style={{
                                backgroundColor: "white",
                              }}
                            >
                              <div className="file-left">
                                <img
                                  src={getFileIcon(file.name)}
                                  alt="file icon"
                                />
                                <p className="file-text">{file.name}</p>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {" "}
                                <div className="progress-percentage">
                                  {uploadProgress[file.name] || 0}%
                                </div>{" "}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent: "center",
                                    borderRadius: "10px",
                                    // backgroundColor: "#D9D9D9",
                                    border: "1px solid #D9D9D9",
                                    width: "160px",
                                    height: "15px",
                                  }}
                                >
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: `${
                                        uploadProgress[file.name] || 0
                                      }%`,
                                      backgroundColor: "#817EFF",
                                      zIndex: "22",
                                      height: "11px",
                                      borderRadius: "10px",
                                    }}
                                  ></div>
                                </div>{" "}
                                <img
                                  src={deleteIcon}
                                  onClick={() => {}}
                                  alt="deleteicon"
                                />
                              </div>
                            </div>
                          </div>
                        ))}

                        {dataFromParent.map((file) => {
                          const data = filesloc.map(
                            (ele) => ele.name !== file.custom_name
                          );
                          console.log(uploadProgress[file.custom_name], "data");
                          if (data) {
                            return (
                              <div key={file.id}>
                                <div
                                  className="file-list"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => {
                                    window.open(file.file, "_blank");
                                  }}
                                >
                                  <div className="file-left">
                                    <img
                                      src={getFileIcon(file.custom_name)}
                                      alt="file icon"
                                    />
                                    <p className="file-text">
                                      {file.custom_name}
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    {/* <div className="progress-percentage">
                                  {uploadProgress[file.custom_name] || 0}%
                                </div>{" "}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent: "center",
                                    borderRadius: "10px",
                                    // backgroundColor: "#D9D9D9",
                                    border: "1px solid #D9D9D9",
                                    width: "160px",
                                    height: "15px",
                                  }}
                                >
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: `${
                                        uploadProgress[file.custom_name] || 0
                                      }%`,
                                      backgroundColor: "#817EFF",
                                      zIndex: "22",
                                      height: "11px",
                                      borderRadius: "10px",
                                    }}
                                  ></div>
                                </div>{" "} */}
                                    {propertyFileFilter === "Property" && (
                                      <img
                                        className="delete-icon"
                                        src={deleteIcon}
                                        onClick={(e) => {
                                          console.log(
                                            file.id,
                                            "kjasjfdkjasdljf"
                                          );
                                          e.stopPropagation();
                                          setConfirmGas(true);
                                          setSelectedId(file.id);
                                        }}
                                        alt="deleteicon"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>
                  {propertyFileFilter === "Property" ? (
                    <section
                      className="wfp--dropzone"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "72px",
                        background: "#fff",
                        border: "2px dashed #817EFF",
                        padding: "41px 25px",
                        borderRadius: "15px",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "30px",
                          width: "100%",
                        }}
                      ></div>
                      <div
                        style={{
                          alignItems: "end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",

                            // flexDirection: "column",
                            // gap: "5px",
                          }}
                        >
                          <>
                            <p style={{ fontSize: "16px" }}>
                              Drag and drop files to attach or
                            </p>
                            <button
                              className="Next_Button"
                              style={{
                                // outline: "1px solid #817eff",
                                // marginBottom: "20px",
                                width: "fit-content",
                                background: "white",
                                color: "#817eff",
                                fontSize: "16px",
                                // padding: "9px 26px",
                                borderRadius: "13px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                open();
                              }}
                            >
                              Browse
                            </button>
                          </>
                        </div>
                      </div>
                    </section>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "10px",
                        borderTop: "1px solid #D9D9D9",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      <span>
                        File uploads are disabled for shared properties. For any
                        updates, please reach out to the property manager.
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <ConformPopup
                isOpen={confirmGas}
                setIsOpen={setConfirmGas}
                handleConfirmSubmit={() => {
                  propertyFileDelete.mutate({
                    data: selectedId,
                  });
                }}
                customMessage={"Are you sure you want to delete this file?"}
              />
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default FileUploadView;
