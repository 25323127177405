const formatPounds = (amount) => {
  if (amount === undefined || amount === null) {
    return 0;
  }
  const value = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(amount));
  if (!value) {
    return 0;
  } else {
    return value;
  }
};
// function formatAddress(addressParts) {
//   return addressParts.filter((part) => part).join(", ");
// }

function formatAddress(addressParts) {
  const value = addressParts.filter((part) => part).join(", ");
  if (value === NaN || value === undefined) {
    return 0;
  } else {
    return value;
  }
}

const addLeadingZero = (num) => {
  if (num === undefined || num === null) {
    return "0";
  }
  return num < 10 ? `0${num}` : `${num}`;
};
// const formatPoundsNoDec = (amount) => {
//   const value = new Intl.NumberFormat("en-GB", {
//     style: "decimal",
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 0,
//   }).format(amount);
//   // console.log(typeof value, typeof amount, "ghvbjknh");
//   if (isNaN(amount) || amount === undefined) {
//     return 0;
//   } else {
//     return value;
//   }
// };

const formatPoundsNoDec = (amount) => {
  if (isNaN(amount) || amount === undefined) {
    return 0;
  } else {
    const value = new Intl.NumberFormat("en-GB", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);

    return value;
  }
};

function findIndexByType(array, type) {
  const index = array.findIndex((item) => item.document_type === type);
  return index !== -1 ? index : "not available";
}

function formatUKTelephone(number) {
  const cleaned = number.replace(/\D/g, "");
  const countryCode = "+44 ";
  if (cleaned.length === 11 && cleaned.startsWith("07")) {
    return (
      countryCode + cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "7$1 $2 $3")
    );
  } else if (cleaned.length === 11 && cleaned.startsWith("02")) {
    return countryCode + cleaned.replace(/(\d{2})(\d{4})(\d{4})/, "20 $1 $2");
  } else if (cleaned.length === 10 && cleaned.startsWith("01")) {
    return countryCode + cleaned.replace(/(\d{2})(\d{4})(\d{6})/, "1$1 $2");
  } else if (cleaned.length === 10 && cleaned.startsWith("03")) {
    return (
      countryCode + cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "3$1 $2 $3")
    );
  } else {
    return number;
  }
}
const formatName = (title, first_name, last_name) => {
  const formattedTitle = title && title !== null ? `${title} ` : "";
  const formattedFirstName = first_name ? `${first_name} ` : "";
  const formattedLastName = last_name || "";

  return `${formattedTitle}${formattedFirstName}${formattedLastName}`.trim();
};
const validatePassword = (password) => {
  const minLength = 8;
  const maxLength = 16;
  const numberRegex = /\d/;
  const capitalLetterRegex = /[A-Z]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  // Check length
  if (password.length < minLength || password.length > maxLength) {
    return "Password must be between 8 and 16 characters long.";
  }

  // Check for at least one number
  if (!numberRegex.test(password)) {
    return "Password must contain at least one number.";
  }

  // Check for at least one capital letter
  if (!capitalLetterRegex.test(password)) {
    return "Password must contain at least one capital letter.";
  }

  // Check for at least one special character
  if (!specialCharRegex.test(password)) {
    return "Password must contain at least one special character.";
  }

  // Password meets all conditions
  return "Password is valid.";
};

// const mutateData = async (
//   url,
//   methodType = "post",
//   payload,
//   headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   }
// ) => axios[methodType](baseUrl + url, payload, { headers });
const handleTruncate = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

export {
  formatPounds,
  // mutateData,
  formatAddress,
  addLeadingZero,
  formatPoundsNoDec,
  findIndexByType,
  formatUKTelephone,
  formatName,
  validatePassword,
  handleTruncate,
};
