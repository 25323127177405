// import React, { useEffect, useState } from "react";
// import "./Styles/InitialProperty.scss";
// import FileIcon from "./Assests/FileIcon.svg";
// import Share from "./Assests/share-icon.svg";
// import { useNavigate } from "react-router-dom";
// import {
//   useComplianceUpdateTitle,
//   useFetchOnboardingFolder,
// } from "../../../Api/InitialSetupQuery";
// import { useDispatch, useSelector } from "react-redux";
// import { addLeadingZero } from "../../../Utils/Utils";
// import { setInitialSelectedProperty } from "../../../redux/reducer/counterslice";
// import { Skeleton } from "antd";

// const PropertyFiles = ({ filter, searchString }) => {
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.counter.loginuserdata);
//   const [data, setData] = useState([]);
//   const [selectedId, setSelectedId] = useState("");
//   const [finalPayload, setFinalPayload] = useState({ is_shared: true });
//   const dispatch = useDispatch();

//   const propertyFileFilter = useSelector(
//     (state) => state.counter.propertyFileFilter
//   );

//   const fetchOnboardingFolder = useFetchOnboardingFolder({
//     client: user?.id,
//     shared: propertyFileFilter === "Property" ? false : true,
//     search: searchString,
//   });
//   const changeTitle = useComplianceUpdateTitle({
//     data: { is_shared: true },
//     params: { id: selectedId },
//     handelSuccess: () => {},
//   });

//   useEffect(() => {
//     console.log(useFetchOnboardingFolder, "ljkalsjfdljalslfdaso");
//     if (fetchOnboardingFolder.data?.data?.results) {
//       setData(fetchOnboardingFolder.data.data?.results);
//     }
//   }, [fetchOnboardingFolder.data]);

//   const folderClick = (id, propertyId) => {
//     navigate(`/initialSetup/${id}/${propertyId}`);
//   };

//   return (
//     <>
//       <div>
//         <p className="init-headings">
//           {filter === "Property" ? "Property Folders" : "Shared"}{" "}
//           <span style={{ color: "#817EFF", fontSize: "20px" }}>
//             {fetchOnboardingFolder.data?.data?.count
//               ? addLeadingZero(fetchOnboardingFolder.data?.data?.count)
//               : "0"}
//           </span>
//         </p>
//         <div
//           className="file-body"
//           style={{ height: "60vh", overflow: "auto", padding: "10px" }}
//         >
//           {fetchOnboardingFolder.isFetching ? (
//             <div className="skelitonDiv">
//               <Skeleton active paragraph={{ rows: 4 }} />
//             </div>
//           ) : (
//             data?.map((file) => {
//               // const showFile =
//               //   (!file.is_shared && filter === "Property") ||
//               //   (file.is_shared && filter === "Shared");
//               // if (!showFile) return null;

//               return (
//                 <div key={file.id}>
//                   <div
//                     className="file-list"
//                     onClick={() => {
//                       folderClick(file.id, file.property);
//                       dispatch(setInitialSelectedProperty(file));
//                     }}
//                   >
//                     <div className="file-left">
//                       <img src={FileIcon} alt="File Icon" />
//                       <p className="file-text">{file.name}</p>
//                     </div>
//                     <div className="file-end">
//                       <p
//                         style={{
//                           color: "#817EFF",
//                           fontSize: "20px",
//                           fontWeight: "400",
//                           lineHeight: "30px",
//                         }}
//                       >
//                         {file.documents_count} Files
//                       </p>
//                       {!file.is_shared && file.documents_count > 0 && (
//                         <div className="share-icon">
//                           <img
//                             // className="share-icon"
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               setSelectedId(file.id);
//                               setTimeout(() => {
//                                 changeTitle.mutate();
//                               }, 150);
//                             }}
//                             src={Share}
//                             alt="Share"
//                           />
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               );
//             })
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default PropertyFiles;

import React, { useEffect, useState } from "react";
import "./Styles/InitialProperty.scss";
import FileIcon from "./Assests/FileIcon.svg";
import Share from "./Assests/share-icon.svg";
import { useNavigate } from "react-router-dom";
import {
  useComplianceUpdateTitle,
  useFetchOnboardingFolder,
} from "../../../Api/InitialSetupQuery";
import { useDispatch, useSelector } from "react-redux";
import { addLeadingZero } from "../../../Utils/Utils";
import {
  loginuserdata,
  setInitialSelectedProperty,
  token,
  selectedButton,
} from "../../../redux/reducer/counterslice";
import { Skeleton } from "antd";
import { toast } from "sonner";

const PropertyFiles = ({ searchString }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.counter.loginuserdata);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [finalPayload, setFinalPayload] = useState({ is_shared: true });
  const dispatch = useDispatch();

  const propertyFileFilter = useSelector(
    (state) => state.counter.propertyFileFilter
  );
  const selectedButton = useSelector((state) => state.counter.selectedButton);

  const fetchOnboardingFolder = useFetchOnboardingFolder({
    client: user?.id,
    shared: propertyFileFilter === "Property" ? false : true,
    search: searchString,
  });
  const changeTitle = useComplianceUpdateTitle({
    data: { is_shared: true },
    params: { id: selectedId },
    handelSuccess: () => {},
  });

  useEffect(() => {
    //   console.log(fetchOnboardingFolder.data, "ljkalsjfdljalslfdaso");
    if (fetchOnboardingFolder.data?.data?.results) {
      //     if () {
      //       localStorage.setItem("login", "false");
      //       localStorage.setItem("token", "");
      //       dispatch(token(""));
      //       dispatch(loginuserdata([]));
      //       toast.warning("Privileges has been updated.");
      //       navigate("/");
      //     }
      setData(fetchOnboardingFolder.data.data?.results);
    }
  }, [fetchOnboardingFolder.data]);

  const folderClick = (id, propertyId) => {
    navigate(`/initialSetup/${id}/${propertyId}`);
  };

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <p className="init-headings">
          {/* {filter === "Property" ? "Property Folders" : "Shared"}{" "} */}
          {selectedButton === "Property"
            ? "To do Folders"
            : "Submitted Folders"}{" "}
          <span style={{ color: "#817EFF", fontSize: "20px" }}>
            {fetchOnboardingFolder.data?.data?.count
              ? addLeadingZero(fetchOnboardingFolder.data?.data?.count)
              : "0"}
          </span>
        </p>
        <div
          className="file-body"
          style={{ flex: "1", overflow: "auto", padding: "10px" }}
        >
          {fetchOnboardingFolder.isFetching ? (
            <div className="skelitonDiv">
              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          ) : (
            data?.map((file) => {
              // const showFile =
              //   (!file.is_shared && filter === "Property") ||
              //   (file.is_shared && filter === "Shared");
              // if (!showFile) return null;

              return (
                <div key={file.id}>
                  <div
                    className="file-list"
                    onClick={() => {
                      folderClick(file.id, file.property);
                      dispatch(setInitialSelectedProperty(file));
                    }}
                  >
                    <div className="file-left">
                      <img src={FileIcon} alt="File Icon" />
                      <p
                        className="file-text"
                        style={{ textTransform: "capitalize" }}
                      >
                        {file.name}
                      </p>
                    </div>
                    <div className="file-end">
                      <p
                        style={{
                          color: "#817EFF",
                          fontSize: "20px",
                          fontWeight: "400",
                          lineHeight: "30px",
                        }}
                      >
                        {file.documents_count} Files
                      </p>
                      {!file.is_shared && file.documents_count > 0 && (
                        <div className="share-icon">
                          <img
                            // className="share-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedId(file.id);
                              setTimeout(() => {
                                changeTitle.mutate();
                              }, 150);
                            }}
                            src={Share}
                            alt="Share"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default PropertyFiles;
