// import React, { useEffect, useState } from "react";
// import "./Styles/InitialProperty.scss";
// import toplogo from "./Assests/toplogo.svg";
// import bottomLogo from "./Assests/bottomLogo.svg";
// import PropertyFolder from "./PropertyFolder";

// import logo from "./Assests/logo.svg";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// const InitialSetup = () => {
//   const user = useSelector((state) => state.counter.loginuserdata);
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log(user.is_release, "qwqwqwqwq");
//     if (user.is_release) {
//       navigate("/");
//     }
//   }, [user]);
//   return (
//     <>
//       {/* {!user.is_release && ( */}
//       <div>
//         <button
//           className="logOutButton"
//           onClick={() => {
//             localStorage.clear();
//             navigate("/login");
//           }}
//         >
//           Logout
//         </button>
//         <img
//           style={{ position: "absolute", top: "0", left: "0" }}
//           src={toplogo}
//           alt="top logo"
//         />
//         <img
//           style={{ position: "absolute", bottom: "0", right: "0" }}
//           src={bottomLogo}
//           alt="bottom logo "
//         />

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <div className="inital-card">
//             <PropertyFolder />
//           </div>
//         </div>
//       </div>
//       {/* )} */}
//     </>
//   );
// };

// export default InitialSetup;

import React, { useEffect, useState } from "react";
import "./Styles/InitialProperty.scss";
import toplogo from "./Assests/toplogo.svg";
import bottomLogo from "./Assests/bottomLogo.svg";
import PropertyFolder from "./PropertyFolder";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckRelease } from "../../../Api/PropertiesQuery";
import {
  loginuserdata,
  setAllowFullPortalAccess,
  token,
} from "../../../redux/reducer/counterslice";

const InitialSetup = () => {
  const user = useSelector((state) => state.counter.loginuserdata);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (user.is_release) {
  //     navigate("/");
  //   }
  // }, [user]);
  const dispatch = useDispatch();
  const location = useLocation();

  const { data, isLoading, isError, error, refetch } = useCheckRelease();

  useEffect(() => {
    console.log(data?.data, "gvcfgdxcvjhbknlkm");
    // refetch();
    if (data !== undefined) {
      if (data) {
        dispatch(setAllowFullPortalAccess(data?.data));

        if (data.data) {
          navigate("/");
        }
      }
    }
  }, [data, location]);
  return (
    <>
      {/* {!user.is_release && ( */}
      <div>
        <button
          className="logOutButton"
          onClick={() => {
            dispatch(token(""));
            dispatch(loginuserdata([]));
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }}
        >
          Logout
        </button>
        <img
          style={{ position: "absolute", top: "0", left: "0" }}
          src={toplogo}
          alt="top logo"
        />
        <img
          style={{ position: "absolute", bottom: "0", right: "0" }}
          src={bottomLogo}
          alt="bottom logo "
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="inital-card">
            <PropertyFolder />
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default InitialSetup;
