import Search from "../../../MainComponent/fields/search";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import "../../StyleSheet/compliance/compliancetable.scss";
import { createColumnHelper } from "@tanstack/react-table";
import { useState, useEffect, useMemo } from "react";
import download from "../../../Assets/Common/download.svg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useFetchComplianceStatscount } from "../../../Api/ComplianceQuery";
import { addLeadingZero } from "../../../Utils/Utils";
import { setPropertyDetailsCrumbs } from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";
export default function ComplianceTable(props) {
  const {
    count,
    setCount,
    pageindex,
    setPageIndex,
    setPageSize,
    pagesize,
    setsearch,
    search,
  } = props;

  const { complianceList, setComplianceList, tableData } = props;
  console.log(complianceList, "complianceList");
  const [chartData, setChartData] = useState([0, 0, 0]);
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const text = ["Not Due", "Due", "Overdue"];
  const columnHelper = createColumnHelper();
  const [Generate, setGenerate] = useState("");
  const [totalCompliance, setTotalCompliance] = useState(0);
  const clientid = useSelector((state) => state.counter.clientid);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [popup, setpopup] = useState(false);
  const dispatch = useDispatch();
  // const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const navigate = useNavigate();
  const landlordId = useSelector((state) => state.counter.landlordId);

  const countdata = useFetchComplianceStatscount({
    params: {
      client: clientid,
      doc_id: complianceList.complianceSelected,
      landlord: landlordId?.length > 0 ? landlordId : null,
    },
  });
  useEffect(() => {
    if (countdata?.data?.data != undefined) {
      setChartData([
        countdata?.data?.data?.document_count[0]?.not_due_count,
        countdata?.data?.data?.document_count[0]?.due_count,
        countdata?.data?.data?.document_count[0]?.overdue_count,
      ]);
      setTotalCompliance(
        countdata?.data?.data?.document_count[0]?.not_due_count +
          countdata?.data?.data?.document_count[0]?.due_count +
          countdata?.data?.data?.document_count[0]?.overdue_count
      );
    }
  }, [
    countdata.data,
    complianceList.complianceSelected,
    complianceList.complianceFilter,
  ]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("expiry_date", {
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            Next Due Date
          </span>
        ),
        cell: (info) => (
          <p
            style={{
              wordBreak: "break-all",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </p>
        ),
      }),

      columnHelper.accessor("status", {
        cell: (info) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <div
              className="status_button"
              style={{
                textTransform: "capitalize",
                height: "15px",
                width: "10px",
                // padding: "8px 10px",
                borderRadius: "2px",
                backgroundColor:
                  info.getValue() === "due"
                    ? "#FFE9B5"
                    : info.getValue() === "not_due"
                    ? "#CEFFE1"
                    : "#FFD3CF",
              }}
            ></div>
            <p
              style={{
                textTransform: "capitalize",
                // fontSize: "max(12px, 0.8vw)",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
            >
              {info.getValue() === "not_due" ? "Not Due" : info.getValue()}
            </p>
          </div>
        ),
      }),

      columnHelper.accessor("property_address", {
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>Address</span>
        ),
        cell: (info) => {
          const data = info.row.original?.property_address;
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(1));
                // setSelectedPropertyId(info.row.original?.property);
                // setpopup(true);
                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Compliance",
                    fromUrl: "/compliance",
                  })
                );
                navigate(
                  `/property/propertyDetails/${info.row.original?.property}`
                );
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "Document",
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          if (data?.file) {
            return (
              <p
                style={{
                  color: "#817EFF",
                  cursor: "pointer",
                  fontSize: "clamp(10px, 0.9vw, 27px)",
                  width: "max-content",
                }}
                onClick={() => {
                  window.open(data.file?.[0], "_blank");
                }}
              >
                Download&nbsp;&nbsp;
                <img
                  src={download}
                  width={"15px"}
                  height={"15px"}
                  alt="document image"
                />
              </p>
            );
          }
        },
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>Document</span>
        ),
      }),
    ],
    []
  );
  const handleFilerChange = (value) => {
    setComplianceList((prev) => ({
      ...prev,
      complianceFilter: value,
    }));
  };
  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }
  return (
    <div className="compliance_table">
      <div className="header">
        <div
          className="text_container"
          style={{ justifyContent: "space-between" }}
        >
          <div className="complaintextheading">
            <img
              src={
                tableData?.[0]?.icon
                  ? tableData?.[0]?.icon
                  : complianceList.selectedIcon
              }
              alt="img"
            ></img>
            <h1
              style={{
                textTransform: "capitalize",
                // fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {complianceList.selectedPayload?.name}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <GenerateReport
              style={{ fontSize: "14px" }}
              setGenerate={setGenerate}
            /> */}
          </div>
        </div>
        <div className="progress_wrapper row">
          {chartData.map((item, index) => {
            // Calculate the percentage width based on the total number of properties
            const percentage =
              totalCompliance > 0 ? (item / totalCompliance) * 100 : 0;

            return (
              <div className="col-4" key={index}>
                <div className="progress_main">
                  <div className="main_text">
                    <span
                      className="text"
                      style={{ fontSize: "clamp(14px, 1.2vw, 33px)" }}
                    >
                      {text[index % text.length]}
                    </span>
                    <span
                      style={{
                        color: colors[index % colors.length],
                        fontSize: "18px",
                      }}
                    >
                      {addLeadingZero(item)}
                    </span>
                  </div>

                  <div className="progress_bar">
                    <div
                      className="progress progress-sm"
                      style={{
                        height: "11px",
                        background:
                          index === 0
                            ? "rgb(219,248,231)"
                            : index === 1
                            ? "rgb(255,240,204)"
                            : index === 2
                            ? "rgb(255,216,212)"
                            : "",
                      }}
                    >
                      <div
                        className="progress-bar"
                        style={{
                          width: `${percentage}%`, // Use the calculated percentage
                          borderRadius: "1.25rem",
                          backgroundColor: colors[index % colors.length],
                        }}
                        aria-valuenow={percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="divider"></div>
      <div className="main_tablecontent">
        <div className="com_table_filter">
          <div className="com_button_container" style={{ display: "flex" }}>
            <button
              className={`${
                complianceList.complianceFilter === "all" && "active"
              }`}
              onClick={() => {
                handleFilerChange("all");
              }}
            >
              All
              <span>
                {addLeadingZero(chartData[0] + chartData[1] + chartData[2])}
              </span>
            </button>
            <button
              className={`${
                complianceList.complianceFilter === "not_due" && "active"
              }`}
              onClick={() => {
                handleFilerChange("not_due");
              }}
            >
              Not Due
              <span style={{ fontSize: "16px" }}>
                {addLeadingZero(chartData[0])}
              </span>
            </button>{" "}
            <button
              className={`${
                complianceList.complianceFilter === "due" && "active"
              }`}
              onClick={() => {
                handleFilerChange("due");
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              Due
              <span style={{ fontSize: "16px" }}>
                {addLeadingZero(chartData[1])}
              </span>
            </button>{" "}
            <button
              className={`${
                complianceList.complianceFilter === "overdue" && "active"
              }`}
              onClick={() => {
                handleFilerChange("overdue");
              }}
            >
              Overdue
              <span>{addLeadingZero(chartData[2])}</span>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flex: "1",
              justifyContent: "right",
            }}
          >
            <Search
              search={search}
              setsearch={setsearch}
              style={{ width: "100%" }}
              setPageIndex={setPageIndex}
              placeholder={"address"}
            />
          </div>
        </div>

        <div
          className="table_compliance"
          style={{ height: "calc(100% - 60px" }}
        >
          {}
          <TableComponent
            data={tableData}
            columns={columns}
            count={count}
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      </div>
      {/* {fetchProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setpopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={popup}
        />
      )} */}
    </div>
  );
}
