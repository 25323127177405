import "../../StyleSheet/clientmoney/accounts.scss";
import ReserveAccount from "./accounts/reservedaccount";
import ClientAccount from "./accounts/clientaccount";
import DepositAccount from "./accounts/depositeaccount";
import { useDispatch, useSelector } from "react-redux";
import { setClientMoneyAccountStatus } from "../../../redux/reducer/counterslice";
import { useEffect } from "react";
export default function Accounts() {
  const toggle = useSelector((state) => state.counter.clientMoneyAccountStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(toggle, "laks;fdkkasdka;sdkasfasf");
  }, [toggle]);

  return (
    <div className="accounts_main">
      <div className="header" style={{ padding: "14px 0" }}>
        <button
          className={toggle === "Reserve Account" ? "active" : "inactive"}
          onClick={(e) =>
            dispatch(setClientMoneyAccountStatus(e.target.innerText))
          }
        >
          Reserve Account
        </button>
        <button
          className={toggle === "Client Account" ? "active" : "inactive"}
          onClick={(e) =>
            dispatch(setClientMoneyAccountStatus(e.target.innerText))
          }
        >
          Client Account
        </button>
        <button
          className={toggle === "Deposit Account" ? "active" : "inactive"}
          onClick={(e) =>
            dispatch(setClientMoneyAccountStatus(e.target.innerText))
          }
        >
          Deposit Account
        </button>
      </div>
      {toggle === "Reserve Account" ? (
        <div className="content_wrapper" style={{ borderRadius: "25px" }}>
          <ReserveAccount />
        </div>
      ) : toggle === "Client Account" ? (
        <div
          className="content_wrapper"
          style={{ borderRadius: "25px", height: "82vh" }}
        >
          <ClientAccount />
        </div>
      ) : (
        <div className="content_wrapper" style={{ borderRadius: "25px" }}>
          <DepositAccount />
        </div>
      )}

      {/* <div className="content_wrapper" style={{ borderRadius: "25px" }}>
        {toggle === "Reserve Account" ? (
          <div style={{ height: "100%" }}>
            <ReserveAccount />
          </div>
        ) : toggle === "Client Account" ? (
          <ClientAccount />
        ) : (
          <div style={{ height: "100%" }}>
            <DepositAccount />
          </div>
        )}
      </div> */}
    </div>
  );
}
