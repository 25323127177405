import { useEffect, useState } from "react";
import "../../StyleSheet/compliance/compliance.scss";
import ComplianceList from "./compliancelist";
import ComplianceTable from "./compliancetable";
import { useFetchComplianceSpecId } from "../../../Api/ComplianceQuery";
import { useSelector } from "react-redux";
import DefaultLoader from "../../GlobalComponents/DefaultLoader";
export default function Compliance() {
  const [tableData, setTableData] = useState([]);
  const [complianceList, setComplianceList] = useState({
    complianceSelected: "",
    selectedPayload: {},
    complianceType: "property",
    complianceFilter: "all",
    selectedIcon: "",
  });
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [search, setsearch] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const clientid = useSelector((state) => state.counter.clientid);
  const landlordId = useSelector((state) => state.counter.landlordId);

  const fetchCompliance = useFetchComplianceSpecId({
    id: complianceList.complianceSelected,
    type: complianceList.complianceType,
    status: complianceList.complianceFilter,
    client: clientid,
    landlord: landlordId?.length > 0 ? landlordId : null,
    page: pageindex,
    page_size: pagesize,
    search: search,
    whenToLoad: firstLoad,
  });

  useEffect(() => {
    if (fetchCompliance.data?.data?.results) {
      setTableData(fetchCompliance.data.data.results);
      setCount(fetchCompliance.data.data.count);
      console.log(fetchCompliance.data.data.count, "laksjdlfalsdfk");
    }
  }, [
    complianceList.complianceSelected,
    fetchCompliance.data,
    complianceList.complianceFilter,
    pageindex,
    pagesize,
  ]);

  // if (fetchCompliance.isPending) {
  //   return <DefaultLoader />; // Or some loading component
  // }
  return (
    <div className="compliance_main">
      <div className="compliance_content_wrapper">
        <div
          className="comcomlist"
          // style={{ width: "490px", height: "87%", borderRadius: "8px" }}
        >
          <ComplianceList
            setComplianceList={setComplianceList}
            complianceList={complianceList}
            setFirstLoad={setFirstLoad}
            firstLoad={firstLoad}
            setsearch={setsearch}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
        <div className="comcomtable">
          <ComplianceTable
            tableData={tableData ? tableData : []}
            complianceList={complianceList}
            setComplianceList={setComplianceList}
            count={count}
            pageindex={pageindex}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            pagesize={pagesize}
            setsearch={setsearch}
            search={search}
          />
        </div>
      </div>
    </div>
  );
}
