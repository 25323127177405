import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import FilterPanel from "../../../../MainComponent/FilterPanel";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useArrears, useFetchArrearsSelected } from "../../../../Api/homeQuery";
import { useDispatch, useSelector } from "react-redux";
import { formatName, formatPounds } from "../../../../Utils/Utils";
import Search from "../../../../MainComponent/fields/search";
import { useFetchPropertiesCompliance } from "../../../../Api/PropertiesQuery";
import Propertydetails from "../../Property/popups/propertydetails";
import {
  setContactDetailsFilter,
  setPropertyDetailsCrumbs,
  setPropertyDetailsFilter,
} from "../../../../redux/reducer/counterslice";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
export default function ArrearsPopup(props) {
  const { activeTab, setActiveTab, dataFromData } = props;
  const columnHelper = createColumnHelper();
  const [paramsPayload, setParamsPayload] = useState();
  const [TableData, setTableData] = useState([]);
  const clientId = useSelector((state) => state.counter.clientid);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [search, setSearch] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [popup, setpopup] = useState(false);
  const dispatch = useDispatch();
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const landlordId = useSelector((state) => state.counter.landlordId);
  const fetchArrears = useFetchArrearsSelected({
    date_range: paramsPayload,
    client: clientId,
    landlord: landlordId?.length > 0 ? landlordId : null,
    page: pageindex,
    page_size: pagesize,
    search: search,
  });
  const navigate = useNavigate();
  useEffect(() => {
    console.log(search, "lkjihuygtfvgbhjk");
  }, [search]);
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "property_address",
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>
            Property Address
          </span>
        ),
        enableSorting: false,
        cell: (info) => {
          const data = info?.row?.original;

          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(1));
                // setSelectedPropertyId(data?.property_id);
                // setpopup(true);
                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Home",
                    fromUrl: "/",
                  })
                );
                navigate(`/property/propertyDetails/${data?.property_id}`);
              }}
            >
              {data?.property_name}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "tenantName",
        header: () => (
          <span
            style={{
              fontSize: "clamp(15px, 1.1vw, 27px)",
            }}
          >
            Tenant Name
          </span>
        ),
        enableSorting: false,
        cell: ({ row }) => {
          const data = row?.original;
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                dispatch(setPropertyDetailsFilter("1"));
                dispatch(setContactDetailsFilter(2));
                setSelectedPropertyId(row?.original?.property_id);
                setpopup(true);
              }}
            >
              {formatName(
                data?.tenant_title,
                data?.tenant_first_name,
                data?.tenant_last_name
              )}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        id: "amount",
        sortingFn: "basic",
        cell: (info) => (
          <span>
            <span
              style={{
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
            >
              £{formatPounds(info.row.original.total_arrears)}
            </span>
          </span>
        ),
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Amount</span>
        ),
      }),
    ],
    []
  );
  const setParams = () => {
    if (activeTab === "0 - 7 Days") {
      setParamsPayload("0_7_days");
    } else if (activeTab === "8 - 14 Days") {
      setParamsPayload("8_14_days");
    } else if (activeTab === "15 - 30 Days") {
      setParamsPayload("15_30_days");
    } else if (activeTab === "31+ Days") {
      setParamsPayload("31_plus_days");
    } else if (activeTab === "All") {
      setParamsPayload("all");
    }
  };
  useEffect(() => {
    setParams();
  }, [activeTab]);
  useEffect(() => {
    if (fetchArrears.data?.data) {
      setTableData(fetchArrears.data?.data?.data);
      setCount(fetchArrears.data?.data?.count);
    }
  }, [fetchArrears.data, paramsPayload, pageindex, pagesize]);

  const getarrears = useArrears({
    params: { id: clientId },
    loading: false,
  });
  useEffect(() => {
    if (getarrears.data != undefined) {
      if (activeTab === "0 - 7 Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_0_7_days);
      } else if (activeTab === "8 - 14 Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_8_14_days);

        setParamsPayload("8_14_days");
      } else if (activeTab === "15 - 30 Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_15_30_days);

        setParamsPayload("15_30_days");
      } else if (activeTab === "31+ Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_31_plus_days);

        setParamsPayload("31_plus_days");
      } else if (activeTab === "All") {
        setTotalAmount(
          getarrears.data.data[0]?.charges_31_plus_days +
            getarrears.data.data[0]?.charges_15_30_days +
            getarrears.data.data[0]?.charges_8_14_days +
            getarrears.data.data[0]?.charges_0_7_days
        );
      }
    }
  }, [getarrears.data, activeTab]);

  useEffect(() => {
    console.log(fetchArrears.isFetching, "kajslejjasldjflas");
  }, [fetchArrears.isFetching]);

  return (
    <div
      className={`popup_main_wrapper ${
        props.popupstate === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`main_popup ${
          props.popupstate === true ? "popup-open" : "popup-close"
        } `}
        style={{
          // width: "1542px",
          // height: "800",
          // marginRight: "20px",
          position: "relative",
          // display: "flex",
          // justifyContent: "space-between",
        }}
      >
        <div
          className="com_popup_header"
          style={{
            height: "10%",
            // marginBottom: "5%",
          }}
        >
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "space-between",
              // padding: "max(10px, 0.8vw)",
              // border: "1px solid black",
            }}
          >
            <h1
              style={{
                fontSize: "clamp(16px, 1.4vw, 53px)",
              }}
            >
              Tenancy Arrears
              {/* <span
                style={{
                  color:
                    {
                      "0 - 7 Days": "rgb(0, 0, 71)",
                      "8 - 14 Days": "rgb(77,222,135)",
                      "15 - 30 Days": "rgb(79,195,244)",
                      "31+ Days": "rgb(129,126,255)",
                    }[props.activeTab] || "inherit",
                }}
              >
                {props.activeTab}
              </span> */}
            </h1>
            <div className="search_close_com" style={{ width: "auto" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "flex-start",
                  // width: "90%",
                  gap: "max(10px, 0.8vw)",
                  // marginRight: "3%",
                }}
              >
                {/* <div
                  className="search_cov"
                  style={{
                    width: "70%",
                  }}
                >
                  <Search
                    setsearch={setSearch}
                    style={{ width: "100%", minWidth: "180px" }}
                    setPageIndex={setPageIndex}
                  />
                </div> */}

                <div
                  className="totalArrears"
                  style={{
                    background: "rgba(217, 217, 217, 0.2)",
                    marginBottom: "0",
                  }}
                >
                  <span>Total Arrears</span>
                  <span>
                    {fetchArrears.isFetching
                      ? "Loading"
                      : `£${formatPounds(
                          search.length === 0
                            ? totalAmount
                            : fetchArrears.data?.data.total_arrears_sum
                        )}`}
                  </span>
                </div>
                <div className="search_cov">
                  <Search
                    setsearch={setSearch}
                    style={{ width: "250px" }}
                    setPageIndex={setPageIndex}
                    placeholder={"property address"}
                  />
                </div>
              </div>
              <div
                className="close_button_wrapper"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  className="close"
                  alt="close"
                  onClick={() => props.setPopupstate(false)}
                  src={closeSolidIcon}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "0 0 20px 0",
            background: "rgb(247,249,251)",
            borderRadius: "15px",
          }}
        >
          <FilterPanel
            tabs={[
              "All",
              "0 - 7 Days",
              "8 - 14 Days",
              "15 - 30 Days",
              "31+ Days",
            ]}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
        <div className="arrearsConfig">
          {fetchArrears.isFetching ? (
            <div className="skelitonDiv">
              <Skeleton active style={{ height: "98px" }} rows={1} />
            </div>
          ) : (
            <TableComponent
              columns={columns}
              data={TableData}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}

              //   handleRowClick={handleRowClick}
            />
          )}
        </div>
      </div>
      {fetchProperties.data?.data &&
        (popup ? (
          <Propertydetails
            rowvalue={fetchProperties.data?.data}
            setpopup={setpopup}
            selectedData={"selectedData"}
            fromContact={true}
            setSelectedLandLordId={""}
            propertyPopup={popup}
          />
        ) : (
          ""
        ))}
    </div>
  );
}
