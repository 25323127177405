import React, { useEffect, useState } from "react";
import logo from "./Assests/logo.svg";
import info_initial from "../../../Assets/Common/question_mark.svg";
import Search from "../../../MainComponent/fields/search";
import { CustomButton } from "../../GlobalComponents/CustomButton/CustomButton";
import PropertyFiles from "./PropertyFiles";
import "./Styles/InitialProperty.scss";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  setPropertyFileFilter,
  setSelectedButton,
} from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";
import InfoQuestionMark from "./InfoQuestionMark";

const PropertyFolder = () => {
  // const [selectedButton, setSelectedButton] = useState("Property");
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 600);
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const navigate = useNavigate();
  const [popupState, setPopupState] = useState(false);

  const propertyFileFilters = useSelector(
    (state) => state.counter.propertyFileFilter
  );

  useEffect(() => {
    console.log(popupState, " sedrftgyhuijkhuygtfrgh");
  }, []);
  return (
    <>
      <div
        className="card-contents-onboarding"
        style={{ position: "relative" }}
      >
        <div>
          <div className="card-logo">
            <img src={logo} alt="logo" />
            <img
              src={info_initial}
              className="info-question-mark"
              alt="logo"
              style={{
                width: "22px",
                position: "absolute",
                right: "0",
                top: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                setPopupState(true);
                console.log(popupState);
              }}
              title="Info"
            />
          </div>
          <div className="init-card-top">
            <div className="top-button">
              {["Property", "Shared"].map((item, index) => (
                <div Key={index}>
                  <CustomButton
                    title={item === "Property" ? "To do" : "Submitted"}
                    active={item === propertyFileFilters}
                    type="submit"
                    handleClick={() => {
                      // setSelectedButton(item);
                      dispatch(setSelectedButton(item));
                      dispatch(setPropertyFileFilter(item));
                    }}
                    styles={{
                      padding: "9px 35px",
                      fontSize: "15.2px",
                      borderRadius: "10px",
                      color: "black",
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="card-search" style={{ width: "auto" }}>
              <Search
                setsearch={setSearch}
                setPageIndex={setPageIndex}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div className="card-body-content-onboarding">
          <div className="property-content" style={{ height: "100%" }}>
            <PropertyFiles
              // filter={selectedButton}
              searchString={searchValue}
            />
          </div>
        </div>
      </div>
      {popupState && (
        <InfoQuestionMark
          // setActiveTab={setActiveTab}
          // activeTab={activeTab}
          setPopupState={setPopupState}
          popuptext={""}
          // dataFromData={Data}
          popupState={popupState}
        />
      )}
      {/* <InfoQuestionMark
        setPopupState={setPopupState}
        popuptext={""}
        popupState={popupState}
      /> */}
    </>
  );
};

export default PropertyFolder;
