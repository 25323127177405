import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useFetchWorkOrderBasedStatus } from "../../../../Api/homeQuery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatName } from "../../../../Utils/Utils";
import { setPropertyDetailsCrumbs } from "../../../../redux/reducer/counterslice";
import { useFetchPropertiesCompliance } from "../../../../Api/PropertiesQuery";
import Propertydetails from "../../Property/popups/propertydetails";
import Search from "../../../../MainComponent/fields/search";
import { useNavigate } from "react-router-dom";
const MaintenancePopup = (props) => {
  const columnHelper = createColumnHelper();

  const { maintenanceStage, setPopup, titleColor } = props;
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [count, setCount] = useState(0);
  const clientid = useSelector((state) => state.counter.clientid);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const navigate = useNavigate();
  const [propertyPopup, setPropertyPopup] = useState(false);
  const [search, setSearch] = useState("");
  const landlordId = useSelector((state) => state.counter.landlordId);
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);

  const dispatch = useDispatch();

  const fetchArrears = useFetchWorkOrderBasedStatus({
    params: {
      page: pageindex,
      page_size: pagesize,
      status: maintenanceStage,
      client: clientid,
      search: search,
      type: props.type === "Compliance" ? true : false,
      landlord: landlordId?.length > 0 ? landlordId : null,
    },
  });
  const [TableData, setTableData] = useState([]);
  function formatAddress(addressParts) {
    return addressParts.filter((part) => part).join(", ");
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "date_reported",
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>
            Date Reported
          </span>
        ),
        sortingFn: "basic",

        cell: (info) => {
          // console.log(info.row.original.created_date, "lakjsfljalsdjljf");
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
            >
              {dayjs(info.row.original.created_date).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        id: "Address",
        // sortingFn: "basic",
        enableSorting: false,

        cell: (info) => {
          const data = info.row.original;
          console.log(data, "maintenancepopupData");
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(1));
                // setSelectedPropertyId(data?.id);
                // setPropertyPopup(true);

                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Home",
                    fromUrl: "/",
                  })
                );
                navigate(`/property/propertyDetails/${data?.property?.id}`);
              }}
            >
              {formatAddress([
                data?.property?.name + " " + data?.property?.street,
                data?.property?.city,
                data?.property?.postcode,
              ])}
            </span>
          );
        },
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Address</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "reportedBy",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original.property_tenant;

          return (
            <span
              style={{
                maxWidth: "400px",
                display: "flex",
                textTransform: "capitalize",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
            >
              {data?.first_name || data?.last_name
                ? formatName(data?.title, data?.first_name, data?.last_name)
                : "Property Manager"}
            </span>
          );
        },
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>
            Reported By
          </span>
        ),
      }),

      columnHelper.accessor((row) => row, {
        id: "Summary",
        enableSorting: false,
        cell: (info) => (
          <span
            style={{
              maxWidth: "400px",
              display: "flex",
              textTransform: "capitalize",
              lineHeight: "25px",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            {info.row.original?.issue_list?.title} ,{" "}
            {info.row.original?.issue_type?.name}
          </span>
        ),
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Summary</span>
        ),
      }),
      columnHelper.accessor("description", {
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>
            Description
          </span>
        ),
        enableSorting: false,
        cell: (info) => (
          <span
            style={{
              wordBreak: "break-all",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            {info.getValue()}
          </span>
        ),
      }),
    ],
    []
  );
  useEffect(() => {
    setPageIndex(1);
    setPageSize(20);
  }, [search]);

  useEffect(() => {
    if (fetchArrears.data?.data) {
      setTableData(fetchArrears.data?.data?.results);
      setCount(fetchArrears.data?.data?.count);
    }
  }, [fetchArrears.data]);
  useEffect(() => {
    console.log(fetchArrears.isFetching, "wqeqdsdsdsda");
  }, [fetchArrears.isFetching]);

  return (
    <>
      <div
        className={`popup_main_wrapper ${
          props.popupstate === true ? "popup-open" : "popup-close"
        } `}
      >
        <div
          className={`main_popup ${
            props.popupstate === true ? "popup-open" : "popup-close"
          } `}
          style={{
            // width: "1872px",
            // height: "800",
            // marginRight: "20px",
            position: "relative",
            // left: "1.5%",
            // top: "10%",
          }}
        >
          <div
            className="com_popup_header"
            style={{
              // height: "13%",
              alignItems: "center",
            }}
          >
            <div
              className="content"
              style={{
                // padding: "0 max(14px, 1.3vw)",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  color: titleColor,
                  // marginBottom: "40px",
                  fontSize: "max(18px, 2.2vw)",
                }}
              >
                {maintenanceStage}
              </h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "7px 10px",
                  gap: "max(10px, 0.8vw)",
                }}
              >
                <Search
                  setsearch={setSearch}
                  style={{ width: "100%" }}
                  setPageIndex={setPageIndex}
                />
                <div
                  className="close_button_wrapper"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="close"
                    alt="close"
                    onClick={() => {
                      setPopup(false);
                    }}
                    src={closeSolidIcon}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="maintenanceConfig">
            {/* {fetchArrears.isFetching ? (
              <div className="skelitonDiv">
                <Skeleton active style={{ height: "98px" }} rows={1} />
              </div>
            ) : ( */}
            <TableComponent
              columns={columns}
              data={TableData}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
              skeLetonLoading={fetchArrears?.isFetching}
            />
            {/* )} */}
          </div>
        </div>
        {fetchProperties.data?.data && propertyPopup && (
          <Propertydetails
            rowvalue={fetchProperties.data?.data}
            setpopup={setPopup}
            selectedData={"selectedData"}
            fromContact={true}
            setSelectedLandLordId={() => {}}
            propertyPopup={propertyPopup}
          />
        )}
      </div>
    </>
  );
};

export default MaintenancePopup;
