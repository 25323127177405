import React, { useMemo } from "react";
import "../PropertyDetails-panel.scss";
import { createColumnHelper } from "@tanstack/react-table";
import { formatPounds } from "../../../../Utils/Utils";

import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import dayjs from "dayjs";
import { Skeleton } from "antd";

function PropertyDetailsTenancy({ dataFromParent }) {
  const columnHelper = createColumnHelper();

  const tenancyColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Tenant",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.tenant?.contact_detail?.[0];
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {data?.title} {data?.first_name} {data?.last_name}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Start Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.start_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "End Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.end_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Rent",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.rent;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Status",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.status;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                padding: "5px 10px",
                borderRadius: "5px",
                background: data === "Periodic" ? "#5EFF9F" : "",
              }}
            >
              {data}
            </span>
          );
        },
      }),
    ],
    []
  );
  return (
    <div className="propertyDetailsTransactionsWrapper">
      {dataFromParent.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="headingWrapper">
            <h2 className="transactionsHeading">Tenancies</h2>
          </div>
          <TableComponent
            data={dataFromParent.tenancies?.[0]?.tenancies || []}
            columns={tenancyColumn}
            count={0}
          />
        </>
      )}
    </div>
  );
}

export default PropertyDetailsTenancy;
