import React, { useEffect, useMemo, useState } from "react";
import "../PropertyDetails-panel.scss";
import { createColumnHelper } from "@tanstack/react-table";
import { formatAddress, formatPounds } from "../../../../Utils/Utils";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useFetchPropertyTransaction } from "../../../../Api/PropertiesQuery";
// import Search from "../../../../MainComponent/fields/search";
import GeneratePeriodicStatement from "../../Contacts/Popup/generatePeriodicStatement";
import Money from "../../../../Assets/Common/Money.svg";
import { Skeleton } from "antd";

function PropertyDetailsTransactions({ dataFromParent }) {
  const columnHelper = createColumnHelper();
  const { propertyId } = useParams();
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [search, setsearch] = useState("");
  const [generatePeriodicStatement, setGeneratePeriodicStatement] =
    useState(false);

  const fetchPropertyPropertyTransactionDetails = useFetchPropertyTransaction({
    property: propertyId,
    page: pageindex,
    page_size: pagesize,
    search: search,
    type: "client",
  });

  const transactionColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Charge Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Pay In",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Pay Out",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.debit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Balance",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.balance;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
    ],
    []
  );

  useEffect(() => {
    if (fetchPropertyPropertyTransactionDetails.data?.data?.count)
      setCount(fetchPropertyPropertyTransactionDetails.data?.data?.count);
  }, [fetchPropertyPropertyTransactionDetails.data]);
  const balance =
    fetchPropertyPropertyTransactionDetails.data?.data?.results?.[0]?.balance ||
    0;
  return (
    <div className="propertyDetailsTransactionsWrapper">
      {fetchPropertyPropertyTransactionDetails.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="headingWrapper">
            <h2 className="transactionsHeading">Transactions</h2>
            <div className="account-balance-container">
              <div className="account-balance-card">
                <div className="account-balance-wrapper">
                  <img src={Money} alt="wallet" className="balance-icon" />
                  <span className="balance-title">Account Balance</span>
                </div>
                <span
                  className={`balance-amount ${
                    balance > 0
                      ? "positive"
                      : balance < 0
                      ? "negative"
                      : "neutral"
                  }`}
                >
                  £{formatPounds(balance)}
                </span>
              </div>
              <div className="statement-button-container">
                <button
                  className="generate-statement-button"
                  onClick={() => setGeneratePeriodicStatement(true)}
                >
                  Generate Statement
                </button>
              </div>
            </div>
          </div>
          <TableComponent
            data={
              fetchPropertyPropertyTransactionDetails.data?.data?.results || []
            }
            columns={transactionColumn}
            count={count}
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
          {generatePeriodicStatement && (
            <GeneratePeriodicStatement
              isOpen={generatePeriodicStatement}
              setIsOpen={setGeneratePeriodicStatement}
              type={"property"}
              propertyName={formatAddress([
                dataFromParent.name + " " + dataFromParent.street,
                dataFromParent.city,
                dataFromParent.postcode,
              ])}
              propertyId={dataFromParent?.id}
              propertyData={dataFromParent}
            />
          )}
        </>
      )}
    </div>
  );
}

export default PropertyDetailsTransactions;
