import React, { useEffect, useMemo, useState } from "react";
import "./PropertyDetails-panel.scss";
import { createColumnHelper } from "@tanstack/react-table";
import { formatPounds } from "../../../../Utils/Utils";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import GeneratePeriodicStatement from "../Popup/generatePeriodicStatement";
import { useFetchLandLordTransactions } from "../../../../Api/ContactsQuery";
import download from "../../../../Assets/Common/download.svg";
import { Skeleton } from "antd";

function PropertyDetailsTransactions({ dataFromParent }) {
  const columnHelper = createColumnHelper();
  const { propertyId } = useParams();
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [search, setsearch] = useState("");
  const [generatePeriodicStatement, setGeneratePeriodicStatement] =
    useState(false);

  const fetchPropertyPropertyTransactionDetails = useFetchLandLordTransactions({
    landlord: propertyId,
    page: pageindex,
    page_size: pagesize,
    search: search,
  });

  const transactionColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Amount",
        sortingFn: "basic",

        cell: (info) => {
          const debit = info.row.original?.debit;
          const credit = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                color: credit > 0 ? "green" : "red",
              }}
            >
              {`£${formatPounds(credit || debit)}`}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Download",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.report;

          return (
            data && (
              <p
                style={{
                  color: "#817EFF",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                onClick={() => {
                  window.open(data, "_blank");
                }}
              >
                Download&nbsp;&nbsp;
                <img
                  src={download}
                  width={"15px"}
                  height={"15px"}
                  alt="document image"
                />
              </p>
            )
          );
        },
      }),
    ],
    []
  );

  useEffect(() => {
    if (fetchPropertyPropertyTransactionDetails.data?.data?.count)
      setCount(fetchPropertyPropertyTransactionDetails.data?.data?.count);
  }, [fetchPropertyPropertyTransactionDetails.data]);

  return (
    <div className="propertyDetailsTransactionsWrapper">
      {fetchPropertyPropertyTransactionDetails.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="headingWrapper">
            <h2 className="transactionsHeading">Transactions</h2>
            <div className="account-balance-container">
              <div className="statement-button-container">
                <button
                  className="generate-statement-button"
                  onClick={() => setGeneratePeriodicStatement(true)}
                >
                  Generate Statement
                </button>
              </div>
            </div>
          </div>
          <TableComponent
            data={
              fetchPropertyPropertyTransactionDetails.data?.data?.results || []
            }
            columns={transactionColumn}
            count={count}
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </>
      )}
      {generatePeriodicStatement && (
        <GeneratePeriodicStatement
          isOpen={generatePeriodicStatement}
          setIsOpen={setGeneratePeriodicStatement}
          landLordId={propertyId}
          type={"landlord"}
        />
      )}
    </div>
  );
}

export default PropertyDetailsTransactions;
