import { useSelector } from "react-redux";
import closeSolidIcon from "../../../Assets/Common/closeSolidIcon.svg";
import { formatName } from "../../../Utils/Utils";
import "./InfoQuestionMark.scss";
export default function InfoQuestionMark(props) {
  const { setPopupState } = props;

  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const details = loginuserdata?.contact_detail?.[0];
  const company = loginuserdata?.company;
  return (
    // <div
    //   className={`popup_main_wrapper ${
    //     props.popupstate === true ? "popup-open" : "popup-close"
    //   } `}
    // >
    <div className="InfoQuesPopup">
      <div className="popupContainer">
        <div className="header">
          <div></div>
          <div className="heading">Info</div>
          <div className="col-1 close_button_wrapper">
            <img
              className="close"
              alt="close"
              onClick={() => setPopupState(false)}
              src={closeSolidIcon}
            />
          </div>
        </div>
        <div className="infoConfig">
          <h2 style={{ fontSize: "20px" }}>
            Dear{" "}
            {company
              ? company
              : formatName(
                  details?.title,
                  details?.first_name,
                  details?.last_name
                )}
            ,
          </h2>
          <p style={{ lineHeight: "30px", textAlign: "justify" }}>
            <span style={{ fontWeight: "bold" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            We are excited to welcome you to the new and improved Rent
            Management System. This platform is designed to streamline your
            rental property management experience, making it easier and more
            efficient than ever before.
          </p>
        </div>
      </div>
    </div>
  );
}
