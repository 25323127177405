// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { Outlet, Navigate } from "react-router-dom";
// import { useNavigate, useLocation } from "react-router-dom";

// const PrivateRoutes = ({ children }) => {
//   const navigate = useNavigate();
//   const token = useSelector((state) => state.counter.token);
//   const user = useSelector((state) => state.counter.loginuserdata);

//   useEffect(() => {
//     if (token === "") {
//       navigate("/login", { replace: true });
//     } else if (token !== "") {
//       if (!user.is_release) {
//         navigate("/initialSetup", { replace: true });
//       }
//     }
//   }, [token, user]);
//   if (token !== "" && user.is_release) {
//     return children;
//   }
//   // if (token == "") {
//   //   navigate("/");
//   // }
// };
// export default PrivateRoutes;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const WhiteScreen = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#FFFFFF",
      zIndex: 99999,
      pointerEvents: "none",
    }}
  />
);

// Global loading state manager
let globalLoadingState = false;
const setGlobalLoading = (state) => {
  globalLoadingState = state;
};

const shouldShowLoading = (pathname, nextPath) => {
  const criticalPaths = ["/", "/initialSetup"];
  return criticalPaths.includes(pathname) || criticalPaths.includes(nextPath);
};

export const PrivateRoutes = ({ children }) => {
  const navigate = useNavigate();
  const { token, loginuserdata: user } = useSelector((state) => state.counter);
  const { allowFullPortalAccess } = useSelector((state) => state.counter);
  const location = useLocation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const handleAuth = async () => {
      let nextPath = location.pathname;

      if (!token) {
        nextPath = "/login";
      } else if (!allowFullPortalAccess && allowFullPortalAccess !== null) {
        if (!location.pathname.match(/^\/initialSetup\/\w+\/\w+$/)) {
          nextPath = "/initialSetup";
        }
      }

      if (nextPath !== location.pathname) {
        if (shouldShowLoading(location.pathname, nextPath)) {
          setGlobalLoading(true);
          await new Promise((resolve) => setTimeout(resolve, 50));
          await navigate(nextPath, { replace: true });
          await new Promise((resolve) => setTimeout(resolve, 450));
        } else {
          await navigate(nextPath, { replace: true });
        }
      }

      setGlobalLoading(false);
    };

    if (mounted) {
      handleAuth();
    } else {
      setMounted(true);
    }

    return () => {
      if (shouldShowLoading(location.pathname, "/")) {
        setGlobalLoading(true);
      }
    };
  }, [token, navigate, allowFullPortalAccess, location, mounted]);

  return (
    <>
      {globalLoadingState && <WhiteScreen />}
      {token ? children : null}
    </>
  );
};

export const PublicRoutes = ({ children }) => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.counter);
  const { allowFullPortalAccess } = useSelector((state) => state.counter);
  const location = useLocation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const handleAuth = async () => {
      let nextPath = location.pathname;

      if (token) {
        if (allowFullPortalAccess && allowFullPortalAccess !== null) {
          nextPath = "/";
        } else if (!allowFullPortalAccess && allowFullPortalAccess !== null) {
          if (!location.pathname.match(/^\/initialSetup\/\w+\/\w+$/)) {
            nextPath = "/initialSetup";
          }
        }
      }

      if (nextPath !== location.pathname) {
        if (shouldShowLoading(location.pathname, nextPath)) {
          setGlobalLoading(true);
          await new Promise((resolve) => setTimeout(resolve, 50));
          await navigate(nextPath, { replace: true });
          await new Promise((resolve) => setTimeout(resolve, 450));
        } else {
          await navigate(nextPath, { replace: true });
        }
      }

      setGlobalLoading(false);
    };

    if (mounted) {
      handleAuth();
    } else {
      setMounted(true);
    }

    return () => {
      if (shouldShowLoading(location.pathname, "/initialSetup")) {
        setGlobalLoading(true);
      }
    };
  }, [token, navigate, allowFullPortalAccess, location, mounted]);

  return (
    <>
      {globalLoadingState && <WhiteScreen />}
      {!token ? children : null}
    </>
  );
};
