import React, { useEffect, useState } from "react";
import "./PropertyDetails-panel.scss";
import { createColumnHelper } from "@tanstack/react-table";
import { formatAddress, formatPounds } from "../../../../Utils/Utils";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchProperties } from "../../../../Api/PropertiesQuery";
import Search from "../../../../MainComponent/fields/search";
import { useDispatch } from "react-redux";
import { setPropertyDetailsCrumbs } from "../../../../redux/reducer/counterslice";
import { Skeleton } from "antd";

function PropertyDetailsProperties() {
  const columnHelper = createColumnHelper();
  const { propertyId } = useParams();
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [search, setsearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProperties = useFetchProperties({
    viewType: true,
    params: {
      landlord: propertyId,
      page: pageindex,
      page_size: pagesize,
      status: true,
    },
  });

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "address",
      cell: (info) => {
        const data = info.row.original;

        return (
          <span
            className="contentHover"
            style={{
              wordBreak: "break-all",
              fontSize: " ",
            }}
            onClick={() => {
              // dispatch(setPropertyDetailsFilter("1"));
              // dispatch(setContactDetailsFilter(1));
              // setpopup(true);
              dispatch(
                setPropertyDetailsCrumbs({
                  fromTitle: "Contacts",
                  fromUrl: `/property/landlordDetails/${propertyId}`,
                })
              );
              setTimeout(() => {
                navigate(`/property/propertyDetails/${info.row.original.id}`);
              }, 200);
            }}
          >
            {formatAddress([
              data.name + " " + data.street,
              data.city,
              data.postcode,
            ])}
          </span>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Address
        </span>
      ),
    }),

    columnHelper.accessor((row) => row, {
      id: "rooms",
      cell: (info) => {
        const data = info.row.original?.rooms?.[0];
        const value =
          parseInt(data?.bed) ||
          0 + parseInt(data?.bath) ||
          0 + parseInt(data?.living) ||
          0;
        return (
          <p
            style={{
              textTransform: "capitalize",
              position: "relative",
              left: "25%",
              fontSize: "14px",
            }}
          >
            {value || 0}
          </p>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Rooms
        </span>
      ),
    }),
    columnHelper.accessor((row) => row, {
      id: "status",
      cell: (info) => {
        console.log(info, "jkdldl");

        return (
          <p
            className={
              info.row?.original?.status === "Approved"
                ? "Approved"
                : "NotApproved"
            }
            style={{
              height: "auto",
              padding: "3px 10px",
              // width: "205px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
              // fontSize: "max(12px, 1.1vw)",
              width: "fit-content",
              fontSize: "14px",
              background:
                info.row?.original?.status === "Let in Progress"
                  ? "#CEFFE1"
                  : info.row?.original?.status === "Occupied"
                  ? "#CEFFE1"
                  : info.row?.original?.status === "Available"
                  ? "rgb(255,216,212)"
                  : info.row?.original?.status === "Vacate in Progress"
                  ? "rgb(255,240,204)"
                  : "",
            }}
          >
            {info.row?.original?.status === "Renewal in Progress"
              ? "Occupied"
              : info.row?.original?.status === "Let in Progress"
              ? "Occupied"
              : info.row?.original?.status === "Vacate in Progress"
              ? "Upcoming Vacancy"
              : info.row?.original?.status}
          </p>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Occupancy Status
        </span>
      ),
    }),
    columnHelper.accessor((row) => row, {
      id: "rent",
      cell: (info) => {
        const data = info.row.original?.tenant?.rent;
        console.log(info.row.original, "lakjsdlafj");
        return (
          <p
            style={{
              textTransform: "capitalize",
              fontSize: "14px",
            }}
          >
            {data ? `£${formatPounds(data)}` : "--"}
          </p>
        );
      },
      header: () => (
        <span
          style={{
            fontSize: "max(12px, 1.1vw)",
          }}
        >
          Property Rent
        </span>
      ),
    }),
  ];
  useEffect(() => {
    if (fetchProperties.data?.data?.count)
      setCount(fetchProperties.data?.data?.count);
  }, [fetchProperties.data]);
  return (
    <div className="propertyDetailsTransactionsWrapper">
      {fetchProperties.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="headingWrapper">
            <h2 className="transactionsHeading">Properties</h2>
            <Search
              setsearch={setsearch}
              style={{ width: "200px" }}
              setPageIndex={setPageIndex}
              placeholder={"Search"}
            />
          </div>
          <TableComponent
            data={fetchProperties.data?.data?.results || []}
            columns={columns}
            count={count}
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </>
      )}
    </div>
  );
}

export default PropertyDetailsProperties;
