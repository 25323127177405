import React, { useEffect, useState } from "react";
import ArchivePopup from "../popups/ArchivePopup";
import { useFetchDetails } from "../../../../Api/PropertiesQuery";
import "../PropertyDetails-panel.scss";
import Documents from "../popups/ComplianceDocument";
import { Skeleton } from "antd";

function PropertyDetailsDocuments({
  dataFromParent,
  fromContact = false,
  dataIsLoading,
}) {
  const [payloadForArchiveApi, setPayloadForArchiveApi] = useState({});
  const [propertyCompliance, setPropertyCompliance] = useState([]);
  const [selectedArchivedData, setSelectedArchivedData] = useState({});
  const [archivePopup, setArchivePopup] = useState(false);
  const [tenancyCompliance, setTenancyCompliance] = useState([]);

  const fetchComplianceMaster = useFetchDetails({
    property: dataFromParent?.id,
    client: dataFromParent?.client?.id,
    tenant: fromContact
      ? dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant?.id
      : dataFromParent?.tenant?.tenant__id,
    // tenant: dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant?.id,
  });
  useEffect(() => {
    if (fetchComplianceMaster.data?.data) {
      setPropertyCompliance(
        fetchComplianceMaster.data?.data?.property_documents_data
      );
      setTenancyCompliance(
        fetchComplianceMaster.data?.data?.tenant_tenancy_files_data
      );
    }
  }, [fetchComplianceMaster.data?.data]);
  return (
    <>
      {fetchComplianceMaster.isLoading ? (
        <Skeleton active />
      ) : (
        <div className="propertyDetailsTransactionsWrapper">
          <div
            className="headingWrapper"
            style={{
              flexDirection: "column",
              height: "100%",
              borderBottom: "none",
            }}
          >
            <h2 className="transactionsHeading" style={{ width: "100%" }}>
              Document Upload
            </h2>
            <div
              className="contact_body_content_data"
              style={{ height: "100%", width: "100%" }}
            >
              <div className="doc_con_cover" style={{ height: "100%" }}>
                <Documents
                  title="Property Documents"
                  documents={propertyCompliance}
                  setSelectedArchivedData={setSelectedArchivedData}
                  setArchivePopup={setArchivePopup}
                  setPayloadForArchiveApi={setPayloadForArchiveApi}
                  type={"property"}
                  rowvalue={dataFromParent}
                />
                <Documents
                  title="Tenancy Documents"
                  documents={tenancyCompliance}
                  setSelectedArchivedData={setSelectedArchivedData}
                  setArchivePopup={setArchivePopup}
                  setPayloadForArchiveApi={setPayloadForArchiveApi}
                  type={"tenancy"}
                  rowvalue={dataFromParent}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {payloadForArchiveApi !== "" && (
        <ArchivePopup
          isOpen={archivePopup}
          setIsOpen={setArchivePopup}
          dataFromParent={selectedArchivedData}
          payloadForArchiveApi={payloadForArchiveApi}
        />
      )}
    </>
  );
}

export default PropertyDetailsDocuments;
