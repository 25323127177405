import { useState, useEffect } from "react";
import "./App.css";
import SideBarComponent from "./Client/GlobalComponents/Sidebar/sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import CircularIndeterminate from "./MainComponent/Loader";
import { useDispatch, useSelector } from "react-redux";
import LowWidthPopup from "./Client/GlobalComponents/LowWidthPopup";
import { useCheckRelease } from "./Api/PropertiesQuery";
import {
  clientid,
  setAllowFullPortalAccess,
} from "./redux/reducer/counterslice";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { set } from "rsuite/esm/internals/utils/date";

function App() {
  const dispatch = useDispatch();
  const { data, isLoading, isError, error, refetch, isFetching } =
    useCheckRelease();
  const { allowFullPortalAccess } = useSelector((state) => state.counter);

  useEffect(() => {
    if (data !== undefined) {
      dispatch(setAllowFullPortalAccess(data?.data));
    }
  }, [data]);
  // if (process.env.NODE_ENV === "production") {
  // console.log = () => {};
  // console.error = () => {};
  // console.debug = () => {};
  // }

  return (
    <>
      {/* {loading && <CircularIndeterminate />} */}
      {allowFullPortalAccess !== null && (
        <div className="App">
          <div className="sidebar">
            <SideBarComponent />
          </div>
          <div className="outlet">
            {" "}
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
