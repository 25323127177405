import React, { useMemo, useState } from "react";
import "../PropertyDetails-panel.scss";
import { createColumnHelper } from "@tanstack/react-table";
import { formatPounds } from "../../../../Utils/Utils";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useFetchPropertyTransaction } from "../../../../Api/PropertiesQuery";
import { useFetchPropertyExpenditure } from "../../../../Api/ContactsQuery";
import expenditureWhite from "../../../../Assets/Common/expenditureWhite.svg";
import grossIncomeWhite from "../../../../Assets/Common/grossIncomeWhite.svg";
import grossIncome from "../../../../Assets/Common/grossIncome.png";
import incomeWhite from "../../../../Assets/Common/incomeWhite.svg";
import expenditure from "../../../../Assets/Common/expenditure.png";
import income from "../../../../Assets/Common/income.png";
import { Skeleton } from "antd";

function PropertyDetailsIncomeAndExpense() {
  const columnHelper = createColumnHelper();
  const { propertyId } = useParams();
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [search, setsearch] = useState("");
  const [selectedExpenditureType, setSelectedExpenditureType] = useState("all");

  const fetchPropertyPropertyTransactionDetails = useFetchPropertyTransaction({
    property: propertyId,
    page: pageindex,
    page_size: pagesize,
    search: search,
    type: "client",
  });

  const fetchPropertyExpenditureData = useFetchPropertyExpenditure({
    params: {
      property: propertyId,
      page: pageindex,
      page_size: pagesize,
      search: search,
      type: selectedExpenditureType,
    },
  });

  const transactionColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Charge Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Pay In",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Pay Out",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.debit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Balance",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.balance;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "14px",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
    ],
    []
  );
  const cardData = [
    {
      title: "Net Amount",
      amount: formatPounds(
        fetchPropertyExpenditureData.data?.data?.total_credit -
          fetchPropertyExpenditureData.data?.data?.total_debit || 0
      ),
      imgSrc:
        selectedExpenditureType === "all" ? grossIncomeWhite : grossIncome,
      type: "all",
    },
    {
      title: "Total Income",
      amount: formatPounds(
        fetchPropertyExpenditureData.data?.data?.total_credit || 0
      ),
      imgSrc: selectedExpenditureType === "income" ? incomeWhite : income,
      type: "income",
    },
    {
      title: "Total Expenditure",
      amount: formatPounds(
        fetchPropertyExpenditureData.data?.data?.total_debit || 0
      ),
      imgSrc:
        selectedExpenditureType === "expense" ? expenditureWhite : expenditure,
      type: "expense",
    },
  ];
  return (
    <div className="propertyDetailsTransactionsWrapper">
      {fetchPropertyExpenditureData.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="headingWrapper">
            <h2 className="transactionsHeading">Income/Expenditure</h2>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {cardData.map((card, index) => (
                <div
                  className={`landlordPopupCard ${
                    selectedExpenditureType === card.type ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    setSelectedExpenditureType(card.type);
                    setPageIndex(1);
                  }}
                  // style={{ width: "max-content" }}
                  style={{
                    // width: "100%",
                    width: "max-content",
                    marginRight: "10px",
                    padding: "12px 13px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={card.imgSrc}
                      alt={card.title}
                      style={{
                        width: "clamp(16px, 1.9vw, 24px)",
                        height: "clamp(16px, 1.9vw, 24px)",
                      }}
                    />
                    <p>{card.title}</p>
                    <h1>£{card.amount}</h1>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <TableComponent
            data={fetchPropertyExpenditureData.data?.data?.data || []}
            columns={transactionColumn}
            count={
              fetchPropertyPropertyTransactionDetails.data?.data?.count || 0
            }
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </>
      )}
    </div>
  );
}

export default PropertyDetailsIncomeAndExpense;
