import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { loginuserdata, token } from "../redux/reducer/counterslice";
import axios from "axios";

// Reusable query function
function useFetchData(queryKey, url, payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey,
    queryFn: async () =>
      handleResponse(url + (payload ? `/${payload}` : ""), "get"),
    placeholderData: keepPreviousData,
  });
}

export function useFetchMaintenanceTable(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["maintenanceTableList", payload],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          status: payload.status,
          client: payload.id,
          search: payload.search,
          page: payload.page,
          page_size: payload.page_size,
          type: payload.type,
          landlord: payload.landlord,
        },
      };
      const response = handleResponse(
        "workorder/workorder/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchMaintenanceFilterStatus(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["MaintenanceFilterStatus", payload],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          client: payload.id,
          landlord: payload.landlord,
          type: payload.type,
        },
      };
      const response = handleResponse(
        "workorder/client_mainatnce_count",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchMaintenanceSpendAmount(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["maintenanceSpedAmount", payload],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          client: payload.id,
          type: payload?.type,
          date_filter:
            payload?.from_date.length > 0 || payload?.to_date.length > 0
              ? ""
              : payload?.date_filter,
          from_date: payload?.from_date,
          to_date: payload?.to_date,
          landlord: payload?.landlord,
        },
      };
      const response = handleResponse(
        "workorder/client_mainatnce_spendamount/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchUpcomingMaintenance(payload) {
  const { handleResponse } = useResponseHandler();
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const landlordId = useSelector((state) => state.counter.landlordId);

  return useQuery({
    queryKey: ["upcomingMaintenance", landlordId, loginuserdata],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          client: loginuserdata.id,
          landlord: landlordId?.length > 0 ? landlordId : null,
          type: false,
        },
      };
      const response = handleResponse(
        "workorder/client_upcoming_mainatnce/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchMaintenanceArchive(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["maintenanceArchive", payload],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          status: payload.status,
          client: payload.id,
          landlord: payload.landlord,
          search: payload.search,
          page: payload.page,
          page_size: payload.page_size,
        },
      };
      const response = handleResponse(
        "workorder/workorder/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useApproveSupplier(payload) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      const uploadPayload = {
        data: payload.payload,
      };
      console.log(payload, "lkajsldfjksdaiflj");
      return handleResponse(
        `workorder/workorder_capprove/v1/${payload.id}/`,
        "put",
        uploadPayload
      );
    },
    onSuccess: () => {
      payload.handleSuccess();
    },
    onError: (error) => {
      console.log(error, "error login");
      toast.error("Error occurred while raising issues.");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["maintenanceTableList"],
        });
      }
    },
  });
}

// export function usePresignedUrlPost(payload) {
//   return useGenericMutation(
//     "property/presignedurl/",
//     payload,
//     null,
//     null,
//     null
//   );
// }

// function useGenericMutation(
//   url,
//   payload,
//   onSuccessCallback,
//   queryKey,
//   tostMessage = "Operation successful."
// ) {
//   const { handleResponse } = useResponseHandler();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();
//   const { setLoading } = useStore((state) => ({
//     setLoading: state.setLoading,
//   }));

//   return useMutation({
//     mutationFn: async () => {
//       const uploadPayload = { data: payload.data || payload };
//       return handleResponse(url, "post", uploadPayload);
//     },
//     onSuccess: () => {
//       if (tostMessage !== null) {
//         toast.success(tostMessage);
//       }
//       if (onSuccessCallback) onSuccessCallback();
//       if (queryKey) {
//         queryClient.invalidateQueries({ queryKey });
//       }
//       setLoading(false);
//     },
//     onError: (error) => {
//       const errorMessage = `Error during mutation at ${url}: ${error.message}`;
//       console.error(errorMessage);
//       toast.error(errorMessage);
//       setLoading(false);
//     },
//   });
// }

export function useFetchMaintenanceOverviewAmount(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["maintenanceOverViewAmount", payload],

    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        "workorder/client_mainatnce_spendamount_summary/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
