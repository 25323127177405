import { Outlet, createBrowserRouter } from "react-router-dom";
import ActivateForm from "../Client/GlobalComponents/Signup/Signuppage";
// import PrivateRoutes from "../Client/GlobalComponents/Signup/PrivateRoutes";
import {
  PrivateRoutes,
  PublicRoutes,
} from "../Client/GlobalComponents/Signup/PrivateRoutes";

import Home from "../Client/Components/Dashboard/home";
import App from "../App";
import { element } from "prop-types";
import ForgetPassword from "../Client/GlobalComponents/forgetpassword/ForgetPassword";
import PropertyOverView from "../Client/Components/Property/Overview";
import AllProperties from "../Client/Components/Property/AllProperties";
import MaintenanceOverview from "../Client/Components/Maintenance/maintenanceoverview";
import MaintenanceLog from "../Client/Components/Maintenance/maintenanceLog";
import MaintenanceArchive from "../Client/Components/Maintenance/maintenancearchive";
import MaintenanceApproval from "../Client/Components/Maintenance/maintenanceapproval";
import Compliance from "../Client/Components/compliance/compliance";
import Inspection from "../Client/Components/inspection/periodicinspection";
import ClientMoney from "../Client/Components/clientmoney/clientmoney";
import { ClientCreate } from "../Client/Components/ClientCreate";
import Contacts from "../Client/Components/Contacts/Contacts";
import InitialSetup from "../Client/Components/InitialSetup/InitaialSetup";
import FileUpload from "../Client/Components/InitialSetup/FileUpload";
import PropertyFolder from "../Client/Components/InitialSetup/PropertyFolder";
import PasswordUpdatePortal from "../Client/GlobalComponents/Signup/PasswordUpdatePortal";
import AdminLogin from "../MainComponent/AdminLogin/AdminLogin";
import PropertyDetails from "../Client/Components/Property/PropertyDetails";
import LandlordDetails from "../Client/Components/Contacts/LandlordDetailsSections/LandlordDetails-main";
const Routes = createBrowserRouter([
  // {
  //   path: "/initialSetup",
  //   element: (
  //     <PrivateRoutes>
  //       <Outlet />
  //     </PrivateRoutes>
  //   ),
  //   children: [
  //     {
  //       path: "/initialSetup",
  //       element: (
  //         <PrivateRoutes>
  //           <InitialSetup />
  //         </PrivateRoutes>
  //       ),
  //     },
  //     {
  //       path: "/initialSetup/:id",
  //       element: (
  //         <PrivateRoutes>
  //           <FileUpload />
  //         </PrivateRoutes>
  //       ),
  //     },
  //   ],
  // },

  {
    path: "/",
    element: (
      <PrivateRoutes>
        <App />
      </PrivateRoutes>
    ),
    children: [
      {
        path: "/",
        element: (
          <PrivateRoutes>
            <Home />
          </PrivateRoutes>
        ),
        index: true,
      },

      {
        path: "/property",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/property",
            element: (
              <PrivateRoutes>
                <PropertyOverView />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "overview",
            element: (
              <PrivateRoutes>
                <PropertyOverView />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "allproperties",
            element: (
              <PrivateRoutes>
                <AllProperties />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "propertyDetails/:propertyId",
            element: (
              <PrivateRoutes>
                <PropertyDetails />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "landlordDetails/:propertyId",
            element: (
              <PrivateRoutes>
                <LandlordDetails />
              </PrivateRoutes>
            ),
            index: true,
          },
        ],
      },
      {
        path: "/maintenance",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/maintenance",
            element: (
              <PrivateRoutes>
                <MaintenanceOverview />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "overview",
            element: (
              <PrivateRoutes>
                <MaintenanceOverview />
              </PrivateRoutes>
            ),
          },
          {
            path: "log",
            element: (
              <PrivateRoutes>
                <MaintenanceLog />
              </PrivateRoutes>
            ),
          },
          {
            path: "approval",
            element: (
              <PrivateRoutes>
                <MaintenanceApproval />
              </PrivateRoutes>
            ),
          },
          {
            path: "archive",
            element: (
              <PrivateRoutes>
                <MaintenanceArchive />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/compliance",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/compliance",
            element: (
              <PrivateRoutes>
                <Compliance />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/inspection",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/inspection",
            element: (
              <PrivateRoutes>
                <Inspection />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/clientmoney",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/clientmoney",
            element: (
              <PrivateRoutes>
                <ClientMoney />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/contacts",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/contacts",
            element: (
              <PrivateRoutes>
                <Contacts />
              </PrivateRoutes>
            ),
          },
        ],
      },
    ],
  },

  {
    path: "/initialSetup",
    element: <InitialSetup />,
  },
  {
    path: "/initialSetup/:propertyId/:id",
    element: <FileUpload />,
  },

  {
    path: "/login",
    element: (
      <PublicRoutes>
        <ActivateForm />
      </PublicRoutes>
    ),
  },
  {
    path: "/createAccount/:id",
    element: (
      <PublicRoutes>
        <ClientCreate />
      </PublicRoutes>
    ),
  },
  {
    path: "/reset-password/:id",
    element: (
      <PublicRoutes>
        <PasswordUpdatePortal />
      </PublicRoutes>
    ),
  },
  {
    path: "/ForgetPassword",
    element: (
      <PublicRoutes>
        <ForgetPassword />
      </PublicRoutes>
    ),
  },
  {
    path: "/admin-login/:id",
    element: <AdminLogin />,
  },

  // {
  //   path: "/test",
  //   element: <App />,
  // },
]);

export default Routes;
