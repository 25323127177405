import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";

export const googleFinderKey = "AIzaSyCIpJxtSOEE6fCWr8Y_WkH72u2MTq0_1wo";

// Satish laptop
// export const baseURL = "http://192.168.1.28:9003/";

// local server
// export const baseURL = "http://122.186.126.218:9010/";

// Client server
export const baseURL = "https://api.solacemanagement.co.uk/";
// export const baseURL = "https://devapi.solacemanagement.co.uk/";

const useResponseHandler = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  // Satish laptop
  // const baseURL = "http://192.168.1.28:9003/";

  // local server
  // const baseURL = "http://122.186.126.218:9010/";

  // Client server
  const baseURL = "https://api.solacemanagement.co.uk/";
  // const baseURL = "https://devapi.solacemanagement.co.uk/";

  const landlordId = useSelector((state) => state.counter.landlordId);

  const handleParams = (payload) => {
    if (!payload || !payload?.params) return {};
    const newParams = { ...payload?.params };
    if (newParams.client && newParams.landlord) {
      if (landlordId) {
        delete newParams.client;
      } else {
        delete newParams.landlord;
      }
    }
    return newParams;
  };

  const handleLayer = async (url, payload, type) => {
    try {
      const requestOptions = {
        method: type.toUpperCase(),
        url: baseURL + url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: handleParams(payload),
        data: payload?.data,
      };

      const response = await axios(requestOptions);
      await new Promise((resolve) => {
        setTimeout(() => {
          dispatch(loader(false));
          // setLoading(false);
          resolve();
        }, 300);
      });

      return response;
    } catch (error) {
      dispatch(loader(false));

      //   setLoading(false);
      console.error(error?.response?.status, "error");
      if (error.response && error?.response?.status == 403) {
        // setUser([]);
        localStorage.clear();
        // setToken("");
        navigate("/login");
        // toast.warning(`Session expired.`);
      }
      console.log(error, "ljoxvnksdfklsdfihlijasdf");

      throw error.message;
    } finally {
      dispatch(loader(false));
    }
  };

  const handleResponse = async (url, type, payload, loadingState = true) => {
    dispatch(loadingState ? loader(true) : loader(false));
    const response = await handleLayer(url, payload, type);
    return response.data;
  };

  return { handleResponse };
};

export { useResponseHandler };

// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useStore } from '../Store/Store';

// const useResponseHandler = () => {
//   const navigate = useNavigate();
//   const { setUser, token, setLoading } = useStore((state) => ({
//     setUser: state.setUser,
//     token: state.token,
//     setLoading: state.setLoading,
//   }));

//   const baseURL = 'http://122.186.126.218:9009/';

//   const handleLayer = async (url, payload, type) => {
//     try {
//       const requestOptions = {
//         method: type.toUpperCase(),
//         url: baseURL + url,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         params: payload?.params,
//         data: payload?.data,
//       };

//       const response = await axios(requestOptions);
//       setLoading(false);
//       return response;
//     } catch (error) {
//       console.error(error);
//       setLoading(false);
//       if (error.response && error.response.status === 401) {
//         setUser([]);
//         localStorage.clear();
//         navigate('/login');
//       }
//       throw error;
//     }
//   };

//   const handleResponse = async (url, type, payload) => {
//     setLoading(true);
//     const response = await handleLayer(url, payload, type);
//     setLoading(false);
//     return response.data;
//   };

//   return { handleResponse };
// };

// export { useResponseHandler };
