import React, { useEffect, useState } from "react";
import toplogo from "./Assests/toplogo.svg";
import bottomLogo from "./Assests/bottomLogo.svg";
import FileUploadView from "./FileUploadView";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchOnboardingFiles } from "../../../Api/InitialSetupQuery";
import { useDispatch, useSelector } from "react-redux";
import { loginuserdata, token } from "../../../redux/reducer/counterslice";

const FileUpload = () => {
  const { propertyId, id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.counter.loginuserdata);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const fetchOnboardingFolder = useFetchOnboardingFiles({
    did: propertyId,
    search: search,
  });
  const propertyFileFilter = useSelector(
    (state) => state.counter.propertyFileFilter
  );
  useEffect(() => {
    if (fetchOnboardingFolder.data?.data?.results) {
      setData(fetchOnboardingFolder.data.data?.results);
    }
  }, [fetchOnboardingFolder.data]);
  // useEffect(() => {
  //   if (user.is_release) {
  //     // navigate("/");
  //   }
  // }, [user]);
  return (
    <>
      <div>
        <img
          style={{ position: "absolute", top: "0", left: "0" }}
          src={toplogo}
          alt="top logo"
        />
        <button
          className="logOutButton"
          onClick={() => {
            dispatch(token(""));
            dispatch(loginuserdata([]));
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }}
        >
          Logout
        </button>
        <img
          style={{ position: "absolute", bottom: "0", right: "0" }}
          src={bottomLogo}
          alt="bottom logo "
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="inital-card"
            style={{ minHeight: "700px", height: "auto" }}
          >
            <FileUploadView
              dataFromParent={data}
              setSearch={setSearch}
              search={search}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
