import "../../../StyleSheet/Maintenance/popups/maintenanceoverviewpopup.scss";
import { createColumnHelper } from "@tanstack/react-table";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFetchMaintenanceOverviewAmount } from "../../../../Api/MaintenanceQuery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatAddress, formatPounds } from "../../../../Utils/Utils";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import {
  setContactDetailsFilter,
  setPropertyDetailsCrumbs,
  setPropertyDetailsFilter,
} from "../../../../redux/reducer/counterslice";
import { useFetchPropertiesCompliance } from "../../../../Api/PropertiesQuery";
import Propertydetails from "../../Property/popups/propertydetails";
import { useNavigate } from "react-router-dom";

export default function MaintenanceOverviewPopup(props) {
  const columnHelper = createColumnHelper();
  const [tabledata, settabledata] = useState([]);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const clientid = useSelector((state) => state.counter.clientid);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [popup, setpopup] = useState(false);
  const dispatch = useDispatch();
  const landlordId = useSelector((state) => state.counter.landlordId);
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const navigate = useNavigate();

  const totalamountdata = useFetchMaintenanceOverviewAmount({
    client: clientid,
    landlord: landlordId?.length > 0 ? landlordId : null,
    paid_by:
      props?.keydata?.text === "Total maintenance spent" ? "total" : "solace",
    page: pageindex,
    page_size: pagesize,
    start_date: props?.Range[0] ? props?.Range[0] : null,
    end_date: props?.Range[1] ? props?.Range[1] : null,
    date_filter: !props?.Range[0] ? props?.selectedFilter : "",
  });
  useEffect(() => {
    if (totalamountdata.data != undefined) {
      settabledata(totalamountdata.data.data.results);
      setCount(totalamountdata.data.data.count);
    }
  }, [totalamountdata.data, pageindex, pagesize]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Date",

        cell: (info) => (
          <p>
            {dayjs(info.row.original?.approved_startdate).format("DD/MM/YYYY")}
          </p>
        ),
      }),
      columnHelper.accessor((row) => row.Address, {
        id: "Address",
        cell: (info) => {
          const data = info.row.original?.property;
          return (
            <span
              className="contentHover"
              style={{ wordBreak: "break-all" }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(1));
                // setSelectedPropertyId(data?.id);
                // setpopup(true);
                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Overview",
                    fromUrl: "/maintenance/overview",
                  })
                );
                navigate(`/property/propertyDetails/${data?.id}`);
              }}
            >
              {formatAddress([
                data?.name + " " + data?.street,
                data?.city,
                data?.postcode,
              ])}
            </span>
          );
        },
        header: () => <span>Address</span>,
      }),
      columnHelper.accessor("title", {
        header: "Summary",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor((row) => row.issue_list, {
        header: "Description",
        cell: (info) => {
          return <span>{info.row.original.issue_list.description}</span>;
        },
      }),
      columnHelper.accessor("invoice_amount", {
        header: "Amount",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>
            £{formatPounds(info.getValue())}
          </span>
        ),
      }),
    ],
    []
  );
  useEffect(() => {
    console.log(props.keydata, "keydata");
  }, [props.keydata]);

  return (
    <div
      className={`popup_main_wrapper ${
        props.popupstate === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`main_popup ${
          props.popupstate === true ? "popup-open" : "popup-close"
        } `}
        style={{
          // width: "1542px",
          height: "800",
          marginRight: "20px",
          position: "relative",
        }}
      >
        <div className="header">
          <div
            className="main_key_content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h1 style={{ fontSize: "22px" }}>
              {props?.keydata?.text} <span>£{props?.keydata?.amount}</span>
            </h1>
            <div className="col-1 close_button_wrapper">
              <img
                className="close"
                alt="close"
                onClick={() => props.setPopup(false)}
                src={closeSolidIcon}
              />
            </div>
          </div>
        </div>
        <div className="main_contentMaintenance">
          <TableComponent
            columns={columns}
            data={tabledata}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}

            // onclickrow={row => console.log(row)}
          />
        </div>
      </div>
      {fetchProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setpopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={""}
          propertyPopup={popup}
        />
      )}
    </div>
  );
}
