import React, { useEffect, useMemo, useState } from "react";
import "../../StyleSheet/Maintenance/maintenanceapproval.scss";
import logo from "../../../Assets/Client/maintenence/maintanence_t_ogo.svg";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../../MainComponent/fields/search";
import balanceimg from "../../../Assets/Client/maintenence/balance.svg";
import MaintenanceSupplierApprove from "./popups/maintenancesupplierapprove";
import {
  useFetchMaintenanceSpendAmount,
  useFetchMaintenanceTable,
} from "../../../Api/MaintenanceQuery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  addLeadingZero,
  formatAddress,
  formatPounds,
} from "../../../Utils/Utils";
import { Skeleton } from "antd";
import { useFetchPropertiesCompliance } from "../../../Api/PropertiesQuery";
import { setPropertyDetailsCrumbs } from "../../../redux/reducer/counterslice";
import Propertydetails from "../Property/popups/propertydetails";
import { useNavigate } from "react-router-dom";
const MaintenanceApproval = () => {
  const columnHelper = createColumnHelper();
  const [popup, setpopup] = useState(false);
  const [popup1, setpopup1] = useState(false);

  const [rowvalue, setrowvalue] = useState([]);
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const landlordId = useSelector((state) => state.counter.landlordId);

  const fetchMaintenanceData = useFetchMaintenanceTable({
    status: "Awaiting Approval",
    id: loginuserdata.id,
    landlord: landlordId?.length > 0 ? landlordId : null,
    search: search,
    page: pageIndex,
    page_size: pagesize,
  });
  const MaintenanceSpendAmount = useFetchMaintenanceSpendAmount({
    id: loginuserdata.id,
    date_filter: "0-7days",
    type: false,
    landlord: landlordId?.length > 0 ? landlordId : null,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "created_date",
        sortingFn: "basic",
        enableSorting: false,

        cell: (info) => {
          const dataFromParent = info.row.original;
          return (
            <p style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
              {dayjs(dataFromParent?.created_date).format("DD/MM/YYYY")}
            </p>
          );
        },
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            Reported Date
          </span>
        ),
      }),
      // columnHelper.accessor("status", {
      //   id: "status",
      //   enableSorting: false,
      //   header: "Status",
      //   cell: (info) => {
      //     return (
      //       <div className="statusWrapper">
      //         <div className="status_main"></div>
      //         <p className="status" style={{ wordBreak: "break-all" }}>
      //           {info.getValue()}
      //         </p>
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor((row) => row, {
        id: "Address",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original.property;
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(1));
                // setSelectedPropertyId(data?.id);
                // setpopup1(true);
                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Maintenance Approval",
                    fromUrl: "/maintenance/approval",
                  })
                );
                navigate(`/property/propertyDetails/${data?.id}`);
              }}
            >
              {" "}
              {formatAddress([
                data.name,
                data.street,
                data.city,
                data.postcode,
              ])}
            </span>
          );
        },
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>Address</span>
        ),
      }),

      columnHelper.accessor((row) => row, {
        id: "summary",
        enableSorting: false,
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>Summary</span>
        ),
        cell: (info) => {
          return (
            <p style={{ wordBreak: "break-all" }}>
              {info.row.original?.issue_list?.title} -{" "}
              {info.row.original?.issue_type?.name}
            </p>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "noOfQuotes",
        sortingFn: "basic",
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            No of Quotes
          </span>
        ),
        cell: (info) => {
          return (
            <div className="status_main" style={{ background: "inherit" }}>
              <span style={{ color: "black" }}>
                {addLeadingZero(info.row.original.work_order_suppliers.length)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "noOfQuotes",
        sortingFn: "basic",
        enableSorting: false,
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>Action</span>
        ),
        cell: (info) => {
          return (
            <button
              className="maintenance_approvalButton"
              style={{
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setpopup(true);
                setrowvalue(info.row.original);
              }}
            >
              Action
            </button>
          );
        },
      }),
    ],
    []
  );
  const clickrow = (row) => {
    console.log(row);
    setpopup(true);
    setrowvalue(row);
  };
  useEffect(() => {
    if (fetchMaintenanceData?.data?.data?.results) {
      setCount(fetchMaintenanceData?.data?.data?.count);
    }
  }, [fetchMaintenanceData.data]);

  return (
    <>
      <div className="maintenace_approval">
        <div className="maintenance_content_wrapper">
          <div className="header">
            <img src={logo}></img>
            <h1>Maintenance Approval</h1>
          </div>
          <div className="maintenace_log_wrapper">
            <div
              className="maintance_log_headwrap"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
                // padding: "0 24px",
              }}
            >
              <div className="balance">
                <div className="img_con">
                  <img src={balanceimg}></img>
                  <p>Reserve Balance</p>
                </div>
                <h1>
                  £
                  {formatPounds(
                    MaintenanceSpendAmount.data?.data?.latest_balance
                  )}
                </h1>
              </div>

              <div style={{ display: "flex", gap: "15px" }}>
                <Search
                  setsearch={setSearch}
                  style={{ width: "210px" }}
                  setPageIndex={setPageIndex}
                  placeholder={"property"}
                />
                {/* <button
                  style={{
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    background: "rgba(129, 126, 255, 1)",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    gap: "5px",
                    textWrap: "nowrap",
                  }}
                >
                  Generate Report
                  <img src={arrow} alt="arrow" width={"12px"} />
                </button> */}
              </div>
            </div>
            <div className="main_content">
              {fetchMaintenanceData.isFetching ? (
                <div className="skelitonDiv">
                  <Skeleton active style={{ height: "98px" }} rows={1} />
                </div>
              ) : (
                <TableComponent
                  data={
                    fetchMaintenanceData.data?.data?.results
                      ? fetchMaintenanceData.data?.data?.results
                      : []
                  }
                  columns={columns}
                  onclickrow={clickrow}
                  count={count}
                  pageindex={pageIndex}
                  pagesize={pagesize}
                  setPageIndex={setPageIndex}
                  setPageSize={setPageSize}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <MaintenanceSupplierApprove
          rowvalue={rowvalue}
          setpopup={setpopup}
          popupstate={popup}
        />
      )}

      {fetchProperties.data?.data && popup1 && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setpopup1}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={""}
          propertyPopup={popup}
        />
      )}
    </>
  );
};

export default MaintenanceApproval;
