import React, { useEffect, useState } from "react";
import "../../../StyleSheet/Property/allproperties.scss";
import "./PropertyDetails-panel.scss";
import { formatAddress, formatName } from "../../../../Utils/Utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import address from "../../../../Assets/Common/address.svg";
import landlordIcon from "../../../../Assets/Common/landlordIcon.svg";
import { toast } from "sonner";
import { Skeleton } from "antd";
function PropertyDetailsPanel({ dataFromParent, dataIsLoading }) {
  const [copied, setCopied] = useState(false);
  const [text, setText] = useState();
  const landlordAddress = dataFromParent?.property_landlord_address?.[0];

  useEffect(() => {
    if (dataFromParent) {
      setText(
        `${landlordAddress?.Name} ${landlordAddress.street}, ${landlordAddress.town}, ${landlordAddress.postcode}`
      );
    }
  }, [dataFromParent, landlordAddress]);

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied to clipboard.");
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1000);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          fallbackCopyTextToClipboard(text);
        });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };
  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      // toast.success('Copied to clipboard.');
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      toast.error("Failed to copy text.");
    }

    document.body.removeChild(textArea);
  };

  const navigate = useNavigate();
  const breadCrumbDetails = useSelector(
    (state) => state.counter.propertyDetailsCrumbs
  );
  const landlordDetails = dataFromParent?.contact_detail?.[0];
  return (
    <div>
      <div className="header">
        <div>
          <span
            className="crumbSubSection"
            onClick={() => navigate(breadCrumbDetails?.fromUrl)}
          >
            {breadCrumbDetails?.fromTitle}
          </span>
          <span style={{ fontWeight: "500" }}>/Landlord Details</span>
        </div>
      </div>
      <div className="details-card">
        {dataIsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div
              style={{
                background: "#F9FAFB",
                borderRadius: "50%",
                marginRight: "20px",
              }}
            >
              <img
                src={landlordIcon}
                alt="Property"
                className="property-image"
                style={{
                  marginRight: "0",
                  padding: "20px",
                }}
              />
            </div>
            <div className="info">
              <h2 className="infoMainHeading">{dataFromParent?.name}</h2>

              <div className="panelContentSection">
                <div>
                  <p className="landlord">
                    <img
                      src={landlordIcon}
                      alt="address"
                      width={20}
                      height={20}
                    />
                    {"  "}
                    <strong>Landlord</strong>
                    {formatName(
                      landlordDetails?.title,
                      landlordDetails?.first_name,
                      landlordDetails?.last_name
                    )}
                  </p>
                  <p className="address">
                    <img src={address} alt="address" width={20} height={20} />
                    <strong>Address</strong>
                    {formatAddress([
                      dataFromParent?.property_landlord_address?.[0]?.Name +
                        " " +
                        dataFromParent?.property_landlord_address?.[0]?.street,
                      dataFromParent?.property_landlord_address?.[0]?.town,
                      dataFromParent?.property_landlord_address?.[0]?.postcode,
                    ])}

                    <button
                      className="copy-btn"
                      onClick={() => {
                        if (!copied) {
                          handleCopy();
                        }
                      }}
                      style={{ color: copied && "green" }}
                    >
                      {copied ? "Copied!" : "Copy"}
                    </button>
                  </p>
                </div>
                <div></div>
              </div>

              {/* <p className="bedrooms">
            <span className="icon bed"></span> 2
          </p> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PropertyDetailsPanel;
