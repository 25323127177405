import React, { useState } from "react";
import "../../StyleSheet/Property/allproperties.scss";
import "./PropertyDetails-panel.scss";
import PropertyDetailsPanel from "./PropertyDetails-panel";
import { useParams } from "react-router-dom";
import { useFetchPropertiesCompliance } from "../../../Api/PropertiesQuery";
import PropertyDetailsTransactions from "./PropertyDetailsSections/PropertyDetails-transactions";
import PropertyDetailsTenancy from "./PropertyDetailsSections/PropertyDetails-tenancy";
import PropertyDetailsMaintenance from "./PropertyDetailsSections/PropertyDetails-maintenance";
import PropertyDetailsIncomeAndExpense from "./PropertyDetailsSections/PropertyDetails-incomeAndExpense";
import PropertyDetailsDocuments from "./PropertyDetailsSections/PropertyDetails-documents";
import PropertyDetailsContactDetails from "./PropertyDetailsSections/PropertyDetails-contactDetails";
import PropertyDetailsUploadDoc from "./PropertyDetailsSections/PropertyDetails-uploadDoc";

function PropertyDetails() {
  const { propertyId } = useParams();
  const fetchProperties = useFetchPropertiesCompliance(propertyId);

  const [selectedSection, setSelectedSection] = useState("Contact Details");

  const renderSection = () => {
    switch (selectedSection) {
      case "Contact Details":
        return (
          <PropertyDetailsContactDetails
            dataFromParent={fetchProperties?.data?.data}
            dataIsLoading={fetchProperties?.isLoading}
            fromContact={true}
          />
        );
      case "Documents":
        return (
          <PropertyDetailsDocuments
            dataFromParent={fetchProperties?.data?.data}
            dataIsLoading={fetchProperties?.isLoading}
            fromContact={true}
          />
        );
      case "Income and Expenditure":
        return <PropertyDetailsIncomeAndExpense />;
      case "Maintenance":
        return <PropertyDetailsMaintenance />;
      case "Tenancies":
        return (
          <PropertyDetailsTenancy
            dataFromParent={fetchProperties?.data?.data}
            dataIsLoading={fetchProperties?.isLoading}
          />
        );
      case "Transactions":
        return (
          <PropertyDetailsTransactions
            dataFromParent={fetchProperties?.data?.data}
            dataIsLoading={fetchProperties?.isLoading}
          />
        );
      case "Upload Documents":
        return (
          <PropertyDetailsUploadDoc
            dataFromParent={fetchProperties?.data?.data}
            dataIsLoading={fetchProperties?.isLoading}
          />
        );
      default:
        return <PropertyDetailsPanel />;
    }
  };

  return (
    <div
      className="allproperties_main_wrapper property-details"
      style={{ overflowY: "auto" }}
    >
      <PropertyDetailsPanel
        dataFromParent={fetchProperties?.data?.data}
        dataIsLoading={fetchProperties?.isLoading}
      />
      <div className="tabs">
        {[
          "Contact Details",
          "Documents",
          "Income and Expenditure",
          "Maintenance",
          "Tenancies",
          "Transactions",
          "Upload Documents",
        ].map((tab) => (
          <button
            key={tab}
            className={selectedSection === tab ? "active" : ""}
            onClick={() => setSelectedSection(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="sectionWrapper">{renderSection()}</div>
    </div>
  );
}

export default PropertyDetails;
