import React, { useState, useEffect } from "react";
import "./style.scss";
import logo from "../../../Assets/Login/Logo_main.svg";
import solace from "../../../Assets/Login/Solace.svg";
import solaceCorner from "../../../Assets/Login/Solace_corner.svg";
import Arrow from "../../../Assets/Common/Arrow.svg";
import ArrowWhite from "../../../Assets/Common/ArrowWhite.svg";
import { toast } from "sonner";
import { useForgotPassword } from "../../../Api/PropertiesQuery";
import { useNavigate } from "react-router-dom";
import LowWidthPopup from "../LowWidthPopup";
import DefaultLoader from "../DefaultLoader";

export default function ForgetPassword() {
  const [UserDetails, setUserDetails] = useState({ email: "" });
  const navigate = useNavigate();
  const [lowWidthPopup, setLowWidthPopup] = useState(false);

  const checkScreenWidth = () => {
    if (window.innerWidth < 750) {
      setLowWidthPopup(true); // Show the popup if width < 560px
    } else {
      setLowWidthPopup(false); // Hide the popup if width >= 560px
    }
  };

  useEffect(() => {
    checkScreenWidth(); // Check on load
    window.addEventListener("resize", checkScreenWidth); // Check on resize

    return () => {
      window.removeEventListener("resize", checkScreenWidth); // Cleanup listener on unmount
    };
  }, []);

  const forgotPasswordReset = useForgotPassword();

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(UserDetails.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    forgotPasswordReset.mutate(UserDetails.email);
  };
  return (
    <>
      {forgotPasswordReset?.isPending && <DefaultLoader />}{" "}
      {/* loading screen*/}
      {lowWidthPopup ? (
        <LowWidthPopup />
      ) : (
        <div className="Forget_password">
          <div className="loginLeft">
            <div className="backgroundImage">
              <div className="innerContent">
                <div className="innerTop">
                  <div className="loginPageLogo">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="loginPageSolace">
                    <img src={solace} alt="solace" />
                  </div>
                  <div className="borderDiv"></div>
                  <p>Client Portal</p>
                </div>
              </div>
            </div>
          </div>
          <div className="loginRight">
            <div className="loginRightMainImg1">
              <img src={solaceCorner} alt="solaceCorner" />
            </div>
            <form
              className="innerContent"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <p
                className="goBack"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <img
                  src={ArrowWhite}
                  alt="arrow"
                  style={{ marginLeft: "10px" }}
                ></img>
                Go Back
              </p>
              <div className="forgetPasswordInnerWrapper">
                <h2>Forgot Password</h2>
                <p className="forgetPasswordInnerWrapperText">
                  Please Enter Your Email Address to Receive a Verification Link
                </p>
                <input
                  className="inputStyle"
                  type="text"
                  placeholder="Email"
                  value={UserDetails.email}
                  style={{
                    outline: "none",
                    margin: "0",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  onChange={(e) => {
                    setUserDetails((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                />
                <button
                  type="submit"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  SEND
                  <img
                    src={Arrow}
                    alt="arrow"
                    style={{ marginLeft: "10px" }}
                  ></img>
                </button>
              </div>
            </form>
          </div>
          <div className="loginMainImg1">
            <img src={solaceCorner} alt="solaceCorner" />
          </div>
        </div>
      )}
    </>
  );
}
