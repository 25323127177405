import "../../StyleSheet/dashboard/home.scss";
import ComplianceHome from "./compliance";
import ArrearsHome from "./arrears";
import PaymentsHome from "./payments";
import CurrentOccpancy from "./currentoccupancy";
import MaintanenceDashboard from "./Maintanence";
import { useSelector } from "react-redux";
import PeriodicInspection from "./periodicInspection";
import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";

export default function Home() {
  const loading = useSelector((state) => state.counter.loader);
  const containerRef = useRef(null);
  const belowRef = useRef(null);

  const [isColumn, setIsColumn] = useState(false);
  const [isWrapped, setIsWrapped] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 1075 && (!isColumn || !isWrapped)) {
      toggleLayout();
      toggleWrap();
    } else if (window.innerWidth > 1075 && (isColumn || isWrapped)) {
      toggleLayout();
      toggleWrap();
    }
  };

  const toggleLayout = () => {
    if (!containerRef.current) return;
    const children = Array.from(containerRef.current.children);
    if (!isColumn) {
      gsap.to(children, {
        duration: 1,
        marginTop: (i) => `${i * 15}px`,
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(containerRef.current, {
        duration: 1,
        flexDirection: "column",
      });

      setIsColumn(true);
    } else {
      gsap.to(children, {
        duration: 1,
        marginTop: "0px",
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(containerRef.current, {
        duration: 1,
        flexDirection: "row",
      });

      setIsColumn(false);
    }
  };

  const toggleWrap = () => {
    if (!belowRef.current) return;
    const children = Array.from(belowRef.current.children);

    if (!isWrapped) {
      gsap.to(children, {
        duration: 1,
        marginTop: "0px",
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(belowRef.current, {
        duration: 1,
        flexWrap: "wrap",
      });

      setIsWrapped(true);
    } else {
      gsap.to(children, {
        duration: 1,
        marginTop: "15px",
        marginLeft: "0px",
        opacity: 1,
        stagger: 0.1,
      });

      gsap.to(belowRef.current, {
        duration: 1,
        flexWrap: "wrap",
      });

      setIsWrapped(false);
    }
  };
  useEffect(() => {
    if (belowRef.current) {
      toggleWrap();
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isColumn]);

  if (loading) {
    return null;
  }
  return (
    <div className="home_wrapper">
      <div className="home_inner_wrapper" ref={containerRef}>
        <div className="complains-tab">
          <ComplianceHome />
        </div>

        <div className="arrearRecept">
          <div className="arrearHome">
            <ArrearsHome />
          </div>
          <div className="payHome">
            <PaymentsHome />
          </div>
        </div>
      </div>

      <div className="home-below" ref={belowRef}>
        <div className="periodHome">
          <PeriodicInspection />
        </div>
        <div className="currentHome">
          <CurrentOccpancy />
        </div>
        <div className="mainDashDown">
          <MaintanenceDashboard />
        </div>
      </div>
    </div>
  );
}
