import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import FilterPanel from "../../../../MainComponent/FilterPanel";
import closebutton from "../../../../Assets/Common/close_button.svg";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useFetchComplienceSelected } from "../../../../Api/homeQuery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import download from "../../../../Assets/Common/download.svg";
import {
  setContactDetailsFilter,
  setPropertyDetailsCrumbs,
  setPropertyDetailsFilter,
} from "../../../../redux/reducer/counterslice";
import Propertydetails from "../../Property/popups/propertydetails";
import { useFetchPropertiesCompliance } from "../../../../Api/PropertiesQuery";
import Search from "../../../../MainComponent/fields/search";
import svg from "../../../../Assets/Client/svgviewer-output.svg";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";

export default function CompliancePopup(props) {
  const { activeTab, setActiveTab, selecteddata } = props;
  const columnHelper = createColumnHelper();
  const [TableData, setTableData] = useState([]);
  const [paramsPayload, setParamsPayload] = useState("all");
  const clientid = useSelector((state) => state.counter.clientid);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [popup, setPopup] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const landlordId = useSelector((state) => state.counter.landlordId);
  const dispatch = useDispatch();

  const getcomplience = useFetchComplienceSelected({
    params: {
      client: clientid,
      landlord: landlordId?.length > 0 ? landlordId : null,
      id: selecteddata.document_id,
      type: selecteddata.type,
      status: paramsPayload,
      page: pageindex,
      page_size: pagesize,
      search: search,
    },
  });

  const setParams = () => {
    if (activeTab === "Not Due") {
      setParamsPayload("not_due");
    } else if (activeTab === "Due") {
      setParamsPayload("due");
    } else if (activeTab === "Overdue") {
      setParamsPayload("overdue");
    } else {
      setParamsPayload("all");
    }
  };

  useEffect(() => {
    console.log("Active Tab changed:", activeTab);
    setParams();
  }, [activeTab]);

  useEffect(() => {
    if (getcomplience.data) {
      setTableData(getcomplience.data.data.results);
      setCount(getcomplience.data?.data?.count);
    }
  }, [getcomplience.data, paramsPayload, pageindex, pagesize, selecteddata]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("expiry_date", {
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>
            Expiry Date
          </span>
        ),
        cell: (info) => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            {dayjs(info.renderValue()).format("DD/MM/YYYY")}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "property_address",
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>
            Property Address
          </span>
        ),
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <span
              className="contentHover"
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                // dispatch(setPropertyDetailsFilter("1"));
                // dispatch(setContactDetailsFilter(1));
                // setSelectedPropertyId(data?.property);
                // setPopup(true);
                dispatch(
                  setPropertyDetailsCrumbs({
                    fromTitle: "Home",
                    fromUrl: "/",
                  })
                );
                navigate(`/property/propertyDetails/${data?.property}`);
              }}
            >
              {data.property_address}
            </span>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Status</span>
        ),
        cell: (info) => (
          <span
            style={{
              textTransform: "capitalize",
              background:
                info.getValue() === "not_due"
                  ? "#DBF8E7"
                  : info.getValue() === "due"
                  ? "#FFF0CC"
                  : info.getValue() === "overdue"
                  ? "#FFD8D4"
                  : "",
              padding: "8px 10px",
              borderRadius: "8px",
              width: "clamp(100px, 15vw, 140px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            {info.getValue() === "not_due" ? "Not Due" : info.getValue()}
          </span>
        ),
      }),

      columnHelper.accessor((row) => row, {
        id: "Document",
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <p
              className="download"
              style={{
                color: "#817EFF",
                cursor: "pointer",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
              onClick={() => {
                window.open(data.file?.[0], "_blank");
              }}
            >
              Download&nbsp;&nbsp;
              <img
                src={download}
                width={"15px"}
                height={"15px"}
                alt="document image"
                style={{ position: "relative", top: "-1.2px" }}
              />
            </p>
          );
        },
        header: () => (
          <span style={{ fontSize: "clamp(15px, 1.1vw, 27px)" }}>Document</span>
        ),
      }),
    ],
    []
  );

  return (
    <div
      className={`popup_main_wrapper ${
        props.popupstate === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`main_popup ${
          props.popupstate === true ? "popup-open" : "popup-close"
        } `}
        style={{
          // width: "1542px",
          // height: "800",
          // marginRight: "20px",
          position: "relative",
        }}
      >
        <div className="com_popup_header">
          <div
            className="content"
            style={{
              marginBottom: "1%",
            }}
          >
            <h1>
              {selecteddata?.icon ? (
                <div
                  style={{
                    background: "#F3F3F7",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "clamp(18px, 1.3vw, 45px)",
                    height: "clamp(18px, 1.3vw, 45px)",
                    padding: "clamp(16px, 1.2vw, 40px)",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={selecteddata?.icon ? selecteddata?.icon : svg}
                    style={{
                      width: "clamp(16px, 1.2vw, 40px)",
                      height: "clamp(16px, 1.2vw, 40px)",
                    }}
                  ></img>
                </div>
              ) : (
                <img src={svg} />
              )}
              {props?.popuptext}
            </h1>
            <div className="search_close_com">
              <div>
                <Search
                  setsearch={setSearch}
                  style={{ width: "100%" }}
                  setPageIndex={setPageIndex}
                  placeholder={"address"}
                />
              </div>
              <div className="col-1 close_button_wrapper">
                <img
                  className="close"
                  alt="close"
                  onClick={() => {
                    props.setPopupstate(false);
                    setTableData([]);
                    setActiveTab("All");
                    setParamsPayload("all");
                  }}
                  src={closeSolidIcon}
                />
              </div>
            </div>
          </div>
          <div
            className="com_popup_filter"
            style={{
              // marginBottom: "20px",
              background: "rgb(247,249,251)",
              borderRadius: "15px",
            }}
          >
            <FilterPanel
              tabs={["All", "Not Due", "Due", "Overdue"]}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>
        </div>

        <div className="complianceConfig">
          {getcomplience.isFetching ? (
            <div className="skelitonDiv">
              <Skeleton active style={{ height: "98px" }} rows={1} />
            </div>
          ) : (
            <TableComponent
              columns={columns}
              data={TableData}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          )}
        </div>
      </div>
      {fetchProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setPopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={popup}
        />
      )}
    </div>
  );
}
