import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../popups/PropertyCreate.scss";
import closeIcon from "../../../../Assets/Common/closeIcon.svg";
import pdfThumbnail from "../../../../Assets/Common/pdf_image.svg";

import {
  usePresignedUrlPost,
  usePresignedUrlPut,
  usePresignedUrlPutType,
  usePropertyFilePost,
} from "../../../../Api/PropertiesQuery";
import DefaultLoader from "../../../GlobalComponents/DefaultLoader";
import { addLeadingZero } from "../../../../Utils/Utils";
import { toast } from "sonner";
const UploadPropertyDocument = (props) => {
  const { dataFromParent, propertyApprovalFolderId } = props;
  const [coImage, setCoImage] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("idel");
  const [data, setData] = useState({ image: [] });
  const [ImageData, setImageData] = useState({ image: [] });
  const [enrollPresignedImage, setEnrollPresignedImage] = useState([]);
  const [presignedURL, setPresignedURL] = useState("");
  const [binaryImageData, setBinaryImageData] = useState("");
  const [imageType, setImageType] = useState("");
  const [PresignedUrlPayload, setPresignedUrlPayload] = useState({
    multiple_files: [],
  });
  const createPresignedURL = usePresignedUrlPost(PresignedUrlPayload);
  const [files, setFiles] = useState([]);
  const updatePresignedUrlPUT = usePresignedUrlPutType();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const oversizedFiles = acceptedFiles.filter((file) => {
        return file.type.startsWith("video/") && file.size > 5 * 1024 * 1024; // 10MB in bytes
      });

      if (oversizedFiles.length > 0) {
        toast.error("Videos must not exceed 5MB in size.");
        return;
      }

      if (acceptedFiles.length + files.length <= 9) {
        const newFiles = [...files, ...acceptedFiles];
        setFiles(newFiles);
        setEnrollPresignedImage([...enrollPresignedImage, ...acceptedFiles]);

        if (newFiles.length > 0) {
          const payload = newFiles.map((ele) => ({
            filename: `${data.type === "Property" ? "property" : "contact"}${
              ele.path
            }`,
            file_type: ele.type,
          }));

          setPresignedUrlPayload({
            multiple_files: payload,
          });

          setTimeout(() => {
            createPresignedURL.mutate();
          }, 100);
        }
      } else {
      }
    },
    maxFiles: 8,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
    },
  });

  const removeFunction = (index) => {
    const newFiles = files?.filter((_, i) => i !== index);
    const newEnrollPresignedImage = enrollPresignedImage?.filter(
      (_, i) => i !== index
    );
    const urls = presignedURL?.filter((_, i) => i !== index);
    setFiles(newFiles);
    setEnrollPresignedImage(newEnrollPresignedImage);
    setPresignedURL(urls);

    setData((prevValue) => ({
      ...prevValue,
      image: prevValue?.image?.filter((_, i) => i !== index),
    }));
  };

  useEffect(() => {
    if (createPresignedURL.data?.data?.length) {
      const output = createPresignedURL.data.data.map(
        (url) => url.split("?")[0]
      );
      setPresignedURL(output);
      setPresignedUrlPayload({ multiple_files: [] });

      const binaryData = enrollPresignedImage.map((file, index) => ({
        filename: file.name,
        type: file.type,
        url: output[index],
        data: file,
      }));

      setBinaryImageData(binaryData);

      if (binaryData.length > 0) {
        binaryData.forEach((binary, index) => {
          updatePresignedUrlPUT.mutate({
            url: createPresignedURL.data.data[index],
            imagePayload: binary.data,
            type: binary.type,
          });
        });
      }
      console.log(data, "dataLoggs");

      setData((prevValue) => {
        const existingUrls = new Set(prevValue?.image?.map((file) => file.url));
        const newFiles = binaryData.filter(({ url }) => !existingUrls.has(url));
        return {
          ...prevValue,
          image: [
            ...prevValue.image,
            ...newFiles.map(({ filename, type, url }) => ({
              filename,
              type,
              url,
            })),
          ],
        };
      });
    }
  }, [createPresignedURL.data]);

  const postePropertyFile = usePropertyFilePost();

  const handleSubmit = (e) => {
    const dummyData = {
      document_type: propertyApprovalFolderId?.[0]?.id,
      file: data.image,
      custom_name: "Approval Folder",
      contact: dataFromParent.id,
      property: dataFromParent.id,
      type: "Property",
      expiry_date: "",
    };
    setData(dummyData);
    setTimeout(() => {
      postePropertyFile.mutate(dummyData);
      // postePropertyFile.mutate(dummyData);
    }, 300);
    // props.setpopup(false);

    setCoImage([]);
    setFiles([]);
    setImageData({ image: [] });
    setEnrollPresignedImage([]);
    setPresignedURL("");
    setBinaryImageData("");
    setImageType("");
    setData({ image: [] });
    setPresignedUrlPayload({
      multiple_files: [],
    });
  };
  const propertyLoading =
    postePropertyFile.isPending ||
    updatePresignedUrlPUT.isPending ||
    createPresignedURL.isPending;

  return (
    <>
      {propertyLoading && <DefaultLoader />}
      <div className="upload_main">
        {/* <div className="upload_doc_top">
          <div className="up_accountBalanceCard">
            <div className="up_doc_wrapper">
              <span className="up_doc_top_file">Files Under Review</span>
            </div>
            <span>
              <span>
                {addLeadingZero(
                  propertyApprovalFolderId?.[0]?.documents.length
                )}
              </span>
            </span>
          </div>
        </div> */}
        <div className="Upload_pic">
          <div
            style={{
              height: "100%",
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className={`imagedropper ${coImage.length > 0 && "actives"}`}
              style={{
                justifyContent: "center",
                width: "100%",
                padding: "0 0 10px 0",
              }}
            >
              <div
                {...(coImage.length < 8 ? getRootProps() : {})}
                className={`Imagedropper_inner`}
                style={{ cursor: "pointer", justifyContent: "flex-end" }}
              >
                {
                  <>
                    {files.length < 8 ? (
                      <>
                        <input {...getInputProps()} />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "clamp(12px, 1.1vw, 33px)",
                              marginBottom: "max(7px, 0.7vw)",
                              color: "black",
                            }}
                          >
                            Drag documents here to upload
                          </p>
                          <p
                            style={{
                              fontSize: "clamp(12px, 1.1vw, 33px)",
                              marginBottom: "max(7px, 0.7vw)",
                            }}
                          >
                            Or
                          </p>
                          <button
                            className="Next_Button"
                            style={{
                              outline: `1px solid #817eff`,
                              marginBottom: "max(10px, 0.8vw)",
                              width: "fit-content",
                              background: "white",
                              color: "#817eff",
                              fontSize: "clamp(13px, 1.15vw, 38px)",
                              borderRadius: "5px",
                              padding: "max(10px, 0.8vw) max(13px, 1.8vw)",
                            }}
                          >
                            Upload Document
                          </button>
                        </div>
                      </>
                    ) : (
                      <span> Max upload reached</span>
                    )}
                  </>
                }
              </div>
              <div className="Image_Viewer">
                {files?.length >= 0 &&
                  files?.map((upFile, index) => {
                    const isImage = upFile.type.includes("image");
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        {isImage ? (
                          <img
                            src={URL.createObjectURL(upFile)}
                            className="ImageView"
                            alt="preview"
                            style={{
                              width: "clamp(90px, 7.5vw ,230px)",
                              height: "clamp(80px, 7vw ,200px)",
                              marginLeft: "clamp(20px, 1.8vw, 50px)",
                              marginTop: "clamp(10px, 0.9vw, 28px)",
                            }}
                          />
                        ) : (
                          <img
                            src={pdfThumbnail}
                            className="PdfView"
                            alt="pdf preview"
                            style={{
                              width: "clamp(90px, 7.5vw ,230px)",
                              height: "clamp(80px, 7vw ,200px)",
                              marginLeft: "clamp(20px, 1.8vw, 50px)",
                              marginTop: "clamp(10px, 0.9vw, 28px)",
                            }}
                          />
                        )}
                        <span
                          className="cancle_img"
                          onClick={(e) => removeFunction(index)}
                          style={{
                            fontSize: "clamp(16px, 1.3vw, 44px)",
                            top: isImage
                              ? "clamp(10px, 0.7vw, 36px)"
                              : "clamp(3px, 0.25vw, 10px)",
                            right: "clamp(-5px, -0.45vw, -16px)",
                          }}
                        >
                          <img
                            src={closeIcon}
                            alt="closeIcon"
                            style={{
                              width: "clamp(10px, 0.9vw, 33px)",
                              height: "clamp(13px, 0.8vw, 50px)",
                            }}
                          />
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <p
            className="Header"
            style={{
              // marginLeft: "77px",
              // marginTop: "40px",
              color: "#000000",
            }}
          >
            Please upload documents related to this property
          </p>
          <p
            className="Header"
            style={{
              // marginLeft: "77px",
              marginTop: "0px",
              color: "#808080",
              fontWeight: "400",
            }}
          >
            The uploaded documents will be reviewed and updated by the property
            manager.
          </p>
        </div>
        <div
          className="upload_doc_bottom"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            className="Next_Button"
            onClick={(e) => {
              handleSubmit(e);
            }}
            disabled={data.image.length === 0}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadPropertyDocument;
