import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import { loginuserdata, token } from "../redux/reducer/counterslice";
import axios from "axios";

export function useFetchTenantNoId(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["landlordList", payload.params],
    queryFn: async () => {
      if (payload.params?.status) {
        const uploadPayload = {
          params: payload.params,
        };
        const response = handleResponse(
          "manager/tenant/v1/",
          "get",
          uploadPayload,
          false
        );
        return response;
      }

      // }
    },
    enabled: payload?.enabled ? payload?.enabled : true,
    // placeholderData: keepPreviousData,
  });
}
export function useFetchLandLordExpenditure(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["landLordExpenditureee", payload],
    queryFn: async () => {
      if (payload.landlord && payload.page && payload.page_size) {
        const uploadPayload = {
          params: payload,
        };
        const response = handleResponse(
          "transaction/landlord_income/v1/",
          "get",
          uploadPayload,
          false
        );
        return response;
      }
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchLandLordTransactions(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["landLordTransactionss", payload],
    queryFn: async () => {
      if (payload.landlord && payload.page && payload.page_size) {
        const uploadPayload = {
          params: payload,
        };
        const response = handleResponse(
          "transaction/landlord_transaction/v1/",
          "get",
          uploadPayload,
          false
        );
        return response;
      }
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchPropertyExpenditure(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["PropertyExpenditure", payload],
    queryFn: async () => {
      if (payload.params.property !== "" && payload.params.property.length) {
        const uploadPayload = payload;
        const response = handleResponse(
          "transaction/property_income/v1/",
          "get",
          uploadPayload,
          false
        );
        return response;
      }
    },
    placeholderData: keepPreviousData,
  });
}
