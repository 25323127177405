import React, { useEffect, useRef, useState } from "react";
import { SideBarData } from "../../Content/data";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import arrow from "../../../Assets/Sidebar/DropdownArrow.svg";
import logout from "../../../Assets/Common/logoutIcon.svg";
import "./style.scss";
import { notificationbellactive } from "../../Content/image";
import {
  setLandlordId,
  setLandlordName,
  setNavLandlordList,
  sidebar,
} from "../../../redux/reducer/counterslice";
import { useDispatch, useSelector } from "react-redux";
import solaceAltLogo from "../../../Assets/Common/solaceAltLogo.svg";

import MainLogo from "../../../Assets/Common/Solace_logo.png";
import { useFetchLandlordNoId } from "../../../Api/PropertiesQuery";
import NavLandlordPopup from "./NavLandlordPopup";
import landlordIcon from "../../../Assets/Common/landlordIcon.svg";

export default function SideBarComponent() {
  const [dropSelect, setDropSelect] = useState(null);
  const [sidebaractive, setsidebaractive] = useState(true);
  const [logoutPop, setLogoutPop] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [subActiveElement, setSubActiveElement] = useState("");
  const userData = useSelector((state) => state.counter.loginuserdata);
  const dispatch = useDispatch();
  const [childShow, setChildShow] = useState(true);
  const [prequelIndex, setPrequelIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [greeting, setGreeting] = useState();
  const clientid = useSelector((state) => state.counter.clientid);
  const [showBackButton, setShowBackButton] = useState(false);
  const landlordlistdata = useSelector(
    (state) => state.counter.navLandlordList
  );

  const onclientclick = (item) => {
    // setmanagerview(false);
    dispatch(setLandlordId(item.id));
    dispatch(
      setLandlordName(
        item?.company?.length !== 0
          ? item?.company
          : item?.first_name + " " + item?.last_name
      )
    );
    // if (window.location.pathname.includes("/accounts/accountreconcile")) {
    //   navigate(`/accounts/accountreconcile/${item.id}`);
    // } else if (
    //   window.location.pathname.includes("/accounts/processagencyfee/")
    // ) {
    //   navigate(`/accounts/processagencyfee/${item.id}`);
    // } else if (window.location.pathname.includes("/bulk-upload/")) {
    //   navigate(`/bulk-upload/${item.id}`);
    // }
  };
  const landlordName = useSelector((state) => state.counter.landlordName);

  const checkWindowSize = () => {
    if (window.innerWidth < 980) {
      setIsMobile(true);
    } else if (window.innerWidth > 980) {
      setIsMobile(false);
    }
  };
  const fetchLandlord = useFetchLandlordNoId({
    params: {
      client: clientid,
      page: 1,
      page_size: 1000,
      status: "dummy",
      // search: "",
    },
  });

  useEffect(() => {
    if (fetchLandlord.data) {
      dispatch(setNavLandlordList(fetchLandlord.data.data.results));
    }
  }, [fetchLandlord.data]);

  useEffect(() => {
    checkWindowSize(); // Run on mount
    window.addEventListener("resize", checkWindowSize); // Add resize listener

    return () => {
      window.removeEventListener("resize", checkWindowSize); // Cleanup listener
    };
  }, []);

  const handleDropDownClick = (index) => {
    setsidebaractive(true);
    setDropSelect((prevIndex) => (prevIndex === index ? null : index));
    if (dropSelect != index) {
      dispatch(sidebar(index));
    }
  };
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const firstPart = pathParts[1];
    const foundItem = SideBarData.find(
      (item) => firstPart === item.href.split("/")[1]
    );
    const selectedIndex = foundItem ? SideBarData.indexOf(foundItem) : null;
    setSelected(selectedIndex);

    if (pathParts[2]) {
      const subPath = pathParts[2];
      setSubActiveElement(subPath);
      if (foundItem) {
        setDropSelect(selectedIndex);
      }
    } else {
      //   setSubActiveElement("");
    }
  }, [location]);

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setLogoutPop(false);
    }
  };

  useEffect(() => {
    if (logoutPop) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [logoutPop]);

  const sideBarRef = useRef(null);

  const handleSideOutside = (event) => {
    if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleSideOutside);
    } else {
      document.removeEventListener("mousedown", handleSideOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleSideOutside);
    };
  }, [isOpen]);

  const getGreeting = () => {
    const ukTime = new Date().toLocaleString("en-US", {
      hour: "2-digit",
      timeZone: "Europe/London",
      hour12: false,
    });

    const hours = parseInt(ukTime);
    console.log(hours, "hours");

    if (hours < 12) {
      return "Good Morning";
    } else if (hours < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };
  useEffect(() => {
    const updateGreeting = () => setGreeting(getGreeting());

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000); // Update every 1 minute

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    console.log(landlordlistdata, "landlordlistdatalandlordlistdata");
  }, [landlordlistdata]);

  return (
    <div
      className={`sidebar_main_wrapper  ${sidebaractive ? "active" : ""}`}
      ref={sideBarRef}
      style={{
        ...(isMobile && isOpen
          ? {
              width: "320%",
              transition: "0.2s",
              zIndex: "200",
            }
          : {}),
      }}
    >
      <div
        className="sidebar-top"
        style={{
          transition: "0.2s",
        }}
      >
        <div className="sidebar_header">
          <div
            className={`title_logo ${sidebaractive ? "" : "active"}`}
            style={{ cursor: "pointer" }}
          >
            <div
              className="pageLogo"
              ref={sideBarRef}
              onClick={() => {
                // navigate("/");
                if (isMobile && !isOpen) {
                  console.log("open");
                  setIsOpen(true);
                } else {
                  navigate("/");
                }
              }}
              // style={{
              //   display: isMobile && !isOpen ? "none" : "",
              // }}
            >
              <img
                src={!isMobile || isOpen ? MainLogo : solaceAltLogo}
                alt="logo"
              />
            </div>
            {isMobile && isOpen && (
              <div
                style={{
                  // border: "1px solid white",
                  height: "100%",
                  width: "45px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                }}
              >
                <img
                  src={logout}
                  alt="logout"
                  style={{
                    cursor: "pointer",
                    minHeight: "16px",
                    minWidth: "16px",
                    height: "1vw",
                    width: "1vw",
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className="side_navdata_wrapper"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true); // Toggle open class
          }}
        >
          {SideBarData.map((ele, index) => {
            return (
              <React.Fragment key={index}>
                <NavLink
                  className={`navLink ${
                    selected === index ? "activelink" : ""
                  }`}
                  style={{
                    paddingLeft: isMobile ? "18px" : "7%",
                  }}
                  onClick={() => {
                    if (selected != index) {
                      setChildShow(true);
                      handleDropDownClick(index);
                    } else {
                      setChildShow(!childShow);
                    }
                  }}
                  to={ele.href}
                >
                  <div className="innerLeft">
                    <div
                      className="menu"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="sidebarIcons"
                        // width={22}
                        // height={22}
                        src={selected == index ? ele.logoactive : ele.logo}
                        alt={ele.data}
                      />
                      <span className={sidebaractive ? "" : "dnone"}>
                        {isMobile && !isOpen ? "" : ele.data}
                      </span>
                    </div>
                    {isMobile && !isOpen
                      ? ""
                      : ele?.children &&
                        (childShow && selected == index ? (
                          <div className={sidebaractive ? "" : "dnone"}>
                            <img
                              style={{
                                transform: "rotate(180deg)",
                                height: "max(16px, 1.1vw)",
                                width: "max(16px, 1.1vw)",
                              }}
                              src={arrow}
                              alt="dropdown"
                              onClick={() => setChildShow(false)}
                            />
                          </div>
                        ) : (
                          <div className={sidebaractive ? "" : "dnone"}>
                            <img
                              style={{
                                // transform: "rotate(180deg)",
                                height: "max(16px, 1.1vw)",
                                width: "max(16px, 1.1vw)",
                              }}
                              // width={19}
                              // height={19}
                              src={arrow}
                              alt="dropdown"
                              onClick={() => setChildShow(true)}
                            />
                          </div>
                        ))}
                  </div>
                </NavLink>
                {ele?.children && dropSelect === index && childShow && (
                  <div className="dropdown_content">
                    {isMobile && !isOpen ? (
                      ""
                    ) : (
                      <ul>
                        {ele.children.map((child, childIndex) => (
                          <NavLink
                            to={child.href}
                            className={`${
                              subActiveElement === child.match
                                ? "subactivelink"
                                : ""
                            }`}
                            key={childIndex}
                            // onClick={() => {
                            //   setIsOpen(false);
                            // }}
                          >
                            <li
                              className={`${
                                subActiveElement === child.match
                                  ? "subactivelink"
                                  : ""
                              }`}
                            >
                              <span className={subActiveElement ? "" : "dnone"}>
                                {child.data}
                              </span>
                            </li>
                          </NavLink>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div
        className="all_managers"
        onClick={(e) => {
          if (landlordlistdata?.length > 0) {
            setShowBackButton(!showBackButton);
          }
        }}
        style={{
          background:
            landlordlistdata?.length > 0
              ? "rgb(5,5,88)"
              : "rgb(255,65,49, 0.2)",
          cursor: landlordlistdata?.length > 0 ? "pointer" : "default",
        }}
      >
        <div className="all_managers_inner">
          <div className="all_managers_inner_logo">
            <img src={landlordIcon} style={{ width: "20px" }} />
          </div>
        </div>
        {(!isMobile || (isMobile && isOpen)) && (
          <p
            style={{
              color: landlordlistdata?.length > 0 ? "#fff" : "#FF4131",
            }}
            onClick={() => {
              if (landlordlistdata?.length > 0) {
                setShowBackButton(!showBackButton);
              }
            }}
          >
            {!landlordlistdata || landlordlistdata.length === 0 ? (
              <span>No Landlord Available</span>
            ) : landlordName === "" || landlordName === null ? (
              <span>All Landlords</span>
            ) : (
              <span style={{ textTransform: "capitalize" }}>
                {landlordName}
              </span>
            )}
          </p>
        )}
      </div>

      <div
        className="sidebar_footer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true); // Toggle open class
        }}
      >
        <div
          className="inner_wrapper"
          style={{
            position: "relative",
            width: "100%",
            paddingLeft: isMobile ? "7px" : "3%",
          }}
        >
          <div className="user_text_area">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "max(10px, 1.2vw)",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div
                className="sidebar_profile"
                ref={popupRef}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setLogoutPop(true);
                  // setLogoutPop(!logoutPop);
                }}
              >
                {userData.company
                  ? userData?.company.slice(0, 1) + userData?.company.slice(-1)
                  : userData.first_name?.slice(0, 1) +
                    userData.last_name?.slice(0, 1)}
              </div>
              {isMobile && !isOpen ? (
                ""
              ) : (
                <div
                  className={`text_area ${sidebaractive ? "" : "dnone"}`}
                  style={{ padding: "0", marginLeft: "0px" }}
                >
                  <p style={{ textAlign: "left", textWrap: "nowrap" }}>
                    {greeting} ,
                  </p>
                  <p>
                    {userData.company
                      ? userData?.company
                      : userData.first_name + " " + userData.last_name}
                  </p>
                </div>
              )}
            </div>
            {isMobile && !isOpen ? (
              ""
            ) : (
              <img
                className={`${sidebaractive ? "" : "dnone"}`}
                src={notificationbellactive}
                // width={32}
                // height={32}
                style={{
                  height: "clamp(20px, 1.7vw, 50px)",
                  width: "clamp(20px, 1.7vw, 50px)",
                }}
              ></img>
            )}

            {logoutPop && (
              <div
                ref={popupRef}
                className="popup-container"
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                <div className="logout_profile">
                  {userData.company
                    ? userData?.company.slice(0, 1) +
                      userData?.company.slice(-1)
                    : userData.first_name?.slice(0, 1) +
                      userData.last_name?.slice(0, 1)}
                </div>
                <div
                  className={`text_area ${sidebaractive ? "" : "dnone"}`}
                  style={{
                    display: "flex",
                    // justifyContent: "flex-start",
                    alignItems: "center",
                    // gap: "15px",
                    //   marginLeft: "20px",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      fontWeight: "500",
                      marginLeft: "-20%",
                    }}
                  >
                    {/* {userData.first_name} */}
                    Logout
                  </p>
                </div>
                <img
                  src={logout}
                  alt="logout"
                  style={{
                    cursor: "pointer",
                    minHeight: "14px",
                    minWidth: "14px",
                    height: "1vw",
                    width: "1vw",
                  }}
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <NavLandlordPopup
        showPopup={showBackButton}
        onClose={setShowBackButton}
        onclientclick={onclientclick}
      />
    </div>
  );
}
