import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import "./NavLandlordPopup.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { formatName, handleTruncate } from "../../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setLandlordId,
  setLandlordName,
  setNavSelectedLandlord,
} from "../../../redux/reducer/counterslice";

// Extracted client name formatting logic
const getFormattedClientName = (client) => {
  if (client.company?.length) return client.company;
  const name = formatName(
    client.contact_detail?.[0]?.title,
    client.contact_detail?.[0]?.first_name,
    client.contact_detail?.[0]?.last_name
  );

  return handleTruncate(name, 25);
};

// Extracted popup header component
const PopupHeader = ({ onClose }) => (
  <div className="nav-client-popup-header">
    <h2>Select Landlord</h2>
    <div>
      <button className="close-btn" onClick={() => onClose(false)}>
        ×
      </button>
    </div>
  </div>
);

PopupHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

// Main NavClientPopup component
const NavLandlordPopup = ({ onclientclick, showPopup, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef(null);

  //   const {
  //     setNavSelectedClient,
  //     navSelectedLandlord,
  //     setClientid,
  //     setClientname,
  //     landlordId,
  //     navLandlordList,
  //   } = useStore((state) => ({
  //     setNavSelectedClient: state.setNavSelectedClient,
  //     navSelectedLandlord: state.navSelectedLandlord,
  //     setClientid: state.setClientid,
  //     setClientname: state.setClientname,
  //     landlordId: state.landlordId,
  //     navLandlordList: state.navLandlordList,
  //   }));
  const landlordId = useSelector((state) => state.counter.landlordId);
  const navSelectedLandlord = useSelector(
    (state) => state.counter.navSelectedLandlord
  );
  const navLandlordList = useSelector((state) => state.counter.navLandlordList);
  const landlordName = useSelector((state) => state.counter.landlordName);
  const dispatch = useDispatch();
  console.log(navLandlordList, "navSelectedLandlord");

  // Handle visibility animation
  useEffect(() => {
    if (showPopup) {
      setIsVisible(true);
      const timer = setTimeout(() => setIsAnimating(true), 10);
      return () => clearTimeout(timer);
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => setIsVisible(false), 400);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  // Handle outside clicks and scroll
  useEffect(() => {
    if (!isVisible) return;

    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose(false);
      }
    };

    const handleScroll = () => onClose(false);

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible, onClose]);

  // Check if user is on editor page
  const isOnEditorPage = useCallback(() => {
    if (location.pathname.includes("/tenancy/myTenancy/editor/")) {
      toast.error("Cannot change client when creating letter");
      onClose(false);
      return true;
    }
    return false;
  }, [location.pathname, onClose]);

  // Handle navigation based on current route
  const handleNavigation = useCallback(() => {
    if (location.pathname.includes("/contacts/myContacts/")) {
      navigate("/contacts/myContacts");
    } else if (location.pathname.includes("/properties/myProperties/")) {
      navigate("/properties/myProperties");
    } else if (location.pathname.includes("/tenancy/myTenancy/")) {
      navigate("/tenancy/myTenancy");
    } else if (location.pathname.includes("/progressions/details/")) {
      navigate("/progressions/myProgressions");
    }
  }, [location.pathname, navigate]);

  // Handle client selection
  const handleClientChange = useCallback(
    (client) => {
      if (isOnEditorPage()) return;

      handleNavigation();
      onclientclick(client);
      onClose(false);

      const formattedName = getFormattedClientName(client);
      dispatch(setLandlordName(formattedName));
      dispatch(
        setNavSelectedLandlord({
          value: client.id,
          label: formattedName,
        })
      );
    },
    [isOnEditorPage, handleNavigation, onclientclick, onClose, dispatch]
  );

  // Handle "All Clients" selection
  const handleAllClientsClick = useCallback(() => {
    if (isOnEditorPage()) return;
    onClose(false);
    dispatch(setNavSelectedLandlord({}));
    dispatch(setLandlordId(""));
    dispatch(setLandlordName(""));
  }, [isOnEditorPage, onClose]);

  if (!isVisible) return null;

  return (
    <div
      className={`nav-client-popup ${isAnimating ? "open" : "close"}`}
      ref={ref}
    >
      <div className="nav-client-popup-container">
        <PopupHeader onClose={onClose} />
        <div className="nav-client-popup-body">
          <div
            className={`nav-client-popup-body-container ${
              landlordId === "" ? "selected" : ""
            }`}
            onClick={handleAllClientsClick}
          >
            <button>All Landlords</button>
          </div>
          {navLandlordList.map((client) => (
            <div
              key={client.id}
              className={`nav-client-popup-body-container ${
                navSelectedLandlord?.value === client.id ? "selected" : ""
              }`}
              onClick={() => handleClientChange(client)}
            >
              <button>{getFormattedClientName(client)}</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

NavLandlordPopup.propTypes = {
  onclientclick: PropTypes.func.isRequired,
  showPopup: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NavLandlordPopup;
