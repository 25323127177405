import React, { useEffect, useState } from "react";
import "./Styles/ConfirmPopup.scss";
import cautionIcon from "./Assests/cautionIcon.svg";
// import { Button } from "../Button";
import { CustomButton } from "../../GlobalComponents/CustomButton/CustomButton";

const ConformPopup = (props) => {
  const { isOpen, setIsOpen, handleConfirmSubmit, customMessage } = props;
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setIsOpen(false);
  // };

  return (
    <div>
      {isOpen && (
        <>
          <div className="overlay" />
          <div
            className="popup"
            style={{ minWidth: "500px", width: "500px", height: "auto" }}
          >
            <div className="popup-content">
              <div
                className="popup_center"
                style={{
                  gap: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="formContainer">
                  <img src={cautionIcon} alt="cautionIcon" height={"85px"} />
                </div>
                <p style={{ fontSize: "22px" }}>
                  {customMessage
                    ? customMessage
                    : "Are you sure you want to continue ?"}
                </p>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <CustomButton
                    tit
                    title={"Close"}
                    active
                    styles={{ background: "red" }}
                    handleClick={(e) => {
                      setIsOpen(false);
                      e.preventDefault();
                    }}
                  />

                  <CustomButton
                    type="submit"
                    title={"Yes"}
                    active
                    handleClick={(e) => {
                      e.preventDefault();
                      handleConfirmSubmit();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConformPopup;
