import { useState, useEffect, useMemo } from "react";
import "../../StyleSheet/inspection/periodicinspection.scss";
import GenerateReport from "../../../MainComponent/fields/generatereport";
import Search from "../../../MainComponent/fields/search";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import {
  useFetchComplianceStatscount,
  useFetchPeriodicId,
} from "../../../Api/ComplianceQuery";
import { useDispatch, useSelector } from "react-redux";
import download from "../../../Assets/Common/download.svg";
import dayjs from "dayjs";
import { addLeadingZero } from "../../../Utils/Utils";
import {
  setContactDetailsFilter,
  setPeriodicInspectionStatus,
  setPropertyDetailsFilter,
} from "../../../redux/reducer/counterslice";
import { useFetchPropertiesCompliance } from "../../../Api/PropertiesQuery";
import Propertydetails from "../Property/popups/propertydetails";
import { Skeleton } from "antd";

export default function Inspection() {
  const [chartData, setChartData] = useState([0, 0, 0]);
  const dispatch = useDispatch();
  const [search, setsearch] = useState("");
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const subColor = ["#dbf8e7", "#fff0cc", "#ffd8d4"];

  const text = ["Not Due", "Due", "Overdue"];
  const columnHelper = createColumnHelper();
  const [Generate, setGenerate] = useState("");
  const clientid = useSelector((state) => state.counter.clientid);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(20);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [popup, setpopup] = useState(false);
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const landlordId = useSelector((state) => state.counter.landlordId);

  const complianceList = useSelector(
    (state) => state.counter.periodicInspection
  );

  const fetchCompliance = useFetchPeriodicId({
    type: "property",
    status: complianceList,
    client: clientid,
    landlord: landlordId?.length > 0 ? landlordId : null,
    search: search,
    page: pageindex,
    page_size: pagesize,
  });
  const Fetchcountdata = useFetchComplianceStatscount({
    params: {
      client: clientid,
      landlord: landlordId?.length > 0 ? landlordId : null,
    },
  });
  useEffect(() => {
    // console.log(complianceSelected, complianceType, "lakjsldkfljsdljf");
    console.log(fetchCompliance?.data?.data, "lakjsfdljasjfdl");
    if (fetchCompliance.data?.data?.results) {
      setTableData(fetchCompliance.data.data.results);
      setCount(fetchCompliance.data?.data?.count);
    }
  }, [fetchCompliance.data, complianceList, search]);
  const [totalProperties, setTotalProperties] = useState(0);

  useEffect(() => {
    // console.log(Fetchcountdata.data.data, "lakjsldflaskldjfajsf");
    if (Fetchcountdata?.data?.data != undefined) {
      setChartData([
        Fetchcountdata?.data?.data?.document_count[0]?.not_due_count,
        Fetchcountdata?.data?.data?.document_count[0]?.due_count,
        Fetchcountdata?.data?.data?.document_count[0]?.overdue_count,
      ]);
      setTotalProperties(
        Fetchcountdata?.data?.data?.document_count[0]?.not_due_count +
          Fetchcountdata?.data?.data?.document_count[0]?.due_count +
          Fetchcountdata?.data?.data?.document_count[0]?.overdue_count
      );
    }
  }, [Fetchcountdata.data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("expiry_date", {
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            Next Due Date
          </span>
        ),
        cell: (info) => (
          <p
            style={{
              wordBreak: "break-all",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
          >
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </p>
        ),
      }),
      columnHelper.accessor("property_address", {
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>Address</span>
        ),
        cell: (info) => {
          return (
            <p
              style={{
                wordBreak: "break-all",
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
            >
              {info.getValue()}
            </p>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        id: "Remaining Days",
        cell: (info) => {
          const expiryDate = dayjs(info.row.original.expiry_date);
          const today = dayjs();
          const daysLeft = expiryDate.diff(today, "day");
          return (
            <span
              style={{
                fontSize: "clamp(10px, 0.9vw, 27px)",
              }}
            >
              {daysLeft < 0 ? "Expired" : `${daysLeft} days`}
            </span>
          );
        },
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>
            Remaining Days
          </span>
        ),
      }),

      // columnHelper.accessor((row) => row, {
      //   id: "address",
      //   cell: (info) => {
      //     const data = info.row.original.property_address;
      //     return (
      //       <span
      //         className="contentHover"
      //         style={{ wordBreak: "break-all" }}
      //         onClick={() => {
      //           dispatch(setPropertyDetailsFilter("1"));
      //           dispatch(setContactDetailsFilter(1));
      //           setSelectedPropertyId(info.row.original?.property);
      //           setpopup(true);
      //         }}
      //       >
      //         {data}
      //       </span>
      //     );
      //   },
      //   header: () => <span>Address</span>,
      // }),

      columnHelper.accessor("file", {
        header: () => (
          <span style={{ fontSize: "clamp(10px, 0.9vw, 27px)" }}>Document</span>
        ),
        enableSorting: false,
        cell: (info) => (
          <p
            style={{
              color: "#817EFF",
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              fontSize: "clamp(10px, 0.9vw, 27px)",
            }}
            onClick={() => {
              window.open(info.getValue(), "_blank");
            }}
          >
            Download
            <img src={download} alt="download" />
          </p>
        ),
      }),
    ],
    []
  );
  const handleFilerChange = (value) => {
    dispatch(setPeriodicInspectionStatus(value));
  };

  useEffect(() => {
    console.log(complianceList, "nsdbufa");
  }, [complianceList]);
  return (
    <div className="Inspection">
      <div className="Inspection_main">
        <div className="Inspection-header">
          <h1>Periodic Inspection</h1>
          <div className="progress_wrapper row">
            {chartData.length > 0 &&
              chartData.map((item, index) => {
                // Calculate the percentage width based on the total number of properties
                const percentage =
                  totalProperties > 0 ? (item / totalProperties) * 100 : 0;

                return (
                  <div className="col-3" style={{ width: "29%" }} key={index}>
                    <div className="progress_main">
                      <div className="main_text">
                        <span className="text">
                          {text[index % text.length]}
                        </span>
                        <span style={{ color: colors[index % colors.length] }}>
                          {addLeadingZero(item)}
                        </span>
                      </div>

                      <div className="progress_bar">
                        <div
                          className="progress progress-sm"
                          style={{
                            height: "8px",
                            backgroundColor: subColor[index % subColor.length],
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              width: `${percentage}%`,
                              borderRadius: "1.25rem",
                              backgroundColor: colors[index % colors.length],
                            }}
                            aria-valuenow={percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="divider"></div>
        <div className="main_tablecontent">
          <div className="table_filter">
            <div className="button_container">
              <button
                className={`${complianceList === "all" && "active"}`}
                onClick={() => {
                  handleFilerChange("all");
                }}
              >
                All
                <span>
                  {addLeadingZero(chartData[0] + chartData[1] + chartData[2])}
                </span>
              </button>
              <button
                className={`${complianceList === "not_due" && "active"}`}
                onClick={() => {
                  handleFilerChange("not_due");
                }}
              >
                Not Due
                <span style={{ fontSize: "14px" }}>
                  {addLeadingZero(chartData[0])}
                </span>
              </button>{" "}
              <button
                className={`${complianceList === "due" && "active"}`}
                onClick={() => {
                  handleFilerChange("due");
                }}
              >
                Due
                <span style={{ fontSize: "14px" }}>
                  {addLeadingZero(chartData[1])}
                </span>
              </button>{" "}
              <button
                className={`${complianceList === "overdue" && "active"}`}
                onClick={() => {
                  handleFilerChange("overdue");
                }}
              >
                Overdue
                <span style={{ fontSize: "14px" }}>
                  {addLeadingZero(chartData[2])}
                </span>
              </button>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Search
                setsearch={setsearch}
                // style={{ width: "410px" }}
                setPageIndex={setPageIndex}
                placeholder={"inspection"}
              />
              {/* <GenerateReport
                style={{ fontSize: "14px" }}
                setGenerate={setGenerate}
              /> */}
            </div>
          </div>
          <div
            className="table_compliance"
            style={{
              paddingTop: "24px",
              marginLeft: "19px",
              height: "calc(100% - 0)",
            }}
          >
            {fetchCompliance.isFetching ? (
              <div className="skelitonDiv">
                <Skeleton active style={{ height: "98px" }} rows={1} />
              </div>
            ) : (
              <TableComponent
                data={tableData}
                columns={columns}
                count={count}
                pageindex={pageindex}
                pagesize={pagesize}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            )}
          </div>
        </div>
      </div>
      {fetchProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setpopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={popup}
        />
      )}
    </div>
  );
}
